import { DeepPartial } from 'redux';
import { enableMapSet } from 'immer';
import { routerMiddleware } from 'connected-react-router';
import {
  AnyAction,
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appHistory from './appHistory';
import reducers from './reducers';

enableMapSet();

export type RootState = ReturnType<typeof reducers>;
export type PartialRootState = DeepPartial<RootState>;

function configureMyStore(preloadedState?: PartialRootState) {
  return configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(
        routerMiddleware(appHistory)
        // middlewares here
      ),
  });
}

let store: ReturnType<typeof configureMyStore>;

export type AppDispatch = typeof store.dispatch;

// Use those instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunkAction = ThunkAction<
  void,
  RootState,
  {} | undefined,
  AnyAction
>;

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

export function getHistory() {
  return appHistory;
}

export const getState = () => {
  return getStore().getState();
};

export function reConfigureStore(preloadedState?: PartialRootState) {
  store = configureMyStore(preloadedState);
}

export function getStore(preloadedState?: PartialRootState) {
  if (!store) {
    store = configureMyStore(preloadedState);
  } else if (preloadedState) {
    throw new Error('Store already initialized');
  }
  return store;
}
