import { Preloader as PreloaderWrapper, Puff } from 'react-preloader-icon';
import styled from 'styled-components';

type PreloaderProps = {
  size?: PreloaderSize;
};

export enum PreloaderSize {
  SMALL = 30,
  MEDIUM = 60,
  LARGE = 120,
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
`;

const Preloader = ({ size = PreloaderSize.MEDIUM }: PreloaderProps) => {
  return (
    <Wrapper>
      <PreloaderWrapper
        use={Puff}
        size={size}
        strokeWidth={6}
        strokeColor="#262626"
        duration={2000}
      />
    </Wrapper>
  );
};

export default Preloader;
