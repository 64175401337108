import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import {
  Loading,
  ReviewQueryParams,
  ReviewStatus,
} from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { REVIEWS_PAGE_PER_PAGE } from '../../review/utils/constants';
import { Reviewer } from './reviews.slice';

const http = new Http();

export const fetchReviews = createAsyncThunk<
  any,
  {
    payload: ReviewQueryParams;
    showPreloader?: boolean;
  },
  { rejectValue: string; state: RootState }
>(
  'reviews/fetch',
  async (
    {
      payload: {
        pageNumber = 1,
        search = '',
        status,
        limit = REVIEWS_PAGE_PER_PAGE,
        sort = 'desc',
        platform,
        rating,
        missingReplies,
      },
    },
    { rejectWithValue }
  ) => {
    let params = {
      sort,
      missingReplies: 'false',
      limit: String(limit),
    } as {
      limit?: string;
      page?: string;
      rating?: string | null;
      search?: string;
      status?: ReviewStatus;
      sortBy?: string;
      sort: string;
      platform?: string;
      missingReplies?: string;
    };

    if (pageNumber) {
      params = { ...params, page: String(pageNumber) };
    }

    if (search) {
      params = { ...params, search };
    }

    if (status) {
      params = {
        ...params,
        status,
      };
    }

    if (platform) {
      params = { ...params, platform };
    }

    if (rating) {
      params = { ...params, rating };
    }

    if (missingReplies === 'true') {
      params = { ...params, missingReplies: 'true' };
    } else {
      params = { ...params, missingReplies: 'false' };
    }

    const queryString = new URLSearchParams(params as any);
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/reviews/list?${queryString}`;

    try {
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  },
  {
    condition(_, api): boolean {
      const {
        reviews: {
          reviews: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const toggleReviewStatus = createAsyncThunk<
  any,
  { id: string; action: 'publish' | 'unpublish' },
  { rejectValue: string; state: RootState }
>('reviews/toggle-status', async ({ id, action }, { rejectWithValue }) => {
  let url = `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/`;

  if (action === 'unpublish') {
    url += 'reviews/unpublish';
  } else {
    url += 'reviews/publish';
  }

  try {
    const response = await http.post(url, { id });
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const deleteReview = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('review/delete', async (reviewId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/${reviewId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const submitAReply = createAsyncThunk<
  any,
  { reviewId: string; replyContent: string; isUpdate?: boolean },
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/reply',
  async (
    {
      reviewId,
      replyContent,
      isUpdate = false,
    }: { reviewId: string; replyContent: string; isUpdate?: boolean },
    { rejectWithValue }
  ) => {
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/reply/${reviewId}`,
        { replyContent, isUpdate }
      );
      return replyContent;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  }
);

export const deleteReviewReply = createAsyncThunk<
  any,
  { reviewId: string },
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/delete',
  async ({ reviewId }: { reviewId: string }, { rejectWithValue }) => {
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/delete-reply/${reviewId}`
      );
      return;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  }
);

export const uploadReviewerAvatar = createAsyncThunk<
  { url: string; fileName: string },
  any,
  { rejectValue: string; state: RootState }
>('review/reviewer/upload-avatar', async (formData, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/remote-files/avatars`,
      formData
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const updateReviewerAvatar = createAsyncThunk<
  { success: boolean; reviewer: Reviewer },
  { avatar: string; reviewerId: string; productId?: string },
  { rejectValue: string; state: RootState }
>(
  'review/reviewer/update',
  async ({ avatar, productId, reviewerId }, { rejectWithValue }) => {
    try {
      const response = await http.update(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviewer/${reviewerId}`,
        {
          avatar,
          productId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  }
);

export const createReview = createAsyncThunk<
  any,
  FormData,
  { rejectValue: string; state: RootState }
>('review/create', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/create`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const updateReview = createAsyncThunk<
  any,
  { id: string; data: FormData },
  { rejectValue: string; state: RootState }
>('review/update', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/update/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const bulkDeleteReviews = createAsyncThunk<
  any,
  string[],
  { rejectValue: string; state: RootState }
>('review/reviews/bulk', async (reviewIds, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/bulk`,
      { ids: reviewIds }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const bulkPublishReviews = createAsyncThunk<
  any,
  { ids: string[]; operation: 'publish' | 'unpublish' | null },
  { rejectValue: string; state: RootState }
>('review/bulk-publish', async (data, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/bulk-publish`,
      data
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const getReviewsUsage = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('reviews/usage', async (_args: void, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/usage`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
