import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input, Message, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import {
  AppleConnectFormData,
  Loading,
} from '../../../../../shared/types/review.types';
import capitalize from '../../../../../shared/utils/capitalize';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useAppleConnectContext } from '../../../contexts/AppleLocationContext';
import { getAppleStores } from '../../../redux/actions';
import {
  initAppleStoresSearch,
  socialReviewsSelector,
} from '../../../redux/social-reviews.slice';
import appleLanguagesJson from '../../../utils/apple-languages.json';
import appleRegionsJson from '../../../utils/apple-regions.json';

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
`;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;
const FormContainer = styled.form`
  padding-top: 20px;
`;
const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
`;

const StyledMessage = styled(Message)`
  margin-bottom: 20px;
  .rs-message-body {
    display: block !important;
  }
`;

const appleRegions = Object.entries(appleRegionsJson).map(([key, value]) => ({
  label: capitalize(value),
  value: key,
}));

const appleLanguages = Object.entries(appleLanguagesJson).map(
  ([key, value]) => ({
    label: capitalize(value.language),
    value: key,
  })
);

const SearchStores = ({
  onSuccess,
  resetCloseButton,
}: {
  onSuccess: () => void;
  resetCloseButton?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const { showErrorToast } = useNotification();
  const { formValues, updateFormValues } = useAppleConnectContext();
  const { loadingAppleStores } = useAppSelector(socialReviewsSelector);
  const isLoading = loadingAppleStores === Loading.PENDING;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AppleConnectFormData>({
    defaultValues: formValues,
  });

  const onSubmit = handleSubmit((data) => {
    updateFormValues(data);
    setShowNotFoundMessage(false);
    dispatch(initAppleStoresSearch());
    dispatch(getAppleStores(data))
      .then(unwrapResult)
      .then(() => {
        reset();
        onSuccess();
      })
      .catch(() => {
        setShowNotFoundMessage(true);
        showErrorToast(t('reviews.import-source.apple.store-find-fail'));
      });
  });

  return (
    <>
      <ModalHeader
        title={t('reviews.import-source.apple.search-your-store')}
        closeButton={false}
        resetCloseButton={resetCloseButton}
      />
      <ModalBody>
        <FormContainer onSubmit={onSubmit}>
          <StyledLabel>
            {t('reviews.import-source.apple.your-app-name')}
          </StyledLabel>
          <Controller
            name="search"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledInput
                error={errors.search}
                placeholder="E.g. Netflix"
                {...field}
              />
            )}
          />

          <StyledLabel>
            {t('reviews.import-source.apple.app-country')}
          </StyledLabel>
          <Controller
            name="country"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledPicker
                block
                cleanable={false}
                data={appleRegions}
                {...field}
              />
            )}
          />

          <StyledLabel>
            {t('reviews.import-source.apple.app-language')}
          </StyledLabel>
          <Controller
            name="lang"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledPicker
                block
                cleanable={false}
                data={appleLanguages}
                {...field}
              />
            )}
          />

          {showNotFoundMessage && (
            <div>
              <StyledMessage>
                <p>
                  <b>
                    {t('reviews.import-source.apple.error.not-found-message-1')}
                  </b>
                </p>
                <p>
                  {t('reviews.import-source.apple.error.not-found-message-2')}
                </p>
              </StyledMessage>
            </div>
          )}
          <ContinueButton
            block={false}
            type="submit"
            appearance={isLoading ? 'subtle' : 'primary'}
            size="md"
          >
            {isLoading ? t('shared.please-wait') : t('shared.search')}
          </ContinueButton>
        </FormContainer>
      </ModalBody>
    </>
  );
};

export default SearchStores;
