import { SubscriptionPlan } from '../types/review.types';

const getCurrentPlan = (plan: SubscriptionPlan) => {
  switch (plan) {
    case SubscriptionPlan.PRO:
    case SubscriptionPlan.ONETIME_PRO:
      return 'Pro';

    case SubscriptionPlan.ADVANCED:
    case SubscriptionPlan.ONETIME_ADVANCED:
      return 'Advanced';

    case SubscriptionPlan.PREMIUM:
    case SubscriptionPlan.ONETIME_PREMIUM:
      return 'Premium';

    default:
      return 'FREE';
  }
};

export default getCurrentPlan;
