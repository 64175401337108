import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosPrivate } from '../../../../../services/axiosPrivate';

export const listIntegrations = createAsyncThunk<
  any,
  { showPreloader: boolean },
  { rejectValue: string }
>('integrations/list', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosPrivate.get(`integrations/list`);
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
