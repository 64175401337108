import { Button } from 'rsuite';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 0;
`;
const MainWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  p {
    line-height: 20px;
    margin-bottom: 30px;
  }
`;
const Title = styled.h3`
  color: #000;
  font-weight: bold;
  font-size: 25px;
  margin: 0 auto 15px;
  text-align: center;
`;
const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  /* max-width: 200px; */
  margin: auto;
`;

interface Props {
  total: number;
  onConfirmed: () => void;
}

const Success = ({ onConfirmed, total }: Props) => {
  return (
    <>
      <Container>
        <MainWrapper>
          <Title>Success!</Title>
          <p>
            Your Yelp page's initial <b>{total} reviews</b> are in. We're
            auto-importing the rest. In the meantime, feel free to add more
            manually.
          </p>
        </MainWrapper>

        <ConfirmButton block appearance="default" onClick={onConfirmed}>
          Close
        </ConfirmButton>
      </Container>
    </>
  );
};

export default Success;
