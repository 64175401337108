import { createSlice } from '@reduxjs/toolkit';
import { Loading, PaginatedResponse } from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { Product } from '../../product/types';
import {
  createQuestion,
  deleteQuestion,
  fetchQuestions,
  submitAnswer,
} from './action';

export interface IQuestionAndAnswer {
  _id: string;
  siteId: string;
  question: string;
  answer?: string;
  user: { name: string; email: string };
  product?: Product | null;
  picture?: string;
  video?: string;
}

export type QuestionFormData = {
  name: string;
  question: string;
  answer: string;
  product: string;
};

type QuestionAndAnswerState = {
  questions: {
    loading: Loading;
    data: PaginatedResponse<IQuestionAndAnswer> | null;
    totalAnswered: number | null;
    totalUnanswered: number | null;
  };
  deleteQuestion: {
    reviewId: string | null;
    loading: Loading;
  };
  submitAnswer: {
    loading: Loading;
    error?: string;
    reviewId: string | null;
  };
  createQuestion: {
    loading: Loading;
    error: string | null;
  };
  addQuestion: {
    openModal: boolean;
  };
};

const initialState: QuestionAndAnswerState = {
  questions: {
    loading: Loading.INITIAL,
    data: null,
    totalAnswered: null,
    totalUnanswered: null,
  },
  deleteQuestion: {
    reviewId: null,
    loading: Loading.INITIAL,
  },
  submitAnswer: {
    reviewId: null,
    loading: Loading.INITIAL,
  },
  createQuestion: {
    loading: Loading.INITIAL,
    error: null,
  },
  addQuestion: {
    openModal: false,
  },
};

const questions = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    updateQuestions: (state, { payload }) => {
      if (state.questions.data?.docs) {
        state.questions.data.docs = payload;
      }
    },
    toggleAddQuestionModal: (state) => {
      state.addQuestion.openModal = !state.addQuestion.openModal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchQuestions.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (!!showPreloader) {
          state.questions.loading = Loading.PENDING;
        }
      }
    );
    builder.addCase(
      fetchQuestions.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.questions.loading = Loading.SUCCESS;
        state.questions.data = payload.questions;
        state.questions.totalAnswered = payload.totalAnswered;
        state.questions.totalUnanswered = payload.totalUnanswered;
      }
    );
    builder.addCase(fetchQuestions.rejected, (state) => {
      state.questions.loading = Loading.ERROR;
    });

    builder.addCase(deleteQuestion.pending, (state) => {
      state.deleteQuestion.loading = Loading.PENDING;
    });
    builder.addCase(deleteQuestion.rejected, (state) => {
      state.deleteQuestion.loading = Loading.ERROR;
    });
    builder.addCase(deleteQuestion.fulfilled, (state) => {
      state.deleteQuestion.loading = Loading.SUCCESS;
    });
    builder.addCase(submitAnswer.pending, (state) => {
      state.submitAnswer.loading = Loading.PENDING;
    });
    builder.addCase(submitAnswer.rejected, (state, { payload }) => {
      state.submitAnswer.loading = Loading.ERROR;
      state.submitAnswer.error = payload;
    });
    builder.addCase(submitAnswer.fulfilled, (state) => {
      state.submitAnswer.loading = Loading.SUCCESS;
    });

    builder.addCase(createQuestion.pending, (state) => {
      state.createQuestion.loading = Loading.PENDING;
    });
    builder.addCase(createQuestion.rejected, (state, { payload }) => {
      state.createQuestion.loading = Loading.ERROR;
      state.createQuestion.error = payload as string;
    });
    builder.addCase(createQuestion.fulfilled, (state) => {
      state.createQuestion.loading = Loading.SUCCESS;
    });
  },
});

export const { updateQuestions, toggleAddQuestionModal } = questions.actions;
export const questionsSelector = (state: RootState) => state.questions;

export default questions.reducer;
