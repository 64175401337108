import localStorage from './localStorage';

type Params = {
  jwtAccessToken: string;
  jwtRefreshToken: string;
};

export default function setSession({
  jwtAccessToken,
  jwtRefreshToken,
}: Params) {
  localStorage.set(
    'session',
    JSON.stringify({
      jwtAccessToken,
      jwtRefreshToken,
    })
  );
}
