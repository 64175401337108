import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import { Loading } from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';

const http = new Http();

export const listWebsites = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'listWebsites',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/webflow/webflow-sites?userId=${userId}`
      );
      return response.data.sites;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        webflow: {
          sites: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);
