import { useCallback } from 'react';
import { ReviewsJetPlatform } from '../../features/product/types';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';

export const WIX_REVIEW_US_REDIRECT_URL = `https://wix.com/app-market/add-review/${process.env.REACT_APP_WIX_APP_ID}`;
export const PRODUCT_HUNT_REVIEW_US_URL =
  'https://www.producthunt.com/products/reviewsjet';
export const SHOPIFY_REVIEW_US_URL = 'https://apps.shopify.com/reviewsjet';

export default function useReviewUs() {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  return useCallback(() => {
    if (platform === ReviewsJetPlatform.WIX) {
      window.open(WIX_REVIEW_US_REDIRECT_URL, '_blank');
    } else if (platform === ReviewsJetPlatform.SHOPIFY) {
      window.open(SHOPIFY_REVIEW_US_URL, '_blank');
    } else {
      window.open(PRODUCT_HUNT_REVIEW_US_URL, '_blank');
    }
  }, [platform]);
}
