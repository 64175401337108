import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../../../shared/types/review.types';
import { RootState } from '../../../../../store';
import { listIntegrations } from './actions';

/* eslint no-console: "warn" */

export enum IntegrationPlatform {
  KLAVIYO = 'klaviyo',
  MAILCHIMP = 'mailchimp',
  INTERCOM = 'intercom',
  SENDGRID = 'sendgrid',
  HUBSPOT = 'hubspot',
  ACTIVE_CAMPAIGN = 'active_campaign',
}

export type Integration = {
  platform: IntegrationPlatform;
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: Date;
  iv?: string;
  codeVerifier?: string;
  adminId?: string;
  serverPrefix?: string;
  apiKey?: string;
  apiUrl?: string;
};

type IntegrationsState = {
  loading: Loading;
  integrations: {
    siteId: string;
    integrations: Integration[];
  } | null;
};

const initialState: IntegrationsState = {
  loading: Loading.INITIAL,
  integrations: null,
};

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    initiateIntegrationLoadingState: (state) => {
      state.loading = Loading.INITIAL;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listIntegrations.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (!!showPreloader) {
          state.loading = Loading.PENDING;
        }
      }
    );
    builder.addCase(listIntegrations.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(listIntegrations.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.integrations = payload;
    });
  },
});

export const integrationsSelector = (state: RootState) => state.integrations;
export const isMailChimpConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) => integration.platform === IntegrationPlatform.MAILCHIMP
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const isIntercomConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) => integration.platform === IntegrationPlatform.INTERCOM
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const isKlaviyoConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) =>
        integration.platform === IntegrationPlatform.KLAVIYO &&
        !!integration.accessToken
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const isActiveCampaignConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) =>
        integration.platform === IntegrationPlatform.ACTIVE_CAMPAIGN
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const isHubspotConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) => integration.platform === IntegrationPlatform.HUBSPOT
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const isSendgridConnectedSelector = createSelector(
  integrationsSelector,
  ({ integrations }) => {
    const response = integrations?.integrations?.find(
      (integration) => integration.platform === IntegrationPlatform.SENDGRID
    );

    if (response) {
      return true;
    }

    return false;
  }
);

export const { initiateIntegrationLoadingState } = integrations.actions;

export default integrations.reducer;
