import styled from 'styled-components';

interface Props {
  width?: string;
}

const HorizontalRule = styled.div<Props>`
  height: 1px;
  margin-top: 10px;
  width: ${({ width }) => width || '100%'};
  border: 1px solid #efefef;
`;

export default HorizontalRule;
