import { useState } from 'react';
import ReviewTaskSuccess from '../ReviewTaskSuccess';
import AddProductPage from './AddPropertyPage';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectAirbnbStep {
  ADD_PAGE,
  SUCCESS,
}

const ConnectAirbnb = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectAirbnbStep.ADD_PAGE);

  return (
    <>
      {step === ConnectAirbnbStep.ADD_PAGE && (
        <AddProductPage
          onConnected={() => {
            onConnected();
            setStep(ConnectAirbnbStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectAirbnbStep.SUCCESS && (
        <ReviewTaskSuccess
          onConfirmed={() => {
            onComplete();
            setStep(ConnectAirbnbStep.ADD_PAGE);
          }}
        />
      )}
    </>
  );
};

export default ConnectAirbnb;
