const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
};

export type BreakpointsType = { [key in keyof typeof breakpoints]: string };

export default breakpoints;
