import { forwardRef } from 'react';
import { Message } from 'rsuite';
import styled from 'styled-components';

const MessageText = styled.div`
  width: 320px;
`;

interface Props {
  type: 'info' | 'error' | 'success' | 'warning';
  message: string | JSX.Element;
}

const AppNotification = forwardRef<HTMLDivElement, Props>(
  ({ type, message, ...rest }, ref) => (
    <Message
      showIcon
      type={type}
      // header={<b>{capitalize(type)}</b>}
      closable
      {...rest}
      ref={ref}
    >
      <MessageText>{message}</MessageText>
    </Message>
  )
);

export default AppNotification;
