import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';

const MainText = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #000000;
  margin-bottom: 11px;
  text-align: center;
  margin-top: 50px;
`;
const Wrapper = styled(FlexContainer)`
  /* border: 1px solid red; */
`;
const SubText = styled.span<{ marginBottom?: string }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #000000;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '29px'};
  /* width: 283px; */
  text-align: center;
  max-width: 350px;
  line-height: 30px;
  color: #4f4646;
`;
const ImageWrapper = styled.div`
  margin-bottom: 29px;
  margin-top: 100px;
`;
const ContactMessage = styled.p`
  color: #000000;
  margin-top: 50px;
`;
const LeftSection = styled.div`
  width: 100%;
  height: 100vh;
  padding: 50px;
`;
const InnerLeftSection = styled.div`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;
const LogoCrown = styled.img`
  height: 50px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  border: 2px solid #eaeaea;
`;
const LogoText = styled.img`
  height: 20px;
`;
const MadeWithLove = styled.p`
  font-size: 13px;
  color: #6e6e6e;
  text-align: center;
  margin-top: 20px;
`;
const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;

interface Props {
  onRetryClick: () => void;
  showFooter?: boolean;
}

const ErrorPage = ({ onRetryClick, showFooter = true }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Wrapper stack alignItems="center">
      <LeftSection>
        <InnerLeftSection>
          <PanelWrapper>
            <FlexContainer stack justifyContent="center" alignItems="center">
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap="10px"
              >
                <a href="https://www.reviewsjet.com">
                  <ReviewsJetCrownLogo />
                </a>
              </FlexContainer>
              {/* <ImageWrapper><ErrorIllustration /></ImageWrapper> */}
              <MainText>An error occurred!</MainText>
              <SubText marginBottom="10px">{t('error.message')}</SubText>
              {/* <SubText>Maybe the button below will help.</SubText> */}
              <Button appearance="ghost" onClick={onRetryClick}>
                {t('error.try-again')}
              </Button>

              <ContactMessage>
                <Trans
                  t={t}
                  i18nKey="error.contact-message"
                  components={[
                    <a href="mailto:hello@reviewsjet.com" target="_blank">
                      {' '}
                      contact support
                    </a>,
                  ]}
                />
              </ContactMessage>
            </FlexContainer>

            <MadeWithLove>
              Made with <FontAwesomeIcon icon={faHeart} /> by FlexByt Inc. ddddd
            </MadeWithLove>
          </PanelWrapper>
        </InnerLeftSection>
      </LeftSection>
    </Wrapper>
  );
};

export default ErrorPage;
