import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { FaRegCopy } from 'react-icons/fa';
import { TiTickOutline } from 'react-icons/ti';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { ReviewsJetPlatform } from '../../product/types';

const StyledFlexContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const Logo = styled.img<{ height?: string }>`
  height: ${({ height }) => height ?? '40px'};
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid transparent;

  &:hover {
    border-color: #c5c5c5;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
`;

const Instruction = styled.p`
  margin-bottom: 20px;
`;

const CodeWrapper = styled.div`
  max-width: 100%;
  pre {
    /* margin: 0 0 20px !important; */
    padding: 10px !important;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
`;

const Title = styled.h4`
  color: #000;
  font-weight: bold;
  margin: 0%;
  font-size: 18px;
  margin-bottom: 0px;
`;

const HowToInstall = ({
  onClose,
  embedCode,
  openTutorial,
}: {
  embedCode: string;
  onClose: () => void;
  openTutorial: (platform: ReviewsJetPlatform) => void;
}) => {
  const hideTabWidget = false;
  const { t } = useTranslation('common');
  const [htmlCopied, setHtmlcopied] = useState(false);

  return (
    <>
      <Title>Installation</Title>
      <Instruction>
        Copy the installation code and paste it before <b>&lt;/head&gt;</b> tag.
      </Instruction>
      <CodeWrapper>
        <SyntaxHighlighter
          language="html"
          wrapLongLines={true}
          style={atomDark}
        >
          {embedCode}
        </SyntaxHighlighter>

        <CopyToClipboard hidden text={embedCode}>
          <StyledButton
            block
            size="lg"
            color={htmlCopied ? 'green' : 'blue'}
            appearance="primary"
            onClick={() => {
              setHtmlcopied(true);
              setTimeout(() => setHtmlcopied(false), 3000);

              // if (isWidgetSettingsUpdated) {
              //   dispatch(updateWidgetsSettings())
              //     .then(unwrapResult)
              //     .then(() => {
              //       showSuccessToast('Settings successfully saved');
              //     })
              //     .catch(() => {});
              // }
            }}
          >
            {htmlCopied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <FaRegCopy /> Copy code
              </>
            )}
          </StyledButton>
        </CopyToClipboard>

        <Divider>Or, install it on</Divider>
      </CodeWrapper>
      <StyledFlexContainer justifyContent="flex-start" gap="20px">
        <Logo
          src="/images/platforms/shopify-logo.png"
          alt="Wordpress"
          title="Install widget on Wordpress"
          height="40px"
          onClick={() => openTutorial(ReviewsJetPlatform.SHOPIFY)}
        />
        <Logo
          src="/images/platforms/wordpress-logo.png"
          alt="Wordpress"
          title="Install widget on Wordpress"
          height="40px"
          onClick={() => openTutorial(ReviewsJetPlatform.WORDPRESS)}
        />
        <Logo
          src="/images/platforms/wix-logo.png"
          alt="Wix"
          title="Install widget on Wix"
          height="35px"
          onClick={() => openTutorial(ReviewsJetPlatform.WIX)}
        />
        <Logo
          src="/images/platforms/webflow-logo.png"
          alt="Webflow"
          title="Install widget on Webflow"
          height="35px"
          onClick={() => openTutorial(ReviewsJetPlatform.WEBFLOW)}
        />
        <Logo
          src="/images/platforms/squarespace-logo.png"
          alt="Squarespace"
          title="Install widget on Squarespace"
          height="35px"
          onClick={() => openTutorial(ReviewsJetPlatform.SQUARESPACE)}
        />
        <Logo
          hidden={!!hideTabWidget}
          src="/images/platforms/go-daddy-logo.png"
          alt="GoDaddy"
          title="Install widget on GoDaddy"
          height="40px"
          onClick={() => openTutorial(ReviewsJetPlatform.GO_DADDY)}
        />
        <Logo
          src="/images/platforms/pixpa-logo.png?v=1.01"
          alt="pixpa"
          title="Install widget on pixpa"
          onClick={() => openTutorial(ReviewsJetPlatform.PIXPA)}
        />
        <Logo
          hidden
          src="/images/platforms/jimdo-logo.png"
          alt="Jimdo"
          title="Install widget on Jimdo"
          height="40px"
        />
      </StyledFlexContainer>

      <FlexContainer justifyContent="flex-end">
        <Button appearance="subtle" onClick={onClose}>
          Close
        </Button>
      </FlexContainer>
    </>
  );
};

export default HowToInstall;
