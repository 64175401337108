import { createSlice } from '@reduxjs/toolkit';
import {
  GoogleBusiness,
  Loading,
  PaginatedResponse,
  ReviewerPlatform,
  ReviewQueryParams,
  ReviewStatus,
  YelpLocation,
} from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { Product } from '../../product/types';
import {
  GOOGLE_BUSINESSESS_PER_PAGE,
  YELP_LOCATIONS_PER_PAGE,
} from '../utils/constants';
import {
  addGoogleAccount,
  addYelpAccount,
  connectFacebookPage,
  deleteReview,
  deleteReviewReply,
  disconnectFacebookPage,
  fetchReviews,
  fetchSiteRatings,
  fetchTotalReviewsPublishedAndPending,
  getGoogleBusinesses,
  getYelpLocations,
  publishReview,
  submitAReply,
  syncGoogleAccount,
  syncYelpAccount,
  unPublishReview,
  uploadReviewerAvatar,
} from './action';

export enum ReviewHeaders {
  REVIEW = 'REVIEW',
  RATING = 'RATING',
  REVIEW_DATE = 'REVIEW_DATE',
  HELPFUL_COUNT = 'HELPFUL_COUNT',
  STATUS = 'STATUS',
  REVIEWER_NAME = 'REVIEWER_NAME',
  REVIEWER_AVATAR = 'REVIEWER_AVATAR',
}

export type Reviewer = {
  _id: string;
  instanceId: string;
  email: string;
  fullName: string;
  avatar: string | null;
  created_at: string;
  updated_at: string;
};

export type Review = {
  instanceId: string;
  content: string;
  rating: number;
  status: ReviewStatus;
  reviewer: Reviewer;
  verified: boolean;
  platform: ReviewerPlatform;
  helpfulCount: number;
  pictures: string[];
  reviewDate: string;
  _id: string;
  id: string;
  created_at: string;
  updated_at: string;
  product: Product | null;
  reply: { content: string } | null;
  fromWidget?: boolean;
};

export type ReviewFormData = {
  name: string;
  rating: number;
  email: string;
  message: string;
  platform?: ReviewerPlatform;
  status?: ReviewStatus;
  reviewDate: string | null;
  productId?: string;
};

export type ModalSize = 'full' | 'lg' | 'md' | 'sm' | 'xs';

type ReviewState = {
  loadingRating: Loading;
  rating: number;
  loadingTotalReviewsPublishedAndPending: Loading;
  totalReviewsPublished: number;
  totalPending: number;
  totalReviews: number;
  reviews: PaginatedResponse<Review> | null;
  loadingReviews: Loading;
  selectedReview: Review | null;
  loadingSelectedReview: Loading;
  loadingFacebookConnect: Loading;
  loadingFacebookDisconnect: Loading;
  reviewQueryParams: ReviewQueryParams;
  loadingYelpLocations: Loading;
  yelpLocations: YelpLocation[];
  hasMoreYelpLocations: boolean;
  addingYelpAccount: Loading;
  syncingYelp: Loading;
  syncingGoogle: Loading;
  loadingGoogleBusinesses: Loading;
  googleBusinesses: GoogleBusiness[];
  addingGoogleAccount: Loading;
  hasMoreGoogleBusinessess: boolean;
  replyingToReview: Loading;
  deletingReviewReply: Loading;
  deletingReview: Loading;
  uploadAvatar: {
    loading: Loading;
  };
  savingOneTimeReviewRequest: Loading;
  campaigns: any[];
  loadingCampaigns: Loading;
  platformModalSize: ModalSize;
  openPlatformModal: boolean;
};

const initialState: ReviewState = {
  rating: 0,
  loadingRating: Loading.INITIAL,
  totalReviewsPublished: 0,
  totalPending: 0,
  totalReviews: 0,
  loadingTotalReviewsPublishedAndPending: Loading.INITIAL,
  reviews: null,
  loadingReviews: Loading.INITIAL,
  selectedReview: null,
  loadingSelectedReview: Loading.INITIAL,
  loadingFacebookConnect: Loading.INITIAL,
  loadingFacebookDisconnect: Loading.INITIAL,
  reviewQueryParams: {
    rating: null,
    pageNumber: 1,
    sort: 'desc',
    status: null,
    platform: null,
  },
  loadingYelpLocations: Loading.INITIAL,
  yelpLocations: [],
  hasMoreYelpLocations: false,
  addingYelpAccount: Loading.INITIAL,
  syncingYelp: Loading.INITIAL,
  syncingGoogle: Loading.INITIAL,
  loadingGoogleBusinesses: Loading.INITIAL,
  googleBusinesses: [],
  addingGoogleAccount: Loading.INITIAL,
  hasMoreGoogleBusinessess: false,
  replyingToReview: Loading.INITIAL,
  deletingReviewReply: Loading.INITIAL,
  deletingReview: Loading.INITIAL,
  uploadAvatar: {
    loading: Loading.INITIAL,
  },
  savingOneTimeReviewRequest: Loading.INITIAL,
  loadingCampaigns: Loading.INITIAL,
  campaigns: [],
  platformModalSize: 'sm',
  openPlatformModal: false,
};

const review = createSlice({
  name: 'review-old',
  initialState,
  reducers: {
    setSelectedReview: (state, { payload }) => {
      state.selectedReview = payload;
    },
    setReviewQueryParams: (state, { payload }) => {
      state.reviewQueryParams = payload;
    },
    initGoogleBusinessesSearch: (state) => {
      state.googleBusinesses = [];
      state.loadingGoogleBusinesses = Loading.INITIAL;
      state.hasMoreGoogleBusinessess = false;
    },
    setPlatformModalSize: (state, { payload }) => {
      state.platformModalSize = payload;
    },
    setOpenPlatformModal: (state, { payload }) => {
      state.openPlatformModal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSiteRatings.pending, (state) => {
      state.loadingRating = Loading.PENDING;
    });
    builder.addCase(fetchSiteRatings.fulfilled, (state, { payload }) => {
      state.loadingRating = Loading.SUCCESS;
      state.rating = payload.rating;
    });
    builder.addCase(fetchSiteRatings.rejected, (state) => {
      state.loadingRating = Loading.ERROR;
    });
    builder.addCase(fetchTotalReviewsPublishedAndPending.pending, (state) => {
      state.loadingTotalReviewsPublishedAndPending = Loading.PENDING;
    });
    builder.addCase(
      fetchTotalReviewsPublishedAndPending.fulfilled,
      (state, { payload }) => {
        state.loadingTotalReviewsPublishedAndPending = Loading.SUCCESS;
        state.totalReviewsPublished = payload.totalPublished;
        state.totalPending = payload.totalPending;
        state.totalReviews = payload.totalPending + payload.totalPublished;
      }
    );
    builder.addCase(fetchTotalReviewsPublishedAndPending.rejected, (state) => {
      state.loadingTotalReviewsPublishedAndPending = Loading.ERROR;
    });
    builder.addCase(fetchReviews.pending, (state) => {
      state.loadingReviews = Loading.PENDING;
    });
    builder.addCase(fetchReviews.fulfilled, (state, { payload }) => {
      state.loadingReviews = Loading.SUCCESS;
      state.reviews = payload.reviews;
    });
    builder.addCase(fetchReviews.rejected, (state) => {
      state.loadingReviews = Loading.ERROR;
    });
    builder.addCase(publishReview.pending, (state) => {
      state.loadingSelectedReview = Loading.PENDING;
    });
    builder.addCase(publishReview.fulfilled, (state) => {
      state.loadingSelectedReview = Loading.SUCCESS;
      if (state.selectedReview) {
        state.selectedReview.status = ReviewStatus.PUBLISHED;
      }
    });
    builder.addCase(publishReview.rejected, (state) => {
      state.loadingSelectedReview = Loading.ERROR;
    });
    builder.addCase(unPublishReview.pending, (state) => {
      state.loadingSelectedReview = Loading.PENDING;
    });
    builder.addCase(unPublishReview.fulfilled, (state) => {
      state.loadingSelectedReview = Loading.SUCCESS;
      if (state.selectedReview) {
        state.selectedReview.status = ReviewStatus.PENDING;
      }
    });
    builder.addCase(unPublishReview.rejected, (state) => {
      state.loadingSelectedReview = Loading.ERROR;
    });
    builder.addCase(connectFacebookPage.pending, (state) => {
      state.loadingFacebookConnect = Loading.PENDING;
    });
    builder.addCase(connectFacebookPage.fulfilled, (state) => {
      state.loadingFacebookConnect = Loading.SUCCESS;
    });
    builder.addCase(connectFacebookPage.rejected, (state) => {
      state.loadingFacebookConnect = Loading.ERROR;
    });
    builder.addCase(disconnectFacebookPage.pending, (state) => {
      state.loadingFacebookDisconnect = Loading.PENDING;
    });
    builder.addCase(disconnectFacebookPage.fulfilled, (state) => {
      state.loadingFacebookDisconnect = Loading.SUCCESS;
    });
    builder.addCase(disconnectFacebookPage.rejected, (state) => {
      state.loadingFacebookDisconnect = Loading.ERROR;
    });

    builder.addCase(getYelpLocations.pending, (state) => {
      state.loadingYelpLocations = Loading.PENDING;
    });
    builder.addCase(getYelpLocations.fulfilled, (state, { payload }) => {
      state.loadingYelpLocations = Loading.SUCCESS;
      state.yelpLocations = [...state.yelpLocations, ...payload];
      state.hasMoreYelpLocations = payload.length >= YELP_LOCATIONS_PER_PAGE;
    });
    builder.addCase(getYelpLocations.rejected, (state) => {
      state.loadingYelpLocations = Loading.ERROR;
    });
    builder.addCase(addYelpAccount.pending, (state) => {
      state.addingYelpAccount = Loading.PENDING;
    });
    builder.addCase(addYelpAccount.fulfilled, (state, { payload }) => {
      state.addingYelpAccount = Loading.SUCCESS;
    });
    builder.addCase(addYelpAccount.rejected, (state) => {
      state.addingYelpAccount = Loading.ERROR;
    });
    builder.addCase(syncYelpAccount.pending, (state) => {
      state.syncingYelp = Loading.PENDING;
    });
    builder.addCase(syncYelpAccount.fulfilled, (state) => {
      state.syncingYelp = Loading.SUCCESS;
    });
    builder.addCase(syncYelpAccount.rejected, (state) => {
      state.syncingYelp = Loading.ERROR;
    });
    builder.addCase(syncGoogleAccount.pending, (state) => {
      state.syncingGoogle = Loading.PENDING;
    });
    builder.addCase(syncGoogleAccount.fulfilled, (state) => {
      state.syncingGoogle = Loading.SUCCESS;
    });
    builder.addCase(syncGoogleAccount.rejected, (state) => {
      state.syncingGoogle = Loading.ERROR;
    });
    //
    builder.addCase(getGoogleBusinesses.pending, (state) => {
      state.loadingGoogleBusinesses = Loading.PENDING;
    });
    builder.addCase(getGoogleBusinesses.fulfilled, (state, { payload }) => {
      state.loadingGoogleBusinesses = Loading.SUCCESS;
      state.googleBusinesses = [...state.googleBusinesses, ...payload];
      state.hasMoreGoogleBusinessess =
        payload.length >= GOOGLE_BUSINESSESS_PER_PAGE;
    });
    builder.addCase(getGoogleBusinesses.rejected, (state) => {
      state.loadingGoogleBusinesses = Loading.ERROR;
    });

    builder.addCase(addGoogleAccount.pending, (state) => {
      state.addingGoogleAccount = Loading.PENDING;
    });
    builder.addCase(addGoogleAccount.fulfilled, (state, { payload }) => {
      state.addingGoogleAccount = Loading.SUCCESS;
    });
    builder.addCase(addGoogleAccount.rejected, (state) => {
      state.addingGoogleAccount = Loading.ERROR;
    });
    builder.addCase(submitAReply.pending, (state) => {
      state.replyingToReview = Loading.PENDING;
    });
    builder.addCase(submitAReply.rejected, (state) => {
      state.replyingToReview = Loading.ERROR;
    });
    builder.addCase(submitAReply.fulfilled, (state, { payload }) => {
      state.replyingToReview = Loading.SUCCESS;
      if (state.selectedReview) {
        state.selectedReview.reply = { content: payload };
      }
    });
    builder.addCase(deleteReviewReply.pending, (state) => {
      state.deletingReviewReply = Loading.PENDING;
    });
    builder.addCase(deleteReviewReply.rejected, (state) => {
      state.deletingReviewReply = Loading.ERROR;
    });
    builder.addCase(deleteReviewReply.fulfilled, (state) => {
      state.deletingReviewReply = Loading.SUCCESS;
      if (state.selectedReview) {
        state.selectedReview.reply = null;
      }
    });
    builder.addCase(deleteReview.pending, (state) => {
      state.deletingReview = Loading.PENDING;
    });
    builder.addCase(deleteReview.rejected, (state) => {
      state.deletingReview = Loading.ERROR;
    });
    builder.addCase(deleteReview.fulfilled, (state) => {
      state.deletingReview = Loading.SUCCESS;
    });
    builder.addCase(uploadReviewerAvatar.pending, (state) => {
      state.uploadAvatar.loading = Loading.PENDING;
    });
    builder.addCase(uploadReviewerAvatar.fulfilled, (state) => {
      state.uploadAvatar.loading = Loading.SUCCESS;
    });
  },
});

export const {
  setSelectedReview,
  setReviewQueryParams,
  initGoogleBusinessesSearch,
  setPlatformModalSize,
  setOpenPlatformModal,
} = review.actions;

export const reviewSelector = (state: RootState) => state.review;

export default review.reducer;
