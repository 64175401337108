import { BiLogOut } from 'react-icons/bi';
import { FaRocket, FaStar, FaUserCircle } from 'react-icons/fa';
import { GoMegaphone } from 'react-icons/go';
import { LuMap } from 'react-icons/lu';
import { MdSpaceDashboard } from 'react-icons/md';
import { RiMailSendFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
import styled from 'styled-components';
import { ReviewsJetPlatform } from '../../features/product/types';
import {
  accountSelector,
  setCelebration,
  toggleFeedbackDrawer,
  userAccountConfigSelector,
} from '../../features/userData/redux/userData.slice';
import FlexContainer from '../../shared/components/layouts/flex-container';
import AppModal from '../../shared/components/modal';
import useModal from '../../shared/components/modal/useModal';
import useReviewUs from '../../shared/hooks/useReviewUs';
import useUpgradeLink from '../../shared/hooks/useUpgradeLink';
import { SubscriptionPlan } from '../../shared/types/review.types';
import { getUpgradeToNextPlanMessage } from '../../shared/utils/getUpgradeMessage';
import localStorage from '../../shared/utils/localStorage';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  toggleChangelogModal,
  toggleUpgradeModal,
} from '../../store/app.slice';
import RedeemCode from './RedeemCode';

const StyledDropdown = styled(Dropdown)`
  li {
    min-width: 200px;
  }
`;

const MenuDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const siteConfigs = useAppSelector(userAccountConfigSelector);
  const { showModal, handleCloseModal, handleOpenModal } = useModal();
  const reviewUs = useReviewUs();
  const { account, celebrate } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};
  const { handleUpgrade } = useUpgradeLink();

  const logout = () => {
    localStorage.remove('session');
    window.open(process.env.REACT_APP_REVIEWSJET_WEBSITE, '_self');
  };

  return (
    <>
      <div className="profile-wrapper">
        <StyledDropdown
          className="profile"
          placement="bottomEnd"
          appearance="subtle"
          title="Profile"
          icon={<FaUserCircle size={25} />}
        >
          {platform !== ReviewsJetPlatform.WIX && (
            <DropdownItem onClick={() => navigate('/manage-reviews')}>
              <FlexContainer gap="5px" justifyContent="flex-start">
                <MdSpaceDashboard />
                <span>Manage reviews</span>
              </FlexContainer>
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              dispatch(
                toggleUpgradeModal(
                  getUpgradeToNextPlanMessage(SubscriptionPlan.ADVANCED)
                )
              );
            }}
          >
            <FlexContainer gap="5px" justifyContent="flex-start">
              <FaRocket />
              <span>Upgrade</span>
            </FlexContainer>
          </DropdownItem>

          <Dropdown.Separator />
          <DropdownItem
            onClick={() =>
              window.open(
                'https://reviewsjet.featurebase.app/roadmap',
                '_blank'
              )
            }
          >
            <FlexContainer gap="5px" justifyContent="flex-start">
              <LuMap />
              <span>Roadmap</span>
            </FlexContainer>
          </DropdownItem>
          <DropdownItem onClick={() => dispatch(toggleChangelogModal(true))}>
            <FlexContainer gap="5px" justifyContent="flex-start">
              <GoMegaphone />
              <span>Changelog</span>
            </FlexContainer>
          </DropdownItem>
          <Dropdown.Separator />

          <DropdownItem onClick={reviewUs}>
            <FlexContainer gap="5px" justifyContent="flex-start">
              <FaStar />
              <span>Rate us</span>
            </FlexContainer>
          </DropdownItem>

          {/* <DropdownItem onClick={handleOpenModal}>
            <FlexContainer gap="5px" justifyContent="flex-start">
              <PiGiftBold />
              <span>Redeem code</span>
            </FlexContainer>
          </DropdownItem> */}
          <DropdownItem onClick={() => dispatch(toggleFeedbackDrawer())}>
            <FlexContainer gap="5px" justifyContent="flex-start">
              <RiMailSendFill />
              <span>Contact support</span>
            </FlexContainer>
          </DropdownItem>

          {siteConfigs?.canLogout && (
            <>
              <Dropdown.Separator />
              <DropdownItem onClick={logout} style={{ color: 'crimson' }}>
                <FlexContainer gap="5px" justifyContent="flex-start">
                  <BiLogOut />
                  <span>Sign out</span>
                </FlexContainer>
              </DropdownItem>
            </>
          )}
        </StyledDropdown>
      </div>
      <AppModal
        size="xs"
        shouldOpenModal={showModal}
        onModalClose={() => {
          handleCloseModal();
          if (celebrate !== null) {
            dispatch(setCelebration(null));
            window.location.reload();
          }
        }}
        body={<RedeemCode closeModal={handleCloseModal} />}
      />
    </>
  );
};

export default MenuDropdown;
