export const REVIEWS_PAGE_PER_PAGE = 50;
export const EMAILS_PAGE_PER_PAGE = 15;
export const QUESTIONS_PAGE_PER_PAGE = 50;
export const CAMPAIGNS_PER_PAGE = 10;
export const BULK_REVIEWS_IMPORT_DATE_FORMAT = 'D/M/YYYY';
export const FREE_PLAN_REVIEWS_LIMIT = 50;
export const PROFESSIONAL_REVIEWS_LIMIT = 500;
export const FREE_PLAN_YELP_LIMIT = 10;
export const PRODUCT_ITEMS_PER_PAGE = 250;
export const SEARCH_DEBOUNCE_DELAY = 500;
export const SHOW_REVIEW_US_REVIEWS_LIMIT = 5;

export const SHOW_DISCOUNT_PUBLISHED_REVIEWS_LIMIT = 3;
export const ONE_WEEK = 7;
export const DISCOUNT_CODE = '05FAHalloweenSales2023';
export const REVIEWS_LIMIT = {
  FREE_PLAN_REVIEWS_LIMIT: 50,
  PROFESSIONAL_REVIEWS_LIMIT: 500,
};
export const REVIEWS_REQUEST_LIMIT = {
  FREE_PLAN_REVIEWS_LIMIT: 10,
  PROFESSIONAL_REVIEWS_LIMIT: 500,
};

export enum LOCAL_STORAGE_KEYS {
  SHOW_UPGRADE_MODAL = 'showUpgradeModal',
  SHOW_REVIEW_US_MODAL = 'showReviewUsModal',
  TIME_TO_SHOW_DISCOUNT_MESSAGE = 'timeToShowDiscountMessage',
  TIME_TO_SHOW_REVIEW_US_MODAL = 'timeToShowReviewUsModal',
}

export const WIX_PRODUCT_PAGE_PATH = 'product-page';
export const SHOPIFY_PRODUCT_PAGE_PATH = 'products';
export const BRANDING_PATH = '/settings/page/brand';
export const PRODUCT_WIDGET_SETTINGS_PATH = '/settings/page/product';
export const SITE_WIDGET_SETTINGS_PATH = '/settings/page/site';
export const EMBEDDED_REVIEW_WIDGET_SETTINGS_PATH = '/settings/page/embedded';
export const CAROUSEL_WIDGET_SETTINGS_PATH = '/settings/page/carousel';

export const YELP_LOCATIONS_PER_PAGE = 10;
export const GOOGLE_BUSINESSESS_PER_PAGE = 10;
export const APPLE_STORES_PER_PAGE = 10;
export const GOOGLE_APIS_BASE_URL = 'https://www.googleapis.com';
export enum ReviewsRequestStep {
  MESSAGE,
  CONTACTS,
}
export const REVIEW_CARD_OPACITY = 80;
export const PLAN_PRICES = {
  FREE: {
    monthly: 0,
    anually: 0,
  },
  PRO: {
    monthly: 7.99,
    anually: 5.99,
  },
  ADVANCED: {
    monthly: 19.99,
    anually: 15.99,
  },
  PREMIUM: {
    monthly: 29.99,
    anually: 25.99,
  },
};

export const CHANGE_LOG_LINK = 'https://reviewsjet.featurebase.app/changelog';
export const HIDE_CHANGE_LOG = 'HIDE_CHANGE_LOG-1';
export enum BorderRadius {
  NONE = '',
  SMALL = '5px',
  MEDIUM = '10px',
  LARGE = '15px',
}
