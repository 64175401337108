import { useTranslation } from 'react-i18next';
import { Divider, Form, Radio, RadioGroup } from 'rsuite';
import { ValueType } from 'rsuite/esm/Radio';
import styled from 'styled-components';
import ColorPicker from '../../../../../shared/components/colorPicker';
import { useAppSelector } from '../../../../../store';
import FormRow from '../../../components/form-row';
import {
  ShadowSize,
  ShadowType,
  settingSelector,
} from '../../../redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../shared/hooks/useDispatchUpdateSiteWidgetSettings';

const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
  justify-content: space-between;
`;

const ShadowSettings = () => {
  const { t } = useTranslation('common');
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <>
      Shadow type
      <FormRow justifyContent="space-between" gap="10px">
        <Form.Group controlId="radioList" style={{ width: '50%' }}>
          <StyledRadioGroup
            name="shadowType"
            inline
            appearance="default"
            value={siteWidgetSettings?.carouselSliderWidget.shadowType}
            onChange={(value: ValueType | undefined, _: boolean) => {
              dispatchUpdateSiteWidgetSettings('shadowType', value, 'slider');
            }}
          >
            <Radio value={ShadowType.NONE}>None</Radio>
            <Radio value={ShadowType.NORMAL}>{ShadowType.NORMAL}</Radio>
            <Radio value={ShadowType.FOCUS}>{ShadowType.FOCUS}</Radio>
          </StyledRadioGroup>
        </Form.Group>
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.shadowType !==
        ShadowType.NONE && (
        <>
          <Divider />
          Shadow size
          <FormRow justifyContent="space-between" gap="10px">
            <Form.Group controlId="radioList" style={{ width: '50%' }}>
              <StyledRadioGroup
                name="radioList"
                inline
                appearance="default"
                value={siteWidgetSettings?.carouselSliderWidget.shadowSize}
                onChange={(value: ValueType | undefined, _: boolean) => {
                  dispatchUpdateSiteWidgetSettings(
                    'shadowSize',
                    value,
                    'slider'
                  );
                }}
              >
                <Radio value={ShadowSize.SMALL}>Small</Radio>
                <Radio value={ShadowSize.MEDIUM}>Medium</Radio>
                <Radio value={ShadowSize.LARGE}>Large</Radio>
              </StyledRadioGroup>
            </Form.Group>
          </FormRow>
          <Divider />
          <FormRow justifyContent="flex-start" gap="10px">
            Shadow color
            <ColorPicker
              color={siteWidgetSettings?.carouselSliderWidget.shadowColor!}
              changeColor={(color: string) =>
                dispatchUpdateSiteWidgetSettings('shadowColor', color, 'slider')
              }
            />
          </FormRow>
        </>
      )}
    </>
  );
};

export default ShadowSettings;
