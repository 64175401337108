import axios from 'axios';
import { RemoteFilePath } from '../shared/types/review.types';
import removeTrailingSlash from '../shared/utils/removeTrailingSlash';

type FileArgs = {
  fileName: string;
  remotePath: RemoteFilePath;
};

export const deleteFile = async ({ fileName, remotePath }: FileArgs) => {
  return axios.delete(
    `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/remote-files/${remotePath}`,
    { data: { fileName } }
  );
};

export const getFileUploadUrl = ({ remotePath }: Omit<FileArgs, 'fileName'>) =>
  `${removeTrailingSlash(
    process.env.REACT_APP_REVIEWS_JET_API!
  )}/remote-files/${remotePath}`;
