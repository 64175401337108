import axios from 'axios';
import { PlanCycle, SubscriptionPlan } from '../shared/types/review.types';
import removeTrailingSlash from '../shared/utils/removeTrailingSlash';

export const createBillingPortalSession = async () => {
  return axios.post(
    `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/stripe/billing-portal-session`
  );
};

export const createCheckoutSession = async ({
  plan,
  cycle,
}: {
  plan: SubscriptionPlan;
  cycle: PlanCycle;
}) => {
  return axios.post(
    `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/stripe/checkout-session`,
    { plan, cycle }
  );
};
