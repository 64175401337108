import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { createIncentive, deleteIncentive, fetchIncentives } from './action';

export type Incentive = {
  _id: string;
  siteId: string;
  discountCode: string;
  fixedAmountOff?: number;
  percentageOff?: number;
  limit: string;
  created_at: string;
  updated_at: string;
};

type ReviewState = {
  loading: Loading;
  items: Incentive[];
  deleteLoading: Loading;
  createLoading: Loading;
};

const initialState: ReviewState = {
  loading: Loading.INITIAL,
  items: [],
  deleteLoading: Loading.INITIAL,
  createLoading: Loading.INITIAL,
};

const review = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    updateIncentivesList: (state, { payload }) => {
      state.items = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncentives.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(fetchIncentives.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(fetchIncentives.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.items = payload.incentives;
    });
    builder.addCase(createIncentive.pending, (state) => {
      state.createLoading = Loading.PENDING;
    });
    builder.addCase(createIncentive.rejected, (state) => {
      state.createLoading = Loading.ERROR;
    });
    builder.addCase(
      createIncentive.fulfilled,
      (state, { payload: newIncentive }) => {
        state.items = [newIncentive, ...state.items];
        state.createLoading = Loading.SUCCESS;
      }
    );
    builder.addCase(deleteIncentive.pending, (state) => {
      state.deleteLoading = Loading.PENDING;
    });
    builder.addCase(deleteIncentive.rejected, (state) => {
      state.deleteLoading = Loading.ERROR;
    });
    builder.addCase(deleteIncentive.fulfilled, (state) => {
      state.deleteLoading = Loading.SUCCESS;
    });
  },
});

export const { updateIncentivesList } = review.actions;

export const reviewSelector = (state: RootState) => state.review;

export default review.reducer;
