import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { fetchPlans } from './actions';

export enum PlanName {
  FREE = 'free',
  PRO = 'professional',
  ADVANCED = 'advanced',
  PREMIUM = 'premium',
}

export type Plan = {
  name: PlanName;
  monthly: number;
  anually: number;
};

type PlanState = {
  loading: Loading;
  data: Plan[];
};

const initialState: PlanState = {
  loading: Loading.INITIAL,
  data: [],
};

const plans = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlans.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(fetchPlans.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(fetchPlans.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.data = payload;
    });
  },
});

export const plansSelector = (state: RootState) => state.plans;

export default plans.reducer;
