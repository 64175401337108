import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa6';
import { Button, Tag, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import { ReviewsJetPlatform } from '../../features/product/types';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';
import { useNotification } from '../hooks/useNotification';
import { SubscriptionPlan } from '../types/review.types';
import FlexContainer from './layouts/flex-container';

const Wrapper = styled.div`
  flex: 1;
  .rs-tag-default {
    color: #000 !important;
    font-weight: bold;
  }
`;

const Message = styled(FlexContainer)`
  width: 100%;

  .mobile {
    display: none;
  }
  @media (max-width: 895px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;

export default function DiscountCode() {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform, plan } = selectedSite ?? {};
  const { showInfoToast } = useNotification();

  const discountCode = '05FAHOWDY';

  if (platform !== ReviewsJetPlatform.WIX || plan !== SubscriptionPlan.FREE) {
    return null;
  }

  return (
    <Wrapper>
      <Message gap="5px">
        <span className="desktop">
          Get <Tag>50% off</Tag> your first month!
        </span>
        <span className="mobile">
          Get <strong>50% off</strong>!
        </span>
        <Whisper
          placement="bottom"
          controlId="control-id-context-menu"
          trigger="hover"
          speaker={<Tooltip>Copy {discountCode} to clipboard.</Tooltip>}
        >
          <CopyToClipboard hidden text={discountCode}>
            <Button
              size="sm"
              onClick={() =>
                showInfoToast('Discount code copied! Use it at checkout. ')
              }
            >
              <FaCopy />
            </Button>
          </CopyToClipboard>
        </Whisper>
      </Message>
    </Wrapper>
  );
}
