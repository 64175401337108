import { useTranslation } from 'react-i18next';
import { Col, Divider, InputNumber, Row, Slider } from 'rsuite';
import ColorPicker from '../../../../../../shared/components/colorPicker';
import { useAppSelector } from '../../../../../../store';
import FormRow from '../../../../components/form-row';
import { settingSelector } from '../../../../redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../../shared/hooks/useDispatchUpdateSiteWidgetSettings';

const BackgroundSettings = () => {
  const { t } = useTranslation('common');
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  if (!siteWidgetSettings) {
    return null;
  }

  const [left, right] =
    siteWidgetSettings.carouselSliderWidget.backgroundColorGradient.split(',');

  return (
    <>
      {/* <BlockLabel>Card background color</BlockLabel>
      <FormRow justifyContent="flex-start" gap="10px">
        <RadioTileGroup
          inline
          aria-label="Card Background Color"
          value={
            siteWidgetSettings?.carouselSliderWidget.reviewCardBackgroundType
          }
          onChange={(value: ValueType, _: SyntheticEvent<Element, Event>) => {
            dispatchUpdateSiteWidgetSettings(
              'reviewCardBackgroundType',
              value,
              'slider'
            );
          }}
        >
          <StyledRadioTile
            label="Solid Colid"
            value={BackgroundType.SOLID_COLOR}
          />
          <StyledRadioTile
            label="Transparent"
            value={BackgroundType.TRANSPARENT}
          />
        </RadioTileGroup>
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.reviewCardBackgroundType ===
        BackgroundType.SOLID_COLOR && ( */}
      <FormRow justifyContent="flex-start" gap="10px">
        Color
        <ColorPicker
          color={siteWidgetSettings.carouselSliderWidget.reviewCardColor}
          changeColor={(color: string) =>
            dispatchUpdateSiteWidgetSettings('reviewCardColor', color, 'slider')
          }
        />
      </FormRow>

      <Divider />

      <FormRow justifyContent="flex-start" gap="10px">
        <div style={{ width: '100%' }}>
          Transparency
          <Row>
            <Col md={6} xs={12}>
              <Slider
                progress
                style={{ marginTop: 16 }}
                value={
                  siteWidgetSettings.carouselSliderWidget.reviewCardColorOpacity
                }
                onChange={(value) => {
                  dispatchUpdateSiteWidgetSettings(
                    'reviewCardColorOpacity',
                    value,
                    'slider'
                  );
                }}
              />
            </Col>
            <Col md={5} xs={12}>
              <InputNumber
                max={100}
                min={10}
                postfix="%"
                value={
                  siteWidgetSettings.carouselSliderWidget.reviewCardColorOpacity
                }
                onChange={(value) => {
                  dispatchUpdateSiteWidgetSettings(
                    'reviewCardColorOpacity',
                    value,
                    'slider'
                  );
                }}
              />
            </Col>
          </Row>
        </div>
      </FormRow>

      {/* )} */}
      {/* <Divider />
      <BlockLabel>Widget background color</BlockLabel>
      <FormRow justifyContent="flex-start" gap="10px">
        <RadioTileGroup
          inline
          aria-label="Widget Background Color"
          value={siteWidgetSettings?.carouselSliderWidget.backgroundColorType}
          onChange={(value: ValueType, _: SyntheticEvent<Element, Event>) => {
            dispatchUpdateSiteWidgetSettings(
              'backgroundColorType',
              value,
              'slider'
            );
          }}
        >
          <StyledRadioTile
            label="Solid color"
            value={BackgroundType.SOLID_COLOR}
          />
          <StyledRadioTile
            label="Transparent"
            value={BackgroundType.TRANSPARENT}
          />
          <StyledRadioTile label="Gradient" value={BackgroundType.GRADIENT} />
        </RadioTileGroup>
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.backgroundColorType ===
        BackgroundType.SOLID_COLOR && (
        <FormRow justifyContent="flex-start" gap="10px">
          Color
          <ColorPicker
            color={siteWidgetSettings.carouselSliderWidget.backgroundColor}
            changeColor={(color: string) =>
              dispatchUpdateSiteWidgetSettings(
                'backgroundColor',
                color,
                'slider'
              )
            }
          />
        </FormRow>
      )}

      {siteWidgetSettings?.carouselSliderWidget.backgroundColorType ===
        BackgroundType.GRADIENT && (
        <FormRow justifyContent="flex-start" gap="20px">
          <GradientColors left={left} right={right} />
        </FormRow>
      )} */}
    </>
  );
};

export default BackgroundSettings;
