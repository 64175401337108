const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.618 12.396h1.07A3.908 3.908 0 0 0 15.584 8.5c0-2.14-1.75-3.896-3.896-3.896h-1.07m-4.243 0H5.313A3.903 3.903 0 0 0 1.417 8.5c0 2.14 1.75 3.896 3.896 3.896h1.062M5.667 8.5h5.667"
    />
  </svg>
);

export default LinkIcon;
