import colors, { ColorsType } from './colors';
import breakpoints, { BreakpointsType } from './breakpoints';
import typography, { TypographyType } from './typography';

export type ThemeStyleType = {
  breakpoints: BreakpointsType;
  colors: ColorsType;
  typography: TypographyType;
};

const theme: ThemeStyleType = { colors, breakpoints, typography };

export default theme;
