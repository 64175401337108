import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input, Message, SelectPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import {
  GoogleConnectFormData,
  Loading,
} from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useGoogleConnectContext } from '../../../contexts/GoogleLocationContext';
import { getGoogleBusinesses } from '../../../redux/actions';
import {
  initGoogleBusinessesSearch,
  socialReviewsSelector,
} from '../../../redux/social-reviews.slice';
import { googlePageLanguages } from '../../../utils/google-page-languages';

const StyledPicker = styled(SelectPicker)`
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
`;

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;
const FormContainer = styled.form`
  padding-top: 20px;
`;
const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
`;

const StyledMessage = styled(Message)`
  margin-bottom: 20px;
  .rs-message-body {
    display: block !important;
  }
`;

const AnchorTag = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const data = googlePageLanguages.map((item) => ({
  label: item.language_name,
  value: item.language_code,
}));

const SearchBusiness = ({
  onSuccess,
  resetCloseButton,
}: {
  onSuccess: () => void;
  resetCloseButton?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const { showErrorToast } = useNotification();
  const { formValues, updateFormValues } = useGoogleConnectContext();
  const { loadingGoogleBusinesses } = useAppSelector(socialReviewsSelector);
  const isLoading = loadingGoogleBusinesses === Loading.PENDING;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<GoogleConnectFormData>({
    defaultValues: formValues,
  });

  const onSubmit = handleSubmit((data) => {
    updateFormValues(data);
    setShowNotFoundMessage(false);
    dispatch(initGoogleBusinessesSearch());
    dispatch(getGoogleBusinesses(data))
      .then(unwrapResult)
      .then(() => {
        reset();
        onSuccess();
      })
      .catch(() => {
        setShowNotFoundMessage(true);
        showErrorToast(t('reviews.import-source.google.business-find-fail'));
      });
  });

  return (
    <>
      <ModalHeader
        title="Search your business"
        closeButton={false}
        resetCloseButton={resetCloseButton}
      />
      <ModalBody>
        <FormContainer onSubmit={onSubmit}>
          <StyledLabel>
            {t('reviews.import-source.google.your-google-business-address')}
          </StyledLabel>
          <Controller
            name="businessAddress"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledInput
                error={errors.businessAddress}
                placeholder="E.g. Crown Castle International"
                {...field}
              />
            )}
          />
          {/* <FlexContainer
            gap="10px"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginBottom: 5 }}
          >
            <StyledLabel>
              {t('reviews.import-source.google.your-gpsCoordinates')}
            </StyledLabel>
            <span>-</span>
            <AnchorTag
              href="https://www.reviewsjet.com/google-gps-coordinates.html"
              target="_blank"
            >
              {t('reviews.import-source.google.where-find-this')}
              <BsBoxArrowUpRight />
            </AnchorTag>
          </FlexContainer>
          <Controller
            name="gpsCoordinates"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledInput
                error={errors.gpsCoordinates}
                placeholder="E.g. @34.0146894,-92.2643131,5z"
                {...field}
              />
            )}
          /> */}

          <StyledLabel>In what language are your Google reviews? </StyledLabel>
          <Controller
            name="hl"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <StyledPicker block cleanable={false} data={data} {...field} />
            )}
          />

          {showNotFoundMessage && (
            <div>
              <StyledMessage>
                <p>
                  <b>{t('reviews.import-source.google.not-found-message-1')}</b>
                </p>
                <p>
                  {t('reviews.import-source.google.google.not-found-message-2')}
                </p>
              </StyledMessage>
            </div>
          )}
          <ContinueButton
            block={false}
            type="submit"
            appearance={isLoading ? 'subtle' : 'primary'}
            size="md"
          >
            {isLoading ? t('shared.please-wait') : t('shared.search')}
          </ContinueButton>
        </FormContainer>
      </ModalBody>
    </>
  );
};

export default SearchBusiness;
