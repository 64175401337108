import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import { WidgetType } from '../../../../shared/types/review.types';
import {
  EMBEDDED_DEVELOPMENT_JS,
  EMBEDDED_PRODUCTION_JS,
} from '../../../../shared/utils/installations';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import {
  accountSelector,
  toggleFeedbackDrawer,
} from '../../../userData/redux/userData.slice';
import Troubleshooting from '../Troubleshooting';

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  pre {
    margin: 0 0 20px !important;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: 25px;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;
const StepWrapper = styled.div`
  margin-bottom: 30px;
`;

const WixCarouselWidget = ({ widgetType }: { widgetType: WidgetType }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform, _id: siteId, instanceId } = selectedSite ?? {};
  const [copied, setCopied] = useState(false);
  const installationCode =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? EMBEDDED_PRODUCTION_JS
      : EMBEDDED_DEVELOPMENT_JS;
  const embedCode =
    widgetType === 'marquee' ? `reviewsjet-marquee` : `reviewsjet-slider`;

  return (
    <Wrapper>
      {/* <PageTitle>How To Install the Carousel Widget on Wix</PageTitle> */}

      <StepWrapper>
        <Step>
          <StepNumber>Step 1</StepNumber>
          <div>Add a Custom Element</div>
        </Step>
        <StepDesc>
          Add a Custom element as shown in the screenshot below.
        </StepDesc>
        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/full-page-widget-v2/1.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 2</StepNumber>
          <div>Choose Source</div>
        </Step>
        <StepDesc>
          Click the <strong>Choose Source</strong> button as shown in the
          screenshot below then add the code below in the Server URL input box:
        </StepDesc>

        <SyntaxHighlighter language="go" wrapLongLines={true}>
          {installationCode}
        </SyntaxHighlighter>
        <CopyToClipboard text={installationCode}>
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>

        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-2.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 3</StepNumber>
          <div>
            Set the <strong>Tag name</strong>
          </div>
        </Step>
        <StepDesc>
          Add the code below in the Tag name input box as shown in the
          screenshot below:
        </StepDesc>

        <SyntaxHighlighter language="html" wrapLongLines={true}>
          {embedCode}
        </SyntaxHighlighter>
        <CopyToClipboard text={embedCode}>
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>

        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-2.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 4</StepNumber>
          <div>
            Click <strong>Set Attributes</strong>
          </div>
        </Step>
        <StepDesc>
          Click the Set Attributes buttons as shown in the screenshot below
        </StepDesc>

        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-3.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 5</StepNumber>
          <div>
            Set <strong>id</strong>
          </div>
        </Step>
        <StepDesc>
          Enter <strong>id</strong> in the <strong>Attribute name</strong> input
          box.
        </StepDesc>
        <StepDesc>
          Then enter the ACCOUNT_ID below in the <strong>Value</strong> input
          box and click the Set button:
        </StepDesc>
        <StepDesc>
          <strong>ACCOUNT_ID:</strong>
        </StepDesc>
        <SyntaxHighlighter language="html" wrapLongLines={true}>
          {`${platform === ReviewsJetPlatform.WIX ? instanceId : siteId}`}
        </SyntaxHighlighter>
        <CopyToClipboard
          text={platform === ReviewsJetPlatform.WIX ? instanceId : siteId}
        >
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>

        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-4.png?v=1"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      {widgetType !== 'marquee' && (
        <StepWrapper>
          <Step>
            <StepNumber>Step 6</StepNumber>
            <div>
              Set <strong>Count</strong>{' '}
              {platform !== ReviewsJetPlatform.WIX ? (
                <>
                  and <strong>Platform</strong>
                </>
              ) : (
                ''
              )}
            </div>
          </Step>
          <StepDesc>
            Add an attribute called <b>count</b> and set the value between 1 and
            4. <strong>count</strong> refers to the total number of reviews that
            are simultaneously displayed at any given moment.{' '}
            <strong>count</strong> is set to 3 by default.
          </StepDesc>
          {platform !== ReviewsJetPlatform.WIX && (
            <StepDesc>
              Add another attribute called <b>platform</b> and set the value to{' '}
              <b>others</b>.
            </StepDesc>
          )}

          <img
            src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-5.png?v=1"
            alt=""
            className="screenshot"
          />
        </StepWrapper>
      )}

      <StepWrapper>
        <Step>
          <StepNumber>Step {widgetType === 'marquee' ? 6 : 7}</StepNumber>
          <div>Center-align the widget.</div>
        </Step>
        <StepDesc>
          Click the <strong>Stretch</strong> icon, then deselect the{' '}
          <strong>Stretch to full width</strong> option.
        </StepDesc>

        <img src="/images/tutorials/wix/1.png" alt="" className="screenshot" />
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Final Step</StepNumber>
          <div>Publish your website</div>
        </Step>
        <StepDesc>
          Lastly, click the <strong>Publish</strong> button.
        </StepDesc>

        <img
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/carousel-widget/scrensnot-6.png?v=2"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <Divider />

      <p style={{ textAlign: 'center' }}>
        Congratulations! Your Carousel Widget has been successfully integrated
        and should now be visibly displayed on the installed page.
      </p>

      <Divider />
      <Troubleshooting />
      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Carousel Widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </Wrapper>
  );
};

export default WixCarouselWidget;
