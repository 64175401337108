import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../shared/hooks/useQuery';
import setSession from '../../../shared/utils/setSession';

export default function ShopifyAuth() {
  const query = useQuery();
  const navigate = useNavigate();

  const jwtAccessToken = query.get('t');
  const jwtRefreshToken = query.get('r');
  const setPassword = query.get('s');
  const startOnboarding = query.get('o');

  useEffect(() => {
    if (jwtAccessToken && jwtRefreshToken) {
      setSession({ jwtAccessToken, jwtRefreshToken });
    }

    if (setPassword === 'true') {
      navigate('/set-password?p=false');
    } else if (startOnboarding === 'true') {
      // navigate('/get-started');
      navigate('/manage-reviews');
    } else {
      navigate('/manage-reviews');
    }
  }, []);

  return null;
}
