import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';

const http = new Http();

export const getBusinessInfo = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('business-info/get', async (_, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/business-info`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
