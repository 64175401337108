import axios from 'axios';

import localStorage from '../shared/utils/localStorage';
import removeTrailingSlash from '../shared/utils/removeTrailingSlash';
import { memoizedRefreshToken } from './refreshToken';

axios.defaults.baseURL = `${removeTrailingSlash(
  process.env.REACT_APP_REVIEWS_JET_API!
)}`;

axios.interceptors.request.use(
  async (config) => {
    const session = localStorage.get('session');

    if (session?.jwtAccessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session?.jwtAccessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const accessToken = await memoizedRefreshToken();

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return axios(config);
    }

    return Promise.reject(error);
  }
);

export const axiosPrivate = axios;
