export default function OpenNewTab() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        strokeLinejoin="round"
        strokeWidth="32"
        d="M448 256 272 88v96C103.57 184 64 304.77 64 424c48.61-62.24 91.6-96 208-96v96z"
      ></path>
    </svg>
  );
}
