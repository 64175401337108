const colors = {
  white: '#ffffff',
  black: '#131720',
  transparent: 'transparent',

  'flexbyt-grey-000': '#D9D9D9',
  'flexbyt-grey-100': '#F5F5F5',
  'flexbyt-grey-200': '#E5E5E5',
  'flexbyt-grey-300': '#c0c0c0',

  'flexbyt-blue-400': '#0017E8',
  'flexbyt-blue-100': '#3B05D6',

  'flexbyt-violet-100': '#9747FF',

  'flexbyt-green-500': '#289017',
  'flexbyt-danger': '#c90808',
};

export type ColorsType = { [key in keyof typeof colors]: string };

export default colors;
