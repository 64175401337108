import styled, { css } from 'styled-components';

type Props = {
  screenshot: string;
};

const Wrapper = styled.div<{ $leftColor: string; $rightColor: string }>`
  padding: 15px 15px 0;
  border-radius: 10px;
  margin-bottom: 20px;

  ${({ $leftColor, $rightColor }) => css`
    background: linear-gradient(to bottom, ${$leftColor}, ${$rightColor});
  `}

  img {
    width: 100%;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export default function ScreenshotWrapper({ screenshot }: Props) {
  return (
    <Wrapper $leftColor="#007fff" $rightColor="#000">
      <img src={screenshot} alt="" />
    </Wrapper>
  );
}
