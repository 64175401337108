import FontPicker from 'font-picker-react';
import { FaEdit } from 'react-icons/fa';
import { Button, Divider, InputPicker, Toggle } from 'rsuite';
import styled from 'styled-components';
import ColorPicker from '../../../../../shared/components/colorPicker';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../shared/components/modal';
import useModal from '../../../../../shared/components/modal/useModal';
import DATE_FORMATS from '../../../../../shared/utils/dateFormats';
import { useAppSelector } from '../../../../../store';
import FormRow from '../../../components/form-row';
import { settingSelector } from '../../../redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../shared/hooks/useDispatchUpdateSiteWidgetSettings';
import Translation from './Translation';

const BlockLabel = styled.label`
  margin-bottom: 10px;
  display: block;
`;

const FontPickerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  #font-picker {
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    &.expanded {
      border-bottom: 0;

      -webkit-border-bottom-right-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-bottomright: 0px;
      -moz-border-radius-bottomleft: px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

      .font-list {
        display: block;
        margin-left: -1px;
        width: 200px;
      }
    }

    button.dropdown-button {
      background: transparent;
    }
    .font-list {
      display: none;
      box-shadow: none;
      background-color: #fff;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      li {
        color: rgb(50, 79, 190);
        button.active-font {
          background-color: rgb(235, 238, 249);
        }
        button:hover {
          background-color: rgb(235, 238, 249);
        }
      }
    }
  }
`;

const TextSettings = () => {
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();
  const { showModal, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <FormRow justifyContent="flex-start" gap="10px">
        <FlexContainer justifyContent="space-between" style={{ width: '100%' }}>
          <FlexContainer gap="10px" style={{ height: 37 }}>
            Use my website's font family.
            <Toggle
              size="md"
              checked={
                !!siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              onClick={() => {
                dispatchUpdateSiteWidgetSettings(
                  'useMyWebsiteFontFamily',
                  !siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
                );
              }}
            />
          </FlexContainer>

          {!siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily && (
            <FontPickerWrapper>
              <span>Use font family</span>
              <FontPicker
                apiKey={process.env.REACT_APP_GOOGLE_FONTS_KEY!}
                activeFontFamily={
                  !siteWidgetSettings?.brandingSettings.useMyWebsiteFontFamily
                    ? siteWidgetSettings?.brandingSettings.fontFamily
                    : ''
                }
                onChange={(nextFont) => {
                  dispatchUpdateSiteWidgetSettings(
                    'fontFamily',
                    nextFont.family
                  );
                }}
              />
            </FontPickerWrapper>
          )}
        </FlexContainer>
      </FormRow>

      <Divider />
      <FormRow>
        <div>
          <BlockLabel>Date format</BlockLabel>
          <InputPicker
            data={DATE_FORMATS}
            value={siteWidgetSettings?.brandingSettings.dateFormat}
            style={{ width: 224 }}
            cleanable={false}
            onChange={(format: string) => {
              dispatchUpdateSiteWidgetSettings('dateFormat', format);
            }}
          />
        </div>
        <div>
          <BlockLabel>Translation</BlockLabel>
          <Button appearance="ghost" color="violet" onClick={handleOpenModal}>
            <FlexContainer gap="5px">
              <FaEdit />
              Update widget content translation
            </FlexContainer>
          </Button>
        </div>
      </FormRow>
      <Divider />

      <FlexContainer justifyContent="space-between">
        <FormRow justifyContent="flex-start" gap="10px">
          Text color
          <ColorPicker
            color={siteWidgetSettings?.carouselSliderWidget.textColor!}
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings('textColor', color, 'slider');
            }}
          />
        </FormRow>
        <FormRow justifyContent="flex-end" gap="10px">
          <span>Reviewer name</span>
          <ColorPicker
            color={siteWidgetSettings?.carouselSliderWidget.linkColor!}
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings('linkColor', color, 'slider');
            }}
          />
        </FormRow>
      </FlexContainer>
      <FlexContainer justifyContent="space-between">
        <FormRow justifyContent="flex-start" gap="10px">
          <span>Date color</span>
          <ColorPicker
            color={siteWidgetSettings?.carouselSliderWidget.dateColor!}
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings('dateColor', color, 'slider');
            }}
          />
        </FormRow>
        <FormRow justifyContent="flex-end" gap="10px">
          <span>Button text color</span>
          <ColorPicker
            color={siteWidgetSettings?.carouselSliderWidget.buttonTextColor!}
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings(
                'buttonTextColor',
                color,
                'slider'
              );
            }}
          />
        </FormRow>
      </FlexContainer>
      <FlexContainer justifyContent="space-between" style={{ paddingLeft: 5 }}>
        <FormRow justifyContent="flex-start" gap="10px">
          <span>Star color</span>
          <ColorPicker
            color={siteWidgetSettings?.carouselSliderWidget.starColor!}
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings('starColor', color, 'slider');
            }}
          />
        </FormRow>
        <FormRow justifyContent="flex-end" gap="10px">
          <span>Button color</span>
          <ColorPicker
            color={
              siteWidgetSettings?.carouselSliderWidget.buttonBackgroundColor!
            }
            changeColor={(color: string) => {
              dispatchUpdateSiteWidgetSettings(
                'buttonBackgroundColor',
                color,
                'slider'
              );
            }}
          />
        </FormRow>
      </FlexContainer>

      <AppModal
        size="sm"
        onModalClose={handleCloseModal}
        shouldOpenModal={showModal}
        body={<Translation onClose={handleCloseModal} />}
      />
    </>
  );
};

export default TextSettings;
