import { useState } from 'react';
import { TransformedProduct } from '../../../../product/types';
import ReviewTaskSuccess from '../ReviewTaskSuccess';
import AddProductPage from './AddProductPage';
import Confirm from './Confirm';
import SetProduct from './SetProduct';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectAmazonProductStep {
  ADD_PAGE,
  SET_PRODUCT,
  CONFIRM,
  SUCCESS,
}

const ConnectAmazonProduct = ({ onComplete, onConnected }: Props) => {
  const [productPageUrl, setProductPageUrl] = useState('');
  const [product, setProduct] = useState<TransformedProduct | null>(null);
  const [step, setStep] = useState(ConnectAmazonProductStep.ADD_PAGE);

  return (
    <>
      {step === ConnectAmazonProductStep.ADD_PAGE && (
        <AddProductPage
          onNext={(url: string) => {
            setProductPageUrl(url);
            setStep(ConnectAmazonProductStep.SET_PRODUCT);
          }}
        />
      )}
      {step === ConnectAmazonProductStep.SET_PRODUCT && (
        <SetProduct
          onNext={(product: TransformedProduct) => {
            setProduct(product);
            setStep(ConnectAmazonProductStep.CONFIRM);
          }}
          onPrevious={() => setStep(ConnectAmazonProductStep.ADD_PAGE)}
        />
      )}
      {step === ConnectAmazonProductStep.CONFIRM && (
        <Confirm
          productPageUrl={productPageUrl}
          product={product}
          onConfirm={() => {
            setProduct(product);
            onConnected();
            setStep(ConnectAmazonProductStep.SUCCESS);
          }}
          onPrevious={() => setStep(ConnectAmazonProductStep.SET_PRODUCT)}
        />
      )}
      {step === ConnectAmazonProductStep.SUCCESS && (
        <ReviewTaskSuccess
          onConfirmed={() => {
            onComplete();
            setStep(ConnectAmazonProductStep.ADD_PAGE);
          }}
        />
      )}
    </>
  );
};

export default ConnectAmazonProduct;
