import { useToaster } from 'rsuite';
import AppNotification from '../components/notifications';

export const useNotification = () => {
  const toaster = useToaster();

  const showSuccessToast = (message: string | JSX.Element) => {
    toaster.push(<AppNotification type="success" message={message} />, {
      placement: 'topEnd',
      duration: 4000,
    });
  };

  const showErrorToast = (message: string | JSX.Element) => {
    toaster.push(<AppNotification type="error" message={message} />, {
      placement: 'topEnd',
      duration: 4000,
    });
  };

  const showWarningToast = (message: string | JSX.Element) => {
    toaster.push(<AppNotification type="warning" message={message} />, {
      placement: 'topEnd',
      duration: 4000,
    });
  };

  const showInfoToast = (message: string | JSX.Element) => {
    toaster.push(<AppNotification type="info" message={message} />, {
      placement: 'topEnd',
      duration: 4000,
    });
  };

  return {
    showSuccessToast,
    showErrorToast,
    showWarningToast,
    showInfoToast,
  };
};
