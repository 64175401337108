import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import {
  GoogleConnectFormData,
  ReviewQueryParams,
  ReviewStatus,
} from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { REVIEWS_PAGE_PER_PAGE } from '../utils/constants';
import { Reviewer, ReviewFormData } from './review.slice';

const http = new Http();

export const fetchSiteRatings = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('reviews/business/rating', async (_args: void, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/business/rating`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const fetchTotalReviewsPublishedAndPending = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('reviews/total', async (_args: void, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/total`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const fetchReviews = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'reviews/business/list',
  async (
    {
      pageNumber = 1,
      rating,
      search = '',
      status,
      limit = REVIEWS_PAGE_PER_PAGE,
      sort = 'desc',
      sortBy = '',
      platform,
    }: ReviewQueryParams,
    { rejectWithValue }
  ) => {
    let params = {
      sort,
      limit: String(limit),
    } as {
      limit: string;
      page: string;
      rating?: string | null;
      search?: string;
      status?: ReviewStatus;
      sortBy?: string;
      sort: string;
      platform?: string;
    };

    if (pageNumber) {
      params = { ...params, page: String(pageNumber) };
    }

    if (rating) {
      params = { ...params, rating: String(rating) };
    }

    if (search) {
      params = { ...params, search };
    }

    if (status) {
      params = {
        ...params,
        status,
      };
    }

    if (sortBy) {
      params = { ...params, sortBy };
    }

    if (platform) {
      params = { ...params, platform };
    }

    const queryString = new URLSearchParams(params as any);
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/reviews/list?${queryString}`;

    try {
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const publishReview = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/publish',
  async ({ reviewId }: { reviewId: string }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/publish`,
        { id: reviewId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const bulkPublishReviews = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/bulk/publish',
  async ({ reviewIds }: { reviewIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/publish/bulk`,
        { ids: reviewIds }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const unPublishReview = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/unpublish',
  async ({ reviewId }: { reviewId: string }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/unpublish`,
        { id: reviewId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const bulkUnPublishReviews = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'reviews/bulk/unpublish',
  async ({ reviewIds }: { reviewIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/unpublish/bulk`,
        { ids: reviewIds }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

type FacebookConnectPayload = {
  facebookUserId: string;
  facebookPageId: string;
  userAccessToken: string;
};

export const connectFacebookPage = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'facebook/connect',
  async (payload: FacebookConnectPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/facebook/page`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const disconnectFacebookPage = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('facebook/disconnect', async (pageId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/facebook/page/${pageId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const fetchFacebookReviews = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('facebook/reviews', async (facebookPageId, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/facebook/reviews/${facebookPageId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const createReview = createAsyncThunk<
  any,
  ReviewFormData & { avatar?: string },
  { rejectValue: string; state: RootState }
>('review/create', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/create`,
      body
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const bulkPulishImportedReviews = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>('review/bulk/import', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/bulk/import`,
      body
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const updateReviewerAvatar = createAsyncThunk<
  { success: boolean; reviewer: Reviewer },
  { avatar: string; reviewerId: string; productId?: string },
  { rejectValue: string; state: RootState }
>(
  'review/reviewer/update',
  async ({ avatar, productId, reviewerId }, { rejectWithValue }) => {
    try {
      const response = await http.update(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviewer/${reviewerId}`,
        {
          avatar,
          productId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const uploadReviewerAvatar = createAsyncThunk<
  { url: string; fileName: string },
  any,
  { rejectValue: string; state: RootState }
>('review/reviewer/upload-avatar', async (formData, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/remote-files/avatars`,
      formData
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

type YelpLocationQuery = {
  yelpUrl: string;
  businesAddress: string;
  start?: number | string;
};

export const getYelpLocations = createAsyncThunk<
  any,
  YelpLocationQuery,
  { rejectValue: string; state: RootState }
>(
  'locations/yelp',
  async ({ yelpUrl, businesAddress, start = 0 }, { rejectWithValue }) => {
    try {
      const params = {
        yelpUrl,
        businesAddress,
        start: String(start),
      } satisfies YelpLocationQuery;

      const queryString = new URLSearchParams(params);
      const url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/yelp/locations?${queryString}`;

      const response = await http.get(url);
      return response.data.response;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addYelpAccount = createAsyncThunk<
  any,
  { yelpDomain: string; placeId: string },
  { rejectValue: string; state: RootState }
>('yelp/add', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/yelp/account`,
      { ...body }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncYelpAccount = createAsyncThunk<
  any,
  { placeId: string },
  { rejectValue: string; state: RootState }
>('yelp/sync', async ({ placeId }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/yelp/sync/${placeId}/`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncGoogleAccount = createAsyncThunk<
  any,
  { placeId: string; dataId?: string },
  { rejectValue: string; state: RootState }
>('google/sync', async ({ placeId, dataId }, { rejectWithValue }) => {
  const data = { placeId, dataId };

  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/google/account/sync`,
      data
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const getGoogleBusinesses = createAsyncThunk<
  any,
  GoogleConnectFormData,
  { rejectValue: string; state: RootState }
>(
  'google/businesses',
  async (
    { gpsCoordinates, businessAddress, start = 0 },
    { rejectWithValue }
  ) => {
    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/google/businesses?businessAddress=${businessAddress}&gpsCoordinates=${gpsCoordinates}`;

      if (start) {
        url += `&start=${start}`;
      }

      const response = await http.get(url);
      return response.data.response;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addGoogleAccount = createAsyncThunk<
  any,
  { dataId: string; placeId: string; pageTitle: string },
  { rejectValue: string; state: RootState }
>('google/add', async ({ dataId, placeId, pageTitle }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/google/account`,
      { dataId, placeId, pageTitle }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const submitAReply = createAsyncThunk<
  any,
  { reviewId: string; replyContent: string; isUpdate?: boolean },
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/reply',
  async (
    {
      reviewId,
      replyContent,
      isUpdate = false,
    }: { reviewId: string; replyContent: string; isUpdate?: boolean },
    { rejectWithValue }
  ) => {
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/reply/${reviewId}`,
        { replyContent, isUpdate }
      );
      return replyContent;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const deleteReviewReply = createAsyncThunk<
  any,
  { reviewId: string },
  { rejectValue: string; state: RootState }
>(
  'reviews/site-reviews/delete',
  async ({ reviewId }: { reviewId: string }, { rejectWithValue }) => {
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/delete-reply/${reviewId}`
      );
      return;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const deleteReview = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('review/delete', async (reviewId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews/${reviewId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
