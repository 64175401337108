import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TbBorderCornerIos,
  TbBorderCornerPill,
  TbBorderCornerRounded,
  TbBorderCornerSquare,
} from 'react-icons/tb';

import { Button, Divider, InputNumber, Toggle } from 'rsuite';
import styled from 'styled-components';
import ColorPicker from '../../../../../shared/components/colorPicker';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../store';
import { BorderRadius } from '../../../../review/utils/constants';
import FormRow from '../../../components/form-row';
import { settingSelector } from '../../../redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../../shared/hooks/useDispatchUpdateSiteWidgetSettings';

const ButtonGroup = styled(FlexContainer)`
  width: 100%;
  button.rs-btn-block {
    width: 25%;
  }
`;

const BorderSettings = () => {
  const { t } = useTranslation('common');
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <>
      <FormRow justifyContent="flex-start" gap="10px">
        Show border
        <Toggle
          size="md"
          checked={!!siteWidgetSettings?.carouselSliderWidget.showBorder}
          checkedChildren={t('widget-settings.product.yes')}
          unCheckedChildren={t('widget-settings.product.no')}
          onClick={() => {
            dispatchUpdateSiteWidgetSettings(
              'showBorder',
              !siteWidgetSettings?.carouselSliderWidget.showBorder,
              'slider'
            );
          }}
        />
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.showBorder && (
        <>
          <Divider />
          <FlexContainer justifyContent="space-between">
            <FormRow justifyContent="flex-start" gap="10px">
              Border color
              <ColorPicker
                color={siteWidgetSettings?.carouselSliderWidget.borderColor}
                changeColor={(color: string) =>
                  dispatchUpdateSiteWidgetSettings(
                    'borderColor',
                    color,
                    'slider'
                  )
                }
              />
            </FormRow>
            <FormRow justifyContent="flex-end" gap="10px">
              <span>Border width</span>
              <InputNumber
                max={10}
                min={1}
                value={siteWidgetSettings.carouselSliderWidget.borderWidth}
                size="sm"
                style={{ width: 100 }}
                postfix="px"
                onChange={(
                  value: number | string | null,
                  event: SyntheticEvent<Element, Event>
                ) => {
                  dispatchUpdateSiteWidgetSettings(
                    'borderWidth',
                    value,
                    'slider'
                  );
                }}
              />
            </FormRow>
          </FlexContainer>
          <Divider />
        </>
      )}

      <>
        Border radius
        <FormRow justifyContent="space-between" gap="10px" marginTop="20px">
          <ButtonGroup gap="10px">
            <Button
              block
              onClick={() => {
                dispatchUpdateSiteWidgetSettings('borderRadius', '', 'slider');
              }}
              appearance={
                siteWidgetSettings?.carouselSliderWidget.borderRadius === ''
                  ? 'primary'
                  : 'default'
              }
            >
              <FlexContainer gap="5px">
                <TbBorderCornerSquare />
                None
              </FlexContainer>
            </Button>
            <Button
              block
              onClick={() => {
                dispatchUpdateSiteWidgetSettings(
                  'borderRadius',
                  BorderRadius.SMALL,
                  'slider'
                );
              }}
              appearance={
                siteWidgetSettings?.carouselSliderWidget.borderRadius ===
                BorderRadius.SMALL
                  ? 'primary'
                  : 'default'
              }
            >
              <FlexContainer gap="5px">
                <TbBorderCornerRounded />
                Small
              </FlexContainer>
            </Button>
            <Button
              block
              onClick={() => {
                dispatchUpdateSiteWidgetSettings(
                  'borderRadius',
                  BorderRadius.MEDIUM,
                  'slider'
                );
              }}
              appearance={
                siteWidgetSettings?.carouselSliderWidget.borderRadius ===
                BorderRadius.MEDIUM
                  ? 'primary'
                  : 'default'
              }
            >
              <FlexContainer gap="5px">
                <TbBorderCornerIos />
                Medium
              </FlexContainer>
            </Button>
            <Button
              block
              onClick={() => {
                dispatchUpdateSiteWidgetSettings(
                  'borderRadius',
                  BorderRadius.LARGE,
                  'slider'
                );
              }}
              appearance={
                siteWidgetSettings?.carouselSliderWidget.borderRadius ===
                BorderRadius.LARGE
                  ? 'primary'
                  : 'default'
              }
            >
              <FlexContainer gap="5px">
                <TbBorderCornerPill />
                Large
              </FlexContainer>
            </Button>
          </ButtonGroup>
        </FormRow>
      </>
    </>
  );
};

export default BorderSettings;
