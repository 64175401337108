import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import styled from 'styled-components';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useYelpLocationContext } from '../../../contexts/YelpLocationContext';
import { searchYelpBusiness } from '../../../redux/actions';
import { socialReviewsSelector } from '../../../redux/social-reviews.slice';
import { BusinessItem } from './BusinessItem';

const Container = styled.div`
  max-height: 450px;
`;
const LoadMoreWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  .load-more-btn {
    max-width: 300px;
  }
`;

const ListBusinesses = ({
  onGoBack,
  resetCloseButton,
  onLocationSelected,
}: {
  onGoBack: () => void;
  resetCloseButton?: boolean;
  onLocationSelected: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { showWarningToast } = useNotification();
  const { formValues, updateSelectedLocation } = useYelpLocationContext();
  const {
    get3rdPartyAccount: { data: integrations },
    yelpBusinesses,
    hasMoreYelpLocations,
    loadingGoogleBusinesses,
  } = useAppSelector(socialReviewsSelector);

  const isLoading = loadingGoogleBusinesses === Loading.PENDING;

  return (
    <>
      <ModalHeader
        onGoBackClick={onGoBack}
        title={t('reviews.import-source.yelp.select-business')}
        closeButton={false}
        resetCloseButton={resetCloseButton}
      />
      <ModalBody>
        <Container>
          {yelpBusinesses.map((business, i) => (
            <BusinessItem
              key={`loc-${i}`}
              thumbnail={business.image ?? ''}
              title={business.name}
              phone={business.phone}
              onSelect={() => {
                const alreadyAdded = (integrations?.yelp_reviews ?? []).some(
                  (i) => i.placeId === business.placeId
                );
                if (alreadyAdded) {
                  return showWarningToast('Business page already added.');
                }
                updateSelectedLocation(business);
                onLocationSelected();
              }}
            />
          ))}
          {hasMoreYelpLocations && (
            <LoadMoreWrapper>
              <Button
                block
                className="load-more-btn"
                disabled={isLoading}
                appearance="subtle"
                onClick={() => {
                  dispatch(
                    searchYelpBusiness({
                      ...formValues,
                      start: yelpBusinesses.length,
                    })
                  );
                }}
              >
                {isLoading ? t('shared.loading') : t('shared.load-more')}
              </Button>
            </LoadMoreWrapper>
          )}
        </Container>
      </ModalBody>
    </>
  );
};

export default ListBusinesses;
