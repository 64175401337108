import styled from 'styled-components';

const StyledSpan = styled.span`
  color: #575757;
  margin-right: 5px;
`;

const InputPickerLabelWrapper = styled.div`
  display: flex;
`;

const InputPickerLabel = ({
  value,
  label,
}: {
  value: string;
  label: string;
}) => {
  return (
    <InputPickerLabelWrapper>
      <StyledSpan>{label ? `${label} :` : ''} </StyledSpan>
      {value}
    </InputPickerLabelWrapper>
  );
};

export default InputPickerLabel;
