import { AxiosRequestHeaders } from 'axios';
import localStorage from '../shared/utils/localStorage';
import { axiosPrivate } from './axiosPrivate';

export const getHeaders = () => {
  const session = localStorage.get('session');

  if (session?.jwtAccessToken) {
    const { jwtAccessToken } = session;
    let headers = {
      pragma: 'no-cache',
      Authorization: `Bearer ${jwtAccessToken}`,
    } as AxiosRequestHeaders;

    return headers;
  }

  return null;
};

class Http {
  // headers: AxiosRequestHeaders;

  constructor() {
    // this.headers = getHeaders();
  }

  post = (url: string, data: any = {}, moreAxiosptions: any = {}) => {
    const axiosOptions = Object.assign(
      {},
      // { headers: this.headers },
      moreAxiosptions
    );
    return axiosPrivate.post(url, data, axiosOptions);
  };

  get = (url: string, query: object = {}, moreAxiosptions: any = {}) => {
    const axiosOptions = Object.assign(
      {},
      // { headers: this.headers },
      { params: query },
      moreAxiosptions
    );
    return axiosPrivate.get(url, axiosOptions);
  };

  update = (url: string, data: object = {}) => axiosPrivate.put(url, data);

  delete = (url: string) => axiosPrivate.delete(url);
}

export default Http;
