import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import { Input, InputGroup, Modal, Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import Preloader from '../../../../shared/components/Preloader';
import StyledText from '../../../../shared/components/styled-text';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useProducts } from '../../../review/hooks/useProducts';
import { PRODUCT_ITEMS_PER_PAGE } from '../../../review/utils/constants';
import { socialReviewsSelector } from '../../../socialReviews/redux/social-reviews.slice';
import { fetchMoreProducts } from '../../redux/action';
import { TransformedProduct } from '../../types';
import { ProductItem } from './product-item';

const NoProductFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;
const NoProductFoundText = styled(StyledText)`
  width: 273px;
  color: #747474;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
`;
const StyledInput = styled(Input)`
  background-color: '#F5F5F5';
  -webkit-border-top-right-radius: 30px;
  -webkit-border-bottom-right-radius: 30px;
  -moz-border-radius-topright: 30px;
  -moz-border-radius-bottomright: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
`;
const StyledModaBody = styled(Modal.Body)`
  height: 450px;
  overflow: auto;
  width: 100%;
`;
const StyledInputGroupWrapper = styled.div`
  padding: 0 20px;
`;
const StyledInputGroup = styled(InputGroup)`
  margin-bottom: 10px;
  margin-top: 30px;
  border-radius: 30px;
  height: 35px;
`;
const LoadMoreWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
`;
const GobackWrapper = styled.div`
  position: fixed;
  left: 15px;
  cursor: pointer;
  z-index: 999;
  top: 20px;
  padding: 2px;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  &:hover {
    color: #324fbe;
    background-color: #ebeef9;
  }
`;

const Footer = styled(FlexContainer)`
  width: 100%;
  border-top: 1px solid #e5e5ea;
  padding: 20px 20px 0 0;
`;
interface Props {
  title: string;
  showPreloader: boolean;
  onGoBack?: () => void;
  product?: TransformedProduct | null;
  onAssignProduct: (product: TransformedProduct) => void;
}
export const ProductList = ({ product, onGoBack, onAssignProduct }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const {
    get3rdPartyAccount: { data: integrations },
  } = useAppSelector(socialReviewsSelector);

  const {
    products,
    loadingProducts,
    loadingMoreProducts,
    hasNextProductPage,
    searchText,
    handleSearchText,
  } = useProducts({});
  const [selectedProduct, setSelectedProduct] =
    useState<TransformedProduct | null>(product ?? null);

  let content = null;

  if (loadingProducts === Loading.PENDING) {
    content = <Preloader />;
  } else if (!products?.products?.length) {
    content = (
      <NoProductFoundWrapper>
        <NoProductFoundText>
          {searchText
            ? t(
                'reviews.assign-to-product-modal.no-product-found-after-search',
                { searchText }
              )
            : t('reviews.assign-to-product-modal.no-product-found')}
        </NoProductFoundText>
      </NoProductFoundWrapper>
    );
  } else {
    content = (
      <>
        {products?.products.map((product) => {
          const { id, name, price, imageUrl } = product;
          return (
            <ProductItem
              key={id}
              name={name}
              price={price}
              imageUrl={imageUrl}
              productId={id}
              onSelect={() => {
                setSelectedProduct(product);
              }}
              disabled={(integrations?.amazon_reviews ?? []).some(
                (i) => i.productId === product.id
              )}
              selectedProduct={selectedProduct}
            />
          );
        })}
        {hasNextProductPage && (
          <LoadMoreWrapper>
            <RsButton
              disabled={loadingMoreProducts === Loading.PENDING}
              appearance={
                loadingMoreProducts === Loading.PENDING ? 'default' : 'primary'
              }
              onClick={() => {
                dispatch(
                  fetchMoreProducts({
                    offset: +products?.metadata.offset + PRODUCT_ITEMS_PER_PAGE,
                  })
                );
              }}
            >
              {loadingMoreProducts === Loading.PENDING
                ? t('reviews.assign-to-product-modal.loading')
                : t('reviews.assign-to-product-modal.load-more')}
            </RsButton>
          </LoadMoreWrapper>
        )}
      </>
    );
  }

  return (
    <>
      <div>
        <StyledInputGroupWrapper>
          <StyledInputGroup inside>
            <InputGroup.Addon>
              <IoSearchOutline size={18} />
            </InputGroup.Addon>
            <StyledInput
              placeholder={t('reviews.assign-to-product-modal.placeholder')}
              onChange={handleSearchText}
            />
          </StyledInputGroup>
        </StyledInputGroupWrapper>
      </div>
      <FlexContainer stack alignItems="flex-start" justifyContent="flex-start">
        <StyledModaBody>{content}</StyledModaBody>
        <Footer justifyContent="flex-end" gap="10px">
          {onGoBack && (
            <Button variant="secondary" size="small" rounded onClick={onGoBack}>
              Cancel
            </Button>
          )}

          <Button
            variant="primary"
            size="small"
            rounded
            disabled={!selectedProduct}
            onClick={() => {
              if (selectedProduct) {
                onAssignProduct(selectedProduct);
              }
            }}
          >
            Assign Product
          </Button>
        </Footer>
      </FlexContainer>
    </>
  );
};
