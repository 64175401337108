import { useState } from 'react';
import ReviewTaskSuccess from '../ReviewTaskSuccess';
import AddProductPage from './AddProductPage';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectTripadvisorStep {
  ADD_PAGE,
  SUCCESS,
}

const ConnectTripadvisor = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectTripadvisorStep.ADD_PAGE);

  return (
    <>
      {step === ConnectTripadvisorStep.ADD_PAGE && (
        <AddProductPage
          onConnected={() => {
            onConnected();
            setStep(ConnectTripadvisorStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectTripadvisorStep.SUCCESS && (
        <ReviewTaskSuccess
          onConfirmed={() => {
            onComplete();
            setStep(ConnectTripadvisorStep.ADD_PAGE);
          }}
        />
      )}
    </>
  );
};

export default ConnectTripadvisor;
