export default {
  get(key: string) {
    const strValue = localStorage.getItem(key);
    let value;
    try {
      if (strValue) {
        value = JSON.parse(strValue);
      }
    } catch (e) {
      value = strValue;
    }

    return value;
  },
  set(key: string, value: unknown) {
    localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  },
  remove(key: string) {
    localStorage.removeItem(key);
  },
};
