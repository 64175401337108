import { ReactNode, createContext, useContext, useState } from 'react';
import {
  AppleConnectFormData,
  IAppleStore,
} from '../../../shared/types/review.types';

export type AppleConnectContextType = {
  formValues: AppleConnectFormData;
  selectedAppleStore: IAppleStore | null;
  updateFormValues: (values: AppleConnectFormData) => void;
  updateSelectedBusiness: (values: IAppleStore) => void;
};

export const AppleConnectContext =
  createContext<AppleConnectContextType | null>(null);

export const AppleConnectProvider = ({ children }: { children: ReactNode }) => {
  const [formValues, setFormValues] = useState<AppleConnectFormData>({
    search: '',
    country: 'us',
    lang: 'en-us',
  });
  const [selectedAppleStore, setSelectedAppleStore] =
    useState<IAppleStore | null>(null);

  const updateFormValues = (values: AppleConnectFormData) => {
    setFormValues(values);
  };
  const updateSelectedBusiness = (values: IAppleStore) => {
    setSelectedAppleStore(values);
  };

  return (
    <AppleConnectContext.Provider
      value={{
        formValues,
        selectedAppleStore,
        updateFormValues,
        updateSelectedBusiness,
      }}
    >
      {children}
    </AppleConnectContext.Provider>
  );
};

export const useAppleConnectContext = () => {
  const context = useContext<AppleConnectContextType | null>(
    AppleConnectContext
  );
  if (!context) {
    throw new Error(
      'useAppleConnectContext must be used within a AppleConnectProvider'
    );
  }
  return context;
};
