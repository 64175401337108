import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { CreateIncentivePayload } from '../../review/types';

const http = new Http();

export const fetchIncentives = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('incentive/all', async (_, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/incentive/list`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const createIncentive = createAsyncThunk<
  any,
  CreateIncentivePayload,
  { rejectValue: string; state: RootState }
>('incentive/create', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/incentive/create`,
      body
    );
    return response.data.incentive;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const deleteIncentive = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('incentive/delete', async (incentiveId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/incentive/${incentiveId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
