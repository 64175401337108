import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import { Title } from './Title';
import FlexContainer from './layouts/flex-container';
import StyledText from './styled-text';

const ModalTitle = styled(Title)`
  text-align: center;
  margin-bottom: 20px;
`;
const ModalSubTitle = styled(ModalTitle)`
  font-size: 19px;
  text-align: center;
  margin-bottom: 10px;
`;
const ModalRequestForReview = styled(FlexContainer)`
  text-align: center;
  margin: auto;
  max-width: 80%;
`;

const WelcomeWRapper = styled(FlexContainer)`
  margin: auto;
  max-width: 90%;
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  cursor: pointer;
`;

const FounderImg = styled.img`
  width: 70px;
  border-radius: 100%;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const CloseBtn = styled(Button)`
  color: #000;
  font-weight: bold;
`;

const ThankYouModal = ({
  source,
  closeModal,
}: {
  source?: string;
  closeModal: () => void;
}) => {
  return (
    <>
      <ModalTitle>
        Thanks a ton{source === 'sumoling' ? ', Sumoling!' : '!'}
      </ModalTitle>
      <WelcomeWRapper
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="20px"
      >
        {/* <FlexContainer stack gap="10px">
        <FounderImg src={FounderImage} alt="" />
        <span>Founder</span>
      </FlexContainer> */}
        <StyledText>
          Welcome aboard ReviewsJet! Your satisfaction means everything to us.
          Feel free to contact us anytime at <b>hello@reviewsjet.com</b>.
        </StyledText>
      </WelcomeWRapper>
      <Divider />
      <ModalSubTitle>Please, share your thoughts!</ModalSubTitle>
      <ModalRequestForReview stack gap="20px">
        <StyledText>
          Your feedback drives our momentum! We value your thoughts—please
          share.
        </StyledText>
        <CloseBtn block appearance="default" size="lg" onClick={closeModal}>
          Close
        </CloseBtn>
      </ModalRequestForReview>
    </>
  );
};

export default ThankYouModal;
