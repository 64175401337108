import { ReactElement } from 'react';
import { GiFlame } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';

type Props = {
  title: string;
  description: string;
  url: string;
  demoUrl: string;
  className?: string;
  icon: ReactElement;
  isHot?: boolean;
};

const SingleWidget = styled(FlexContainer)<{ $isHot?: boolean }>`
  position: relative;
  /* border: 1px solid #dadada; */
  padding: 20px 0;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  height: 110px;
  cursor: pointer;
  ${({ $isHot }) =>
    $isHot &&
    css`
      border: 1px solid #ffc2308a;
    `}

  .widget-title {
    font-size: 15px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0px;
    line-height: 20px;
  }

  p {
    line-height: 25px;
    color: #636363;
  }

  &:hover {
    box-shadow: 0px 10px 15px -3px #cdcccf;
    border-color: #dddddd;
  }
`;

const IconWrapper = styled(FlexContainer)`
  height: 60px;
  width: 60px;
  /* border: 1px solid #edecec; */
  border-radius: 100%;
  padding: 5px;
  background-color: #f2ecfc;
  color: #531ba8;
`;

const Footer = styled(FlexContainer)`
  border-top: 1px solid #dadada;
  width: 100%;
  padding-top: 20px;
`;

const StyledGiFlame = styled(GiFlame)`
  position: absolute;
  top: 5px;
  right: 10px;
`;

export default function WidgetCard({
  demoUrl,
  description,
  url,
  title,
  icon,
  isHot,
}: Props) {
  const navigate = useNavigate();

  return (
    <SingleWidget
      stack
      gap="10px"
      justifyContent="center"
      alignItems="flex-start"
      role="presentation"
      onClick={() => navigate(url)}
      $isHot={isHot}
    >
      {isHot && (
        <StyledGiFlame color="#ff7600" size={25} title="Trending widget" />
      )}

      <FlexContainer
        gap="20px"
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {icon}
        <div>
          <h3 className="widget-title">{title}</h3>
          <p>{description}</p>
        </div>
      </FlexContainer>

      {/* <Footer gap="5px" justifyContent="flex-end">
        <Button variant="secondary" size="small" onClick={() => navigate(url)}>
          <FlexContainer gap="5px">Select widget</FlexContainer>
        </Button>

        <RsButton
          size="sm"
          appearance="link"
          onClick={() => window.open(demoUrl, '_blank')}
        >
          <FlexContainer gap="5px">
            View demo
            <OpenNewTab />
          </FlexContainer>
        </RsButton>
      </Footer> */}
    </SingleWidget>
  );
}
