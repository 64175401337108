import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useRef, useState } from 'react';
import { MdCelebration } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Form, Schema, Tag } from 'rsuite';
import styled from 'styled-components';
import {
  AuthForm,
  AuthFormGroup,
  AuthLabel,
  AuthTextField,
} from '../../features/external/components';
import { redeemCode } from '../../features/userData/redux/action';
import FlexContainer from '../../shared/components/layouts/flex-container';
import { useNotification } from '../../shared/hooks/useNotification';
import { Loading, SubscriptionPlan } from '../../shared/types/review.types';
import getCurrentPlan from '../../shared/utils/getCurrentPlan';
import { useAppDispatch, useAppSelector } from '../../store';

const successMessages: { title: string; message: string }[] = [
  {
    title: 'Whoa! You just upgraded! 🚀',
    message:
      'Your account is now officially cooler than a penguin in sunglasses.',
  },
  {
    title: 'Upgrade Achieved!',
    message:
      'Your account has leveled up—get ready for a dance-off with virtual confetti!',
  },
  {
    title: 'Upgrade Success! 🎉',
    message:
      'Your account is now equipped with secret agent features. Your messages might self-destruct (not really).',
  },
  {
    title: 'Upgrade Unlocked! 🦄',
    message:
      'Brace yourself! Your account is now a unicorn. Magical updates await!',
  },
  {
    title: 'Upgrade Celebration! 🎉',
    message:
      'Your account has more sparkle than a disco ball at a space party.',
  },
];

const Title = styled.h4`
  color: #000;
  font-weight: bold;
  margin: 0%;
  font-size: 18px;
  /* margin-bottom: 20px; */
`;
const CenteredFlexContainer = styled(FlexContainer)`
  text-align: center;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  redemptionCode: StringType().isRequired('Please enter your redemption code'),
});

const RedeemCode = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState(false);
  const formRef = useRef<{ check: () => void }>(null);
  const [formValue, setFormValue] = useState({
    redemptionCode: '',
  });
  const [newPlan, setNewPlan] = useState<SubscriptionPlan | null>(null);
  const {
    redeemCode: { loading },
  } = useAppSelector((state) => state.userData);
  const { showErrorToast, showSuccessToast } = useNotification();
  const { account } = useAppSelector((state) => state.userData);
  const { selectedSite } = account ?? {};
  const { plan = SubscriptionPlan.FREE } = selectedSite || {};

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!formRef.current?.check()) {
        return;
      }

      dispatch(redeemCode(formValue.redemptionCode))
        .then(unwrapResult)
        .then(({ error, errorMessage, newPlan }) => {
          if (error) {
            showErrorToast(errorMessage);
          } else {
            setSuccess(true);
            setNewPlan(newPlan);
            showSuccessToast(
              'The redemption code has successfully been applied to your account.'
            );
          }
          // dispatch(getSite({ showPreloader: true }))
          //   .then(unwrapResult)
          //   .then(() => {
          //     showSuccessToast('New workspace successfully added.');
          //     navigate(`/manage-reviews`);
          //   })
          //   .catch(() => {
          //     showErrorToast('Something went wrong. Please try it again.');
          //   });
        })
        .catch((e) => {
          showErrorToast('Something went wrong. Please try it again.');
        });
    },
    [formValue]
  );

  return (
    <>
      {success ? (
        <>
          <CenteredFlexContainer stack>
            <Title>
              {
                successMessages[
                  Math.floor(Math.random() * successMessages.length)
                ].title
              }
            </Title>
            <p>
              {
                successMessages[
                  Math.floor(Math.random() * successMessages.length)
                ].message
              }
            </p>
          </CenteredFlexContainer>
          <Divider />
          <CenteredFlexContainer stack>
            {newPlan && (
              <p>
                Your new plan:{' '}
                <Tag color="violet">{getCurrentPlan(newPlan)}</Tag>{' '}
              </p>
            )}
          </CenteredFlexContainer>
          <Divider />
          <FlexContainer>
            <Button
              block
              onClick={() => {
                closeModal();
                window.location.reload();
              }}
            >
              Close
            </Button>
          </FlexContainer>
        </>
      ) : (
        <>
          <Title>Claim Your Rewards</Title>

          <p style={{ marginBottom: 20 }}>
            Current Plan: <Tag color="green">{getCurrentPlan(plan)}</Tag>
          </p>
          <Divider />

          <AuthForm
            fluid
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            model={model}
          >
            <AuthFormGroup controlId="fullName">
              <AuthLabel>Recemption code</AuthLabel>
              <AuthTextField
                name="redemptionCode"
                type="text"
                autoComplete="off"
                placeholder="h7Uj92Jj98YTkidps"
              />
            </AuthFormGroup>

            <Form.Group>
              <FlexContainer gap="15px" justifyContent="flex-end">
                {loading !== Loading.PENDING && (
                  <Button appearance="subtle" onClick={closeModal}>
                    Cancel
                  </Button>
                )}
                <Button
                  appearance={
                    loading === Loading.PENDING ? 'default' : 'primary'
                  }
                  type="submit"
                  color="violet"
                  onClick={handleSubmit}
                  disabled={false}
                >
                  {loading === Loading.PENDING ? (
                    'Please wait...'
                  ) : (
                    <FlexContainer justifyContent="center" gap="5px">
                      <MdCelebration />
                      Redeem
                    </FlexContainer>
                  )}
                </Button>
              </FlexContainer>
            </Form.Group>
          </AuthForm>
        </>
      )}
    </>
  );
};

export default RedeemCode;
