import { forwardRef } from 'react';
import { Form } from 'rsuite';
import styled from 'styled-components';

export const AuthTextField = forwardRef((props: any, ref: any) => {
  const { name, label, accepter, isPassword, ...rest } = props;
  return (
    <Form.Group controlId={name} ref={ref}>
      <Form.Control name={name} accepter={accepter} {...rest} />
      {isPassword && (
        <Form.HelpText>
          Use 5 or more characters with a mix of letters and numbers
        </Form.HelpText>
      )}
    </Form.Group>
  );
});
export const AuthTitle = styled.h2<{
  marginBottom?: string;
  fontSize?: string;
}>`
  font-family: 'Ubuntu', sans-serif;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '30px'};
  color: #000;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize ?? '25px'};
  /* text-align: center; */
`;
export const AuthForm = styled(Form)`
  /* max-width: 410px; */
  width: 100%;
`;
export const AuthLabel = styled(Form.ControlLabel)`
  font-weight: bold;
`;
export const AuthFormGroup = styled(Form.Group)<{ $marginBotton?: string }>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ $marginBotton }) => $marginBotton ?? 'auto'};
`;
export const AuthIconWrapper = styled.span<{ isLoginPage?: boolean }>`
  /* background-color: #f2f2f2; */
  position: absolute;
  right: 1px;
  bottom: ${({ isLoginPage }) => (isLoginPage ? '1px' : '25px')};
  z-index: 9;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &:hover {
    /* background-color: #e6e3e3; */
  }
`;
