import dayjs from 'dayjs';

const DATE_FORMATS = [
  'MM/DD/YYYY',
  // 'MM-DD-YYYY',
  'DD/MM/YYYY',
  // 'DD-MM-YYYY',
  'DD MMM, YYYY',
  'MMM DD, YYYY',
  'DD MMMM, YYYY',
  'MMMM DD, YYYY',
  'MMM YYYY',
  'MMMM YYYY',
].map((item) => {
  let label = dayjs(new Date()).format(item);

  if (['MM/DD/YYYY', 'MM-DD-YYYY'].includes(item)) {
    label = `${dayjs(new Date()).format(item)} (US format)`;
  } else if (['DD/MM/YYYY', 'DD-MM-YYYY'].includes(item)) {
    label = `${dayjs(new Date()).format(item)} (British format)`;
  }

  return {
    label,
    value: item,
  };
});

export default DATE_FORMATS;
