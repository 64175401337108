import { useEffect, useState } from 'react';
import { PiChatDotsBold } from 'react-icons/pi';
import styled from 'styled-components';
import { reviewsUsageSelector } from '../../../features/manage-reviews/redux/reviews.slice';
import { SHOW_REVIEW_US_REVIEWS_LIMIT } from '../../../features/review/utils/constants';
import { useAppSelector } from '../../../store';
import useReviewUs from '../../hooks/useReviewUs';
import { Loading } from '../../types/review.types';

const Wrapper = styled.div`
  top: 35%;
  background-color: #d23201;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  gap: 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 1;

  svg {
    /* transform: rotate(90deg); */
  }

  &.default {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
      font-size: 14px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      /* letter-spacing: 0; */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      -webkit-tap-highlight-color: transparent;

      @media (max-width: 644px) {
        /* font-size: 11px; */
      }
    }
    position: fixed;
    width: auto;
    padding: 15px 10px;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    z-index: 9999999;
    line-height: normal;

    @media (max-width: 644px) {
      padding: 8px 15px;
      position: fixed;
      width: auto;
      cursor: pointer;
      align-items: center;
      white-space: nowrap;
      z-index: 9999999;
      line-height: normal;
    }

    &.floating {
      bottom: 20px;
      border-radius: 5px;
    }

    &.sticky {
      bottom: -1px;
      -webkit-border-top-left-radius: 6px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &.bottom_right {
      right: 20px;
      @media (max-width: 644px) {
        padding: 12px 15px;
      }
    }

    &.bottom_left {
      left: 20px;
      @media (max-width: 644px) {
        padding: 12px 15px;
      }
    }

    &.right {
      right: -1px;

      overflow-wrap: normal !important;
      word-break: normal !important;
      word-wrap: normal !important;
      white-space: nowrap !important;
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
      cursor: pointer;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-lr;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      color: #ffffff !important;
      z-index: 1;

      &:hover {
        right: 0;
      }
    }

    &.left {
      left: -1px;
      transform-origin: 0 50%;
      transform: rotate(-90deg) translate(-50%, 50%);
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      &:hover {
        left: 0;
      }
    }
    &.bottom {
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 5px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
`;

export default function Feedback() {
  const [ask, setAsk] = useState(false);
  const reviewUs = useReviewUs();
  const { published, loading: loadingReviewsUsage } =
    useAppSelector(reviewsUsageSelector);

  useEffect(() => {
    if (
      published >= SHOW_REVIEW_US_REVIEWS_LIMIT &&
      loadingReviewsUsage === Loading.SUCCESS
    ) {
      setAsk(true);
    }
  }, [published, loadingReviewsUsage]);

  if (!ask) {
    return null;
  }

  return (
    <Wrapper className="default right" role="presentation" onClick={reviewUs}>
      <PiChatDotsBold size={16} />
      <span>Rate your experiencesss</span>
    </Wrapper>
  );
}
