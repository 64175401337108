import { TiTick } from 'react-icons/ti';
import { Button } from 'rsuite';
import styled from 'styled-components';
import { useAppSelector } from '../../../../store';
import { accountSelector } from '../../../userData/redux/userData.slice';

const Container = styled.div`
  padding-top: 0;
`;
const MainWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    line-height: 20px;
    text-align: center;
  }
  p.sub-title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
  }
`;
const Title = styled.h3`
  color: #000;
  font-weight: bold;
  font-size: 25px;
  margin: 0 auto 15px;
  text-align: center;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #58b15b;
`;
const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
`;

interface Props {
  onConfirmed: () => void;
}

const ReviewTaskSuccess = ({ onConfirmed }: Props) => {
  const { account } = useAppSelector(accountSelector);
  const { ownerEmail } = account ?? {};

  return (
    <>
      <Container>
        <MainWrapper>
          <Title>
            <TiTick color="#fff" size={30} />
          </Title>
          <p className="sub-title">Your reviews are being imported!</p>
          <p>
            It'll just take 3 to 5 minutes. We'll send an email to {ownerEmail}{' '}
            once they're in.
          </p>
        </MainWrapper>

        <ConfirmButton block appearance="default" onClick={onConfirmed}>
          Close
        </ConfirmButton>
      </Container>
    </>
  );
};

export default ReviewTaskSuccess;
