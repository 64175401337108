export type ConvertReviewPayload = {
  reviewId: string;
  product: any;
  reviewImages?: string[];
};

export type ProductResponse = {
  products: any[];
  metadata: {
    items: number;
    offset: number;
  };
  totalResults: number;
};

export type ProductPicture = {
  thumbnail: string;
  image: string;
};

export enum ReviewsJetPlatform {
  WORDPRESS = 'wordpress',
  WIX = 'wix',
  WEBFLOW = 'webflow',
  SQUARESPACE = 'squarespace',
  GO_DADDY = 'godaddy',
  PIXPA = 'pixpa',
  SHOPIFY = 'shopify',
  OTHERS = 'others',
  BIGCOMMERCE = 'bigcommerce',
}

export type Product = {
  productId: string;
  instanceId: string;
  name: string;
  slug: string;
  price: string;
  media: ProductPicture[];
  platform: ReviewsJetPlatform;
};

export type TransformedProduct = {
  id: string;
  name: string;
  price: string;
  imageUrl: string;
  slug: string;
};
