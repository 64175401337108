import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { getTestimonialTemplate } from './action';

type TestimonialsState = {
  loadingTestimonialTemplates: Loading;
};

export const initialTemplates = {
  Default: {
    subject: 'Share a testimonial with us {{ first_name }}!',
    message: `<p>Hey {{ first_name }} 👋</p>
       <br/>
       <p>Got a quick minute? We'd love to hear your thoughts! Your feedback helps us make sure we're delivering the best experience possible.</p>
       <p>Click the link below to share a testimonial with us!</p>
       <br/>
       <p>Thanks,<br/>{{ owner_name }}</p>`,
  },
  Short: {
    subject: 'Quick question',
    message: `<p>Hey {{ first_name }} 👋</p>
       <br/>
       <p>Got a quick minute? We'd love to hear your thoughts! Your feedback helps us make sure we're delivering the best experience possible.</p>
       <br/>
       <p>Thanks,<br/>{{ owner_name }}</p>`,
  },
  Friendly: {
    subject: 'Hey {{ first_name }}, can you share a testimonial with us?',
    message: `<p>Hey {{ first_name }} 👋</p>
       <br/>
       <p>Got a quick minute? We'd love to hear your thoughts! Your feedback helps us make sure we're delivering the best experience possible.</p>
       <p>Click the link below to share a testimonial with us!</p>
       <br/>
       <p>Thanks,<br/>{{ owner_name }}</p>`,
  },
  Formal: {
    subject: 'We would love to hear from you!',
    message: `<p>Dear {{ first_name }},</p>
       <br/>
       <p>We would love to hear your thoughts! Your feedback helps us make sure we're delivering the best experience possible.</p>
       <p>Click the link below to share a testimonial with us!</p>
       <br/>
       <p>Thanks,<br/>{{ owner_name }}</p>`,
  },
  Product: {
    subject: 'We’d love your feedback',
    message: `<p>Hi {{ first_name }},</p>
       <br/>
       <p>We hope you’re loving it. Your feedback means the world to us, and we’d be thrilled if you could take a moment to share your experience.</p>
       <p>Click the link below to share a testimonial with us!</p>
       <br/>
       <p>Thanks,<br/>{{ owner_name }}</p>`,
  },
};

export type TemplateType = keyof typeof initialTemplates;

const initialState: TestimonialsState = {
  loadingTestimonialTemplates: Loading.INITIAL,
};

const testimonial = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTestimonialTemplate.pending, (state) => {
      state.loadingTestimonialTemplates = Loading.PENDING;
    });
    builder.addCase(getTestimonialTemplate.fulfilled, (state, { payload }) => {
      state.loadingTestimonialTemplates = Loading.SUCCESS;
    });
    builder.addCase(getTestimonialTemplate.rejected, (state) => {
      state.loadingTestimonialTemplates = Loading.ERROR;
    });
  },
});

export const testimonialSelector = (state: RootState) => state.testimonial;

export default testimonial.reducer;
