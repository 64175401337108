import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import {
  EmailQueryParams,
  EmailStatus,
} from '../../../shared/types/email.types';
import { Loading } from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { EMAILS_PAGE_PER_PAGE } from '../../review/utils/constants';

/* eslint no-console: "warn" */

const http = new Http();

export const fetchEmails = createAsyncThunk<
  any,
  {
    payload: EmailQueryParams;
    showPreloader?: boolean;
  },
  { rejectValue: string; state: RootState }
>(
  'emails/get',
  async (
    {
      payload: {
        pageNumber = 1,
        search = '',
        status,
        limit = EMAILS_PAGE_PER_PAGE,
        sort = 'desc',
        startDate,
        endDate,
      },
    },
    { rejectWithValue }
  ) => {
    let params = {
      sort,
      pageNumber,
      limit: String(limit),
    } as {
      limit?: string;
      page?: string;
      rating?: string | null;
      search?: string;
      status?: EmailStatus;
      sortBy?: string;
      sort: string;
      platform?: string;
      missingReplies?: string;
      startDate?: string;
      endDate?: string;
    };

    if (search) {
      params = { ...params, search };
    }

    if (startDate) {
      params = { ...params, startDate };
    }

    if (endDate) {
      params = { ...params, endDate };
    }

    if (status) {
      params = {
        ...params,
        status,
      };
    }

    const queryString = new URLSearchParams(params as any);
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/emails/list?${queryString}`;

    try {
      const response = await http.get(url);

      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  },
  {
    condition(_, api): boolean {
      const {
        emails: {
          emails: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const getStats = createAsyncThunk<
  any,
  {
    startDate: string;
    endDate: string;
  },
  { rejectValue: string; state: RootState }
>(
  'emails/stats',
  async ({ startDate, endDate }, { rejectWithValue }) => {
    let params = {
      startDate,
      endDate,
    } as {
      startDate?: string;
      endDate?: string;
    };

    const queryString = new URLSearchParams(params as any);
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/emails/stats?${queryString}`;

    try {
      const response = await http.get(url);

      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  },
  {
    condition(_, api): boolean {
      const {
        emails: {
          stats: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);
