import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'rsuite';
import styled from 'styled-components';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { verifyAccount } from '../../userData/redux/action';
import { AuthTitle } from '../components';
import ErrorPage from '../components/error-page';

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 50px;
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const ConfirmAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    verifyAccount: { loading },
  } = useAppSelector((state) => state.userData);

  useEffect(() => {
    if (token) {
      dispatch(verifyAccount(token));
    }
  }, [token]);

  if (loading === Loading.PENDING) {
    return <p>Please wait....</p>;
  }

  if (loading === Loading.ERROR) {
    return (
      <Wrapper style={{ maxWidth: 650 }}>
        <ReviewsJetCrownLogo />
        <ErrorPage
          onRetryClick={() => {
            if (token) {
              dispatch(verifyAccount(token));
            }
          }}
          showFooter={false}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <a
        href="https://www.reviewsjet.com"
        style={{ marginBottom: 30, display: 'block' }}
      >
        <ReviewsJetCrownLogo />
      </a>
      <AuthTitle style={{ lineHeight: '35px' }}>
        Your account has been successfully confirmed.
      </AuthTitle>

      <p>Click the button below to login.</p>

      <StyledButton appearance="primary" onClick={() => navigate('/login')}>
        Login into your account
      </StyledButton>
    </Wrapper>
  );
};

export default ConfirmAccount;
