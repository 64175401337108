import { useCallback, useState } from 'react';
import { ReviewsJetPlatform } from '../../features/product/types';
import { axiosPrivate } from '../../services/axiosPrivate';
import { useAppSelector } from '../../store';
import { Loading } from '../types/review.types';
import removeTrailingSlash from '../utils/removeTrailingSlash';
import { useNotification } from './useNotification';

/* eslint no-console: "warn" */

const useUpgradeLink = () => {
  const { account } = useAppSelector((state) => state.userData);
  const { showErrorToast } = useNotification();
  const { selectedSite } = account ?? {};
  const { instanceId, platform } = selectedSite ?? {};
  const { upgradePlanId, billingCycle } = useAppSelector((state) => state.app);
  const [generatingUpgradeLink, setGeneratingUpgradeLink] = useState(
    Loading.INITIAL
  );

  const handleGenerateWixUpgradeLink = useCallback(async () => {
    try {
      setGeneratingUpgradeLink(Loading.PENDING);
      const {
        data: { checkoutUrl },
      } = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/wix/upgrade-link`,
        { instanceId, productId: upgradePlanId, billingCycle }
      );

      setGeneratingUpgradeLink(Loading.SUCCESS);
      window.open(checkoutUrl, '_blank');
    } catch (error: any) {
      setGeneratingUpgradeLink(Loading.ERROR);

      let errorMessage = '';

      if (error.response) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = error.message;
      }

      showErrorToast(errorMessage);
    }
  }, [upgradePlanId, billingCycle]);

  const handleGenerateStripeUpgradeLink = useCallback(async () => {
    try {
      setGeneratingUpgradeLink(Loading.PENDING);
      const {
        data: { confirmationUrl },
      } = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/account/initiate-payment`,
        { plan: upgradePlanId, cycle: billingCycle }
      );

      setGeneratingUpgradeLink(Loading.SUCCESS);
      window.open(confirmationUrl, '_blank');
    } catch (error: any) {
      setGeneratingUpgradeLink(Loading.ERROR);

      let errorMessage = '';

      if (error.response) {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = error.message;
      }

      showErrorToast(errorMessage);
    }
  }, [upgradePlanId, billingCycle]);

  const handleUpgrade = useCallback(async () => {
    if (!platform) {
      return;
    }

    if (platform === ReviewsJetPlatform.WIX) {
      await handleGenerateWixUpgradeLink();
    } else {
      await handleGenerateStripeUpgradeLink();
    }
  }, [platform, upgradePlanId, billingCycle]);

  return {
    handleUpgrade,
    generatingUpgradeLink,
    handleGenerateWixUpgradeLink,
    handleGenerateStripeUpgradeLink,
  };
};

export default useUpgradeLink;
