import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import { Loading } from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';

const http = new Http();

export const fetchPlans = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'plans/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/account/plans`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  },
  {
    condition(_, api): boolean {
      const {
        plans: { loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);
