import { useState } from 'react';
import ReviewTaskSuccess from '../ReviewTaskSuccess';
import AddProductPage from './AddProductPage';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectProducthuntStep {
  ADD_PAGE,
  SUCCESS,
}

const ConnectProducthunt = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectProducthuntStep.ADD_PAGE);

  return (
    <>
      {step === ConnectProducthuntStep.ADD_PAGE && (
        <AddProductPage
          onConnected={() => {
            onConnected();
            setStep(ConnectProducthuntStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectProducthuntStep.SUCCESS && (
        <ReviewTaskSuccess
          onConfirmed={() => {
            onComplete();
            setStep(ConnectProducthuntStep.ADD_PAGE);
          }}
        />
      )}
    </>
  );
};

export default ConnectProducthunt;
