import { Modal } from 'rsuite';
import { ModalSize } from 'rsuite/esm/Modal';
import styled, { css } from 'styled-components';

const ModalHeaderText = styled(Modal.Title)<{ centertitle?: boolean }>`
  /* font-size: ${({ theme }) => theme.typography.fontSizes.xl}; */
  /* font-weight: ${({ theme }) => theme.typography.fontWeights.bold}; */
  margin: 0;
  margin-bottom: 5px;
  ${({ centertitle }) =>
    !!centertitle &&
    css`
      text-align: center;
    `}
`;

const ModalSubHeaderText = styled(Modal.Title)`
  margin: 0 0 10px 0;
`;

interface Props {
  size?: ModalSize;
  shouldOpenModal: boolean;
  title?: any;
  subTitle?: string;
  body: React.ReactNode;
  footer?: React.ReactNode;
  onModalClose: () => void;
  centertitle?: boolean;
  backdrop?: boolean | 'static';
  classNames?: string;
}

const AppModal = ({
  title,
  subTitle,
  body,
  size = 'md',
  footer,
  shouldOpenModal,
  onModalClose,
  backdrop = true,
  centertitle = false,
  classNames,
}: Props) => (
  <Modal
    open={shouldOpenModal}
    onClose={onModalClose}
    size={size}
    className={`appModal ${classNames ?? ''}`}
    backdrop={backdrop}
  >
    <Modal.Header>
      {title && (
        <ModalHeaderText centertitle={centertitle}>{title}</ModalHeaderText>
      )}
      {subTitle && <ModalSubHeaderText>{subTitle}</ModalSubHeaderText>}
    </Modal.Header>
    <Modal.Body
      style={{
        padding: 0,
        marginTop: 10,
        marginRight: 0,
      }}
    >
      {body}
    </Modal.Body>
    {footer && <Modal.Footer>{footer}</Modal.Footer>}
  </Modal>
);

export default AppModal;
