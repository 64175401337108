import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { WidgetType } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  accountSelector,
  toggleFeedbackDrawer,
} from '../../../userData/redux/userData.slice';

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;
  pre {
    margin: 0 0 20px !important;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: 25px;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;
const StepWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledMessage = styled(Message)`
  margin-top: 15px;
`;

const ShopifyCarouselWidget = ({
  widgetType,
  count = 15,
}: {
  widgetType: WidgetType;
  count?: number;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { account } = useAppSelector(accountSelector);
  const [copied, setCopied] = useState(false);
  const { installationCode } = useInstallation(Widget.EMBEDDED);
  const { selectedSite: site } = account ?? {};
  const embedCode = `${installationCode}
<${widgetType === 'marquee' ? 'reviewsjet-marquee' : 'reviewsjet-slider'} id="${
    site?._id
  }" count="${count}" platform="others"></${
    widgetType === 'marquee' ? 'reviewsjet-marquee' : 'reviewsjet-slider'
  }>`;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wrapper>
      <StepWrapper>
        <StepDesc>
          Click <strong>Online Store</strong>:
        </StepDesc>
        <img
          src="/images/tutorials/shopify/embedded/1.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>

        <StepDesc>
          Click <strong>Customize</strong>:
        </StepDesc>
        <img
          src="/images/tutorials/shopify/embedded/2.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Select the page you would like to install the widget on
        </StepDesc>
        <img
          src="/images/tutorials/shopify/embedded/3.png?v=1.0.0"
          alt=""
          className="screenshot"
        />

        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Click <strong>Add Section</strong> and select the{' '}
          <strong>Carousel Slider</strong> widget under the{' '}
          <strong>Apps</strong> tab.
        </StepDesc>
        <img
          src="/images/tutorials/shopify/embedded/a.png?v=1.0.0"
          alt=""
          className="screenshot"
        />
        <div style={{ paddingBottom: 30 }}></div>
        <StepDesc>
          Click the <strong>Apps</strong> tab where the
          <strong>Carousel Slider</strong> widget is listed, then go to{' '}
          <strong>Theme Settings</strong>, uncheck{' '}
          <strong>Reveal sections on scroll</strong>, and click{' '}
          <strong>Save</strong>.
        </StepDesc>
        <img
          src="/images/tutorials/shopify/embedded/b.png?v=1.0.0"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <Divider />

      <p style={{ textAlign: 'center' }}>
        Congratulations! Your Carousel widget should now be successfully
        integrated and visibly displayed on your website.
      </p>

      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Carousel widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </Wrapper>
  );
};

export default ShopifyCarouselWidget;
