import SearchAppleStores from './SearchStores';
import { AppleConnectProvider } from '../../../contexts/AppleLocationContext';
import { useState } from 'react';
import ListAppleStores from './ListAppleStores';
import Success from './Success';
import ConfirmAppleStore from './ConfirmAppleStore';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectAppleStep {
  SEARCH,
  LIST,
  CONFIRM,
  SUCCESS,
}

const ConnectApple = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectAppleStep.SEARCH);

  return (
    <AppleConnectProvider>
      {step === ConnectAppleStep.SEARCH && (
        <SearchAppleStores onSuccess={() => setStep(ConnectAppleStep.LIST)} />
      )}
      {step === ConnectAppleStep.LIST && (
        <ListAppleStores
          onGoBack={() => setStep(ConnectAppleStep.SEARCH)}
          onLocationSelected={() => {
            setStep(ConnectAppleStep.CONFIRM);
          }}
        />
      )}
      {step === ConnectAppleStep.CONFIRM && (
        <ConfirmAppleStore
          onGoback={() => setStep(ConnectAppleStep.LIST)}
          onConfirmed={() => {
            onConnected();
            setStep(ConnectAppleStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectAppleStep.SUCCESS && (
        <Success
          onConfirmed={() => {
            onComplete();
            setStep(ConnectAppleStep.SEARCH);
          }}
        />
      )}
    </AppleConnectProvider>
  );
};

export default ConnectApple;
