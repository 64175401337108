import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import {
  Loading,
  QuestionQueryParams,
  QuestionStatus,
} from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { REVIEWS_PAGE_PER_PAGE } from '../../review/utils/constants';

const http = new Http();

export const fetchQuestions = createAsyncThunk<
  any,
  {
    payload: QuestionQueryParams;
    showPreloader?: boolean;
  },
  { rejectValue: string; state: RootState }
>(
  'reviews/fetch',
  async (
    {
      payload: {
        pageNumber = 1,
        search = '',
        status,
        limit = REVIEWS_PAGE_PER_PAGE,
        sort = 'desc',
      },
    },
    { rejectWithValue }
  ) => {
    let params = {
      sort,
      limit: String(limit),
    } as {
      limit?: string;
      page?: string;
      status?: QuestionStatus;
      sortBy?: string;
      sort: string;
    };

    if (pageNumber) {
      params = { ...params, page: String(pageNumber) };
    }

    if (status) {
      params = {
        ...params,
        status,
      };
    }

    const queryString = new URLSearchParams(params as any);
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/question/list?${queryString}`;

    try {
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  },
  {
    condition(_, api): boolean {
      const {
        reviews: {
          reviews: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const deleteQuestion = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('question/delete', async (questionId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/question/${questionId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});

export const submitAnswer = createAsyncThunk<
  any,
  { questionId: string; answer: string },
  { rejectValue: string; state: RootState }
>(
  'question/answer',
  async (
    { questionId, answer }: { questionId: string; answer: string },
    { rejectWithValue }
  ) => {
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/question/answer/${questionId}`,
        { answer }
      );
      return answer;
    } catch (error) {
      return rejectWithValue((error as any).response.data.error);
    }
  }
);

export const createQuestion = createAsyncThunk<
  any,
  FormData,
  { rejectValue: string; state: RootState }
>('question/create', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/question`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data.error);
  }
});
