import styled from 'styled-components';

const Wrapper = styled.div`
  h4 {
    color: #ff3e3e;
  }
  .title {
    color: #000;
    font-size: 16px;
  }
`;

export default function Troubleshooting() {
  return (
    <Wrapper>
      <h4>Troubleshooting</h4>
      <p className="title">
        <b>
          The widget appears in the editor and preview mode, but it fails to
          display once the site is published.
        </b>
      </p>
      <p>
        Does your website URL contain <b>.wixsite.com</b>? One of Wix's
        limitations is that Embed Codes only function with a{' '}
        <b>connected domain</b>.
      </p>
      <p>
        To see the widget displayed, kindly <b>connect a domain</b> to your site
        and refresh the page.
      </p>
    </Wrapper>
  );
}
