import styled from 'styled-components';

const LogoCrown = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 100%;
`;

const ReviewsJetCrownLogo = ({ onClick }: { onClick?: () => void }) => {
  return (
    <LogoCrown
      alt="ReviewsJet"
      src="/images/logo-crown.svg"
      onClick={onClick}
    />
  );
};

export default ReviewsJetCrownLogo;
