import { Button, Divider, Tag } from 'rsuite';
import styled from 'styled-components';
import { CHANGE_LOG_LINK } from '../../../features/review/utils/constants';
import ScreenshotWrapper from '../ScreenshotWrapper';

const Wrapper = styled.div`
  color: #ddd;
  max-height: 600px;
  overflow-y: auto;

  p {
    font-size: 15px;
    line-height: 25px;

    &.last-p {
      margin-bottom: 20px;
    }
  }
  ul {
    padding-left: 25px;
    margin: 10px 0;
  }

  ul li {
    list-style: circle;
  }
  .rs-divider {
    background-color: #8e8e8e4a !important;
  }
`;

const Title = styled.h3`
  font-size: 25px;
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const TagsWrapper = styled.div`
  padding: 0 0 10px;
  margin-bottom: 10px;

  .new {
    font-weight: bold;
    color: #22c55e !important;
    background-color: #22c55e1a !important;
  }
`;

export default function WhatIsNew() {
  return (
    <Wrapper>
      <ScreenshotWrapper screenshot="/whats-new/review-image.png" />
      <Title>
        <span>Create and Share Review Images 🎨</span>
      </Title>

      <TagsWrapper>
        <Tag className="new" size="sm">
          NEW
        </Tag>
      </TagsWrapper>
      <p>
        We’re excited to introduce our latest feature—Stylish Shareable Review
        Images!
      </p>
      <p>
        Now, you can easily transform your customer reviews into eye-catching
        images and share them across your social media platforms.
      </p>

      <p>Why use it?</p>

      <ul>
        <li>
          <b>Engage Your Audience</b>: Capture attention with visually appealing
          review images that stand out on social media.
        </li>
        <li>
          <b>Build Trust</b>: Highlight positive feedback from your customers in
          a creative way.
        </li>
        <li>
          <b>Boost Visibility</b>: Reviews shared as images tend to perform
          better on social platforms, helping you reach a wider audience.
        </li>
      </ul>
      <p className="last-p">
        With just a few clicks, turn your reviews into shareable, polished
        images and let your customers’ voices be seen and heard!
      </p>

      <Button
        appearance="link"
        size="sm"
        color="green"
        onClick={() => window.open(CHANGE_LOG_LINK, '_blank')}
      >
        Read more
      </Button>
      <Divider />

      <ScreenshotWrapper screenshot="/whats-new/wall-of-love.jpeg" />
      <Title>
        <span>Share Your “Wall of Love” ❤️</span>
      </Title>

      <TagsWrapper>
        <Tag className="new" size="sm">
          NEW
        </Tag>
      </TagsWrapper>
      <p>We’re excited to introduce a new feature: the "Wall of Love"!</p>
      <p>
        Now, you can share a dedicated link that showcases all your customer
        reviews in one beautiful, organized space.
      </p>
      <p>
        This feature allows you to display your glowing testimonials publicly,
        making it easier for potential customers to discover how much your
        existing customers love your brand.
      </p>

      <p>Why use it?</p>

      <ul>
        <li>
          <b>Boost Trust</b>: Let potential customers see all your reviews in
          one place.
        </li>
        <li>
          <b>Easy Sharing</b>: Share a single link to display your reviews
          effortlessly.
        </li>
        <li>
          <b>SEO Optimized</b>: Your reviews page is optimized to help improve
          your search rankings.
        </li>
      </ul>
      <p className="last-p">
        Start sharing your "Wall of Love" today and let your happy customers
        speak for you!
      </p>

      <Button
        appearance="link"
        size="sm"
        color="green"
        onClick={() => window.open(CHANGE_LOG_LINK, '_blank')}
      >
        Read more
      </Button>
      <Divider />

      <ScreenshotWrapper screenshot="/whats-new/review-form.png" />
      <Title>
        <span>Shareable Review Form 📝</span>
      </Title>

      <TagsWrapper>
        <Tag className="new" size="sm">
          NEW
        </Tag>
      </TagsWrapper>
      <p>We’ve just launched a powerful new feature — Shareable Review Form!</p>
      <p>
        Now, you can easily gather more reviews by sharing a direct link to your
        review form across various channels like your website, social media, or
        email campaigns.
      </p>

      <p>Why use it?</p>

      <ul>
        <li>
          <b>Boost Review Collection</b>: Simplify the process for customers to
          leave feedback, increasing the number of reviews you collect.
        </li>
        <li>
          <b>Multi-Channel Sharing</b>: Share your review form link anywhere—on
          your website, social media platforms, or email newsletters.
        </li>
        <li>
          <b>Convenient and User-Friendly</b>: Your customers can leave reviews
          with just a few clicks, making it easier than ever to gather valuable
          feedback.
        </li>
      </ul>
      <p className="last-p">
        Start sharing your review form today and watch your review count grow!
      </p>

      <Button
        appearance="link"
        size="sm"
        color="green"
        onClick={() => window.open(CHANGE_LOG_LINK, '_blank')}
      >
        Read more
      </Button>
      <Divider />

      <ScreenshotWrapper screenshot="/whats-new/video-review-feature.png" />
      <Title>
        <span>Collect and Display Video Reviews!</span>
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M638.128 223.376c1.28 0 2.32 1.008 2.32 2.24v127.872c0 23.664 13.056 45.424 34 56.528a63.763 63.763 0 0 0 30 7.471c12.56 0 27.056-3.68 37.84-10.991L960 283.264V739.68L741.088 620.16a63.92 63.92 0 0 0-36.655-11.536 64.277 64.277 0 0 0-29.568 7.217c-21.12 11.024-34.4 32.88-34.432 56.688l-.16 125.84c0 1.248-1.008 2.256-2.288 2.256H66.289c-1.28 0-2.289-.992-2.289-2.225l.16-572.784c0-1.248 1.008-2.24 2.289-2.24h571.68zm352.24-32.032c-6.816 0-20.291 2.016-27.97 9.664l-257.969 152.48V225.616c0-36.56-29.68-66.24-66.319-66.24H66.43c-36.672 0-66.288 29.665-66.288 66.241l-.144 572.752c0 36.56 29.632 66.256 66.288 66.256h571.712c36.657 0 66.289-29.68 66.289-66.256l.16-125.744 262.976 153.312c7.712 7.68 16.256 6.687 23.088 6.687 7.087 0 12.368-2.16 13.024-2.432 12.432-5.184 20.464-17.184 20.464-30.688V224.528c0-13.504-8.032-25.551-20.464-30.656-.72-.32-6.031-2.528-13.167-2.528z"></path>
        </svg>
      </Title>

      <TagsWrapper>
        <Tag className="new" size="sm">
          NEW
        </Tag>
      </TagsWrapper>
      <p>
        We’re excited to introduce our latest feature: video reviews! You can
        now allow your customers to submit video testimonials and showcase them
        directly on your website.
      </p>
      <p>Why collect video reviews?</p>
      <ul>
        <li>
          Build trust – Video reviews add authenticity and credibility to
          customer feedback.
        </li>
        <li>
          Boost conversions – Potential customers are more likely to convert
          when they see real people endorsing your product.
        </li>
        <li>
          Stand out – Video reviews make your website more engaging and visually
          dynamic.
        </li>
      </ul>
      <p className="last-p">
        Try it out and start building trust with authentic video reviews today!
      </p>

      <Button
        appearance="link"
        size="sm"
        color="green"
        onClick={() => window.open(CHANGE_LOG_LINK, '_blank')}
      >
        Read more
      </Button>
      <Divider />
    </Wrapper>
  );
}
