import styled from 'styled-components';
import range from '../utils/range';

type Props = {
  color: string;
  rating: number;
  size: number;
};

const FullRating = ({ color, size }: { color: string; size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.93384 0.773358C10.2694 -0.257787 11.7306 -0.257785 12.0662 0.77336L13.8944 6.39099C14.0445 6.85213 14.4749 7.16434 14.9605 7.16434H20.8768C21.9628 7.16434 22.4143 8.55174 21.5357 9.18902L16.7494 12.6609C16.3565 12.9459 16.1921 13.4511 16.3421 13.9122L18.1704 19.5299C18.506 20.561 17.3239 21.4185 16.4453 20.7812L11.6589 17.3093C11.266 17.0243 10.734 17.0243 10.3411 17.3093L5.55471 20.7812C4.67614 21.4185 3.49405 20.561 3.82963 19.5299L5.65786 13.9122C5.80793 13.4511 5.64353 12.9459 5.25062 12.6609L0.464252 9.18902C-0.414312 8.55174 0.037212 7.16434 1.12318 7.16434H7.03945C7.52511 7.16434 7.95553 6.85213 8.10561 6.39099L9.93384 0.773358Z"
        fill={color}
      />
    </svg>
  );
};

const EmptyRating = ({ size }: { size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.93384 0.773358C10.2694 -0.257787 11.7306 -0.257785 12.0662 0.77336L13.8944 6.39099C14.0445 6.85213 14.4749 7.16434 14.9605 7.16434H20.8768C21.9628 7.16434 22.4143 8.55174 21.5357 9.18902L16.7494 12.6609C16.3565 12.9459 16.1921 13.4511 16.3421 13.9122L18.1704 19.5299C18.506 20.561 17.3239 21.4185 16.4453 20.7812L11.6589 17.3093C11.266 17.0243 10.734 17.0243 10.3411 17.3093L5.55471 20.7812C4.67614 21.4185 3.49405 20.561 3.82963 19.5299L5.65786 13.9122C5.80793 13.4511 5.64353 12.9459 5.25062 12.6609L0.464252 9.18902C-0.414312 8.55174 0.037212 7.16434 1.12318 7.16434H7.03945C7.52511 7.16434 7.95553 6.85213 8.10561 6.39099L9.93384 0.773358Z"
        fill="#999999"
      />
    </svg>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  width: fit-content;
`;

const ReviewRating = ({ color, rating, size }: Props) => {
  return (
    <Wrapper>
      {[...range(1, rating)].map((key) => (
        <FullRating key={`full-rating-${key}`} color={color} size={size} />
      ))}
      {[...range(1, 5 - rating)].map((key) => (
        <EmptyRating key={`empty-rating-${key}`} size={size} />
      ))}
    </Wrapper>
  );
};

export default ReviewRating;
