import { Button, List } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../../shared/components/styled-text';

const LocationImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid #d4d4d4;
`;
const MissingImg = styled.div`
  width: 50px;
  height: 50px;
  background: #d4d4d4;
  border-radius: 5px;
  margin-right: 10px;
  padding: 2px;
`;
const LocationName = styled(StyledText)`
  font-weight: 700;
  line-height: 19px;
`;
const PhoneNumber = styled(StyledText)`
  line-height: 19px;
  text-align: left;
  font-size: 13px;
  color: #676767;
  margin-top: 2px;
`;
const StyledButton = styled(Button)`
  display: none;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 460px;
`;
const TextWrapper = styled.div``;

const StyledList = styled(List)`
  cursor: pointer;
  border-top: 0;
`;

const StyledListItem = styled(List.Item)`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    button {
      display: block;
    }
  }
`;

interface Props {
  title: string;
  thumbnail: string;
  phone: string;
  onSelect: () => void;
}

export const BusinessItem = ({ title, thumbnail, phone, onSelect }: Props) => (
  <StyledList hover>
    <StyledListItem onClick={onSelect}>
      <LeftWrapper>
        {thumbnail ? (
          <LocationImage src={thumbnail} referrerPolicy="no-referrer" />
        ) : (
          <MissingImg />
        )}
        <TextWrapper>
          <LocationName>{title}</LocationName>
          <PhoneNumber>{phone}</PhoneNumber>
        </TextWrapper>
      </LeftWrapper>
      <StyledButton onClick={onSelect} appearance="ghost" size="sm">
        Select
      </StyledButton>
    </StyledListItem>
  </StyledList>
);
