import { PlanCycle, SubscriptionPlan } from '../types/review.types';

export const PRICE_LEVELS: Record<string, number> = {
  [`${SubscriptionPlan.FREE}:${PlanCycle.NO_CYCLE}`]: 0,
  [`${SubscriptionPlan.PRO}:${PlanCycle.MONTHLY}`]: 1,
  [`${SubscriptionPlan.PRO}:${PlanCycle.YEARLY}`]: 2,
  [`${SubscriptionPlan.ADVANCED}:${PlanCycle.MONTHLY}`]: 3,
  [`${SubscriptionPlan.ADVANCED}:${PlanCycle.YEARLY}`]: 4,
  [`${SubscriptionPlan.PREMIUM}:${PlanCycle.MONTHLY}`]: 5,
  [`${SubscriptionPlan.PREMIUM}:${PlanCycle.YEARLY}`]: 6,
} as const;
