import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { PRODUCT_ITEMS_PER_PAGE } from '../../review/utils/constants';
import { ConvertReviewPayload, ProductResponse } from '../types';

const http = new Http();

export type ProductQueryParams = {
  limit?: number;
  offset?: number;
  filter?: string;
  showPreloader?: boolean;
};

export const fetchProducts = createAsyncThunk<
  ProductResponse,
  ProductQueryParams,
  { rejectValue: string; state: RootState }
>(
  'product/products',
  async (
    { limit = PRODUCT_ITEMS_PER_PAGE, offset = 0, filter = '' },
    { rejectWithValue }
  ) => {
    let params = {
      offset: String(offset),
      limit: String(limit),
    } as any;

    if (filter) {
      params = {
        ...params,
        filter,
      };
    }

    const queryString = new URLSearchParams(params);

    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/product?${queryString}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const fetchMoreProducts = createAsyncThunk<
  ProductResponse,
  ProductQueryParams,
  { rejectValue: string; state: RootState }
>(
  'product/products/more',
  async (
    { limit = PRODUCT_ITEMS_PER_PAGE, offset = 0 },
    { rejectWithValue }
  ) => {
    let params = {
      offset: String(offset),
      limit: String(limit),
    };

    const queryString = new URLSearchParams(params);

    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/product?${queryString}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const convertToProductReview = createAsyncThunk<
  { success: boolean; review: any },
  ConvertReviewPayload,
  { rejectValue: string; state: RootState }
>(
  'product/convert',
  async ({ reviewId, product, reviewImages }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews/convert-to-product`,
        {
          reviewId,
          product,
          reviewImages,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);
