import { useState } from 'react';
import { FaCircleQuestion } from 'react-icons/fa6';
import { MdLibraryBooks } from 'react-icons/md';
import { Drawer } from 'rsuite';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { FeedbackComponent } from './feedback-component';
import { SupportIframe } from './support-iframe';

const StyledDrawerBody = styled(Drawer.Body)<{ update?: boolean }>`
  ${({ update }) =>
    update &&
    css`
      padding: 0px;
      overflow: hidden;
    `}
`;

const EmptySpace = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Card = styled(FlexContainer)`
  padding: 10px;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ededed;

  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin: 10px 0 5px;
  }

  p {
    color: #838383;
    font-size: 13px;
    width: 100%;
    text-align: center;
  }

  svg {
    fill: #959494;
  }
`;

interface Props {
  openDrawer: boolean;
  onDrawerClose: () => void;
}

enum ActivePage {
  SUPPORT = 'support',
  FEEDBACK = 'feedback',
}

const FeedbackDrawer = ({ openDrawer, onDrawerClose }: Props) => {
  const [activePage, setActivePage] = useState<ActivePage | null>(
    ActivePage.FEEDBACK
  );

  return (
    <Drawer open={openDrawer} onClose={onDrawerClose}>
      <StyledDrawerBody update={activePage === ActivePage.SUPPORT}>
        {!activePage && (
          <EmptySpace>
            <FlexContainer
              gap="10px"
              alignItems="stretch"
              style={{ width: '100%' }}
            >
              <Card
                role="presentation"
                onClick={() => setActivePage(ActivePage.SUPPORT)}
              >
                <MdLibraryBooks size={25} />
                <h4>Browse Guides</h4>
                <p>Detailed guides on setting up and using ReviewsJet.</p>
              </Card>
              <Card
                role="presentation"
                onClick={() => setActivePage(ActivePage.FEEDBACK)}
              >
                <FaCircleQuestion size={25} />
                <h4>Get Help</h4>
                <p>Need help finding something? Contact us!</p>
              </Card>
            </FlexContainer>
          </EmptySpace>
        )}
        {activePage === ActivePage.SUPPORT && <SupportIframe />}
        {activePage === ActivePage.FEEDBACK && (
          <FeedbackComponent onDrawerClose={onDrawerClose} />
        )}
      </StyledDrawerBody>
    </Drawer>
  );
};

export default FeedbackDrawer;
