import { Tag, Toggle } from 'rsuite';
import { PlanCycle } from '../../../types/review.types';
import FlexContainer from '../../layouts/flex-container';

type Props = {
  cycle: PlanCycle;
  switchCycle: (cycle: PlanCycle) => void;
};

export default function BillingCycleSwitcher({ cycle, switchCycle }: Props) {
  return (
    <FlexContainer
      justifyContent="flex-start"
      gap="5px"
      className="cycle-switcher"
    >
      <Toggle
        size="md"
        className="price-switcher"
        checked={cycle === PlanCycle.YEARLY}
        onChange={(value) => {
          if (value) {
            switchCycle(PlanCycle.YEARLY);
          } else {
            switchCycle(PlanCycle.MONTHLY);
          }
        }}
      />
      <label htmlFor="billed-monthly">Pay yearly</label>
      <Tag size="md" color="green" className="discount">
        2 months free
      </Tag>
    </FlexContainer>
  );
}
