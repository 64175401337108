const MobileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      clipPath="url(#a)"
    >
      <path d="M14.444 4.864v7.727c0 3.09-.777 3.864-3.888 3.864H5.889C2.778 16.454 2 15.681 2 12.59V4.864C2 1.773 2.778 1 5.889 1h4.667c3.11 0 3.888.773 3.888 3.864ZM9.778 3.705H6.667" />
      <path d="M8.222 14.214c.666 0 1.206-.537 1.206-1.198 0-.662-.54-1.198-1.206-1.198-.666 0-1.205.537-1.205 1.198 0 .661.54 1.198 1.205 1.198Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h17v17H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MobileIcon;
