import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, Panel } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import Preloader from '../../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import ErrorPage from '../components/error-page';
import { listWebsites } from '../redux/actions';

const LeftSection = styled.div`
  width: 50%;
  height: 100vh;
  padding: 50px;
`;
const InnerLeftSection = styled.div`
  max-width: 700px;
`;
const LogoCrown = styled.img`
  height: 50px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  border: 2px solid #eaeaea;
`;
const LogoText = styled.img`
  height: 20px;
`;
const MadeWithLove = styled.p`
  font-size: 13px;
  color: #6e6e6e;
  text-align: center;
  margin-top: 50px;
`;
const PanelWrapper = styled.div`
  margin-top: 40px;
`;
const ListItem = styled(List.Item)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: auto;
    padding: 5px 15px;
    border-radius: 5px;
  }
  &:hover span {
    background-color: #0c97e2;
    color: #fff;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const SelectSite = () => {
  const navigate = useNavigate();

  const {
    query: { id, email, platform, fullname },
  } = useAppSelector((state) => state.router.location);
  const {
    sites: { items: webflowSites, loading },
  } = useAppSelector((state) => state.webflow);
  const dispatch = useAppDispatch();

  const onClick = (siteId: string) => {
    navigate(
      `/register?id=${id}&email=${email}&site=${siteId}&platform=${platform}&fullname=${fullname}`
    );
  };

  useEffect(() => {
    if (webflowSites.length === 1) {
      navigate(
        `/register?id=${id}&email=${email}&site=${webflowSites[0].id}&platform=${platform}&fullname=${fullname}`
      );
    }
  }, [webflowSites]);

  useEffect(() => {
    dispatch(listWebsites(id))
      .then(unwrapResult)
      .catch(() => {});
  }, [id]);

  if (loading === Loading.ERROR) {
    return (
      <Wrapper style={{ maxWidth: 650 }}>
        <ReviewsJetCrownLogo />
        <ErrorPage
          onRetryClick={() => {
            window.open('https://www.reviewsjet.com');
          }}
          showFooter={false}
        />
      </Wrapper>
    );
  }

  return (
    <>
      {loading === Loading.PENDING && <Preloader />}
      {loading === Loading.SUCCESS && (
        <FlexContainer stack alignItems="center">
          <LeftSection>
            <InnerLeftSection>
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap="10px"
              >
                <ReviewsJetCrownLogo />
                <LogoText
                  alt="ReviewsJet"
                  src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/logos/logo-text.svg"
                />
              </FlexContainer>

              <PanelWrapper>
                <Panel header="Select a website:">
                  <List size="md" bordered hover>
                    {webflowSites.map((site) => (
                      <ListItem key={site.id} onClick={() => onClick(site.id)}>
                        {site.displayName}
                        <span>Select</span>
                      </ListItem>
                    ))}
                  </List>
                </Panel>
              </PanelWrapper>

              <MadeWithLove>
                Made with <FontAwesomeIcon icon={faHeart} /> by FlexByt Inc.
              </MadeWithLove>
            </InnerLeftSection>
          </LeftSection>
        </FlexContainer>
      )}
    </>
  );
};

export default SelectSite;
