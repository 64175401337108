import { ReactNode, createContext, useContext, useState } from 'react';
import {
  GoogleBusiness,
  GoogleConnectFormData,
} from '../../../shared/types/review.types';

export type GoogleConnectContextType = {
  formValues: GoogleConnectFormData;
  selectedBusiness: GoogleBusiness | null;
  updateFormValues: (values: GoogleConnectFormData) => void;
  updateSelectedBusiness: (values: GoogleBusiness) => void;
};

export const GoogleConnectContext =
  createContext<GoogleConnectContextType | null>(null);

export const GoogleConnectProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [formValues, setFormValues] = useState<GoogleConnectFormData>({
    businessAddress: '',
    gpsCoordinates: '',
    hl: 'en',
  });
  const [selectedBusiness, setSelectedBusiness] =
    useState<GoogleBusiness | null>(null);

  const updateFormValues = (values: GoogleConnectFormData) => {
    setFormValues(values);
  };
  const updateSelectedBusiness = (values: GoogleBusiness) => {
    setSelectedBusiness(values);
  };

  return (
    <GoogleConnectContext.Provider
      value={{
        formValues,
        selectedBusiness,
        updateFormValues,
        updateSelectedBusiness,
      }}
    >
      {children}
    </GoogleConnectContext.Provider>
  );
};

export const useGoogleConnectContext = () => {
  const googleConnectContext = useContext<GoogleConnectContextType | null>(
    GoogleConnectContext
  );
  if (!googleConnectContext) {
    throw new Error(
      'useGoogleConnectContext must be used within a GoogleConnectProvider'
    );
  }
  return googleConnectContext;
};
