import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { GoDash } from 'react-icons/go';
import { Breadcrumb, Button, Divider, Tag, Toggle } from 'rsuite';
import styled, { css } from 'styled-components';
import { createBillingPortalSession, createCheckoutSession } from '../../api';
import NavLink from '../../shared/components/NavLink';
import FlexContainer from '../../shared/components/layouts/flex-container';
import StyledText from '../../shared/components/styled-text';
import { PlanCycle, SubscriptionPlan } from '../../shared/types/review.types';
import { getPlanLevel } from '../../shared/utils/getPlanPrice';
import USAGE from '../../shared/utils/usage-limits';
import { useAppSelector } from '../../store';
import { PLAN_PRICES } from '../review/utils/constants';
import { accountSelector } from '../userData/redux/userData.slice';

const PlansContainer = styled(FlexContainer)`
  max-width: 1260px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 20px;
  margin-bottom: 50px;
`;
const PlansComparerContainer = styled.div`
  margin-top: 50px;
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  table {
    table-layout: fixed;
    width: 100%;
    th,
    .section {
      color: #000;
      font-size: 15px;
      font-weight: bold;
    }
    td,
    th {
      padding: 15px 0 15px 15px;
      text-align: left;
    }
    td {
      border-bottom: 1px solid rgb(217, 217, 217);
    }
    td.feature {
      /* cursor: pointer;
      text-decoration-color: rgb(204 204 204);
      text-decoration-style: dashed;
      text-decoration-line: underline;
      text-underline-offset: 4px; */
    }
  }
  .section {
    background-color: rgb(250, 250, 252);
    border-top: 1px solid rgb(217, 217, 217);
    border-bottom: 1px solid rgb(217, 217, 217);
  }
`;
const Wrapper = styled.div`
  padding-bottom: 100px;
`;

const BreadcrumbWrapper = styled.div`
  padding: 20px 30px;
  max-width: 950px;
  margin: auto;
`;

const Plan = styled.div`
  width: 25%;
  max-width: 290px;
  min-width: 250px;
  border-radius: 10px;
  background-color: #fafafc;
  padding: 40px 20px;
  border: 1px solid #d9d9d9;
  position: relative;
  @media (max-width: 1259px) {
    width: 50% !important;
    max-width: 450px;
  }
  @media (max-width: 959px) {
    width: 80% !important;
    max-width: 80%;
  }
  &.recommended {
    border: 2px solid #673ab7 !important;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    span.tag {
      position: absolute;
      color: #fff;
      top: -13px;
      background-color: #673ab7;
      display: block;
      padding: 5px 10px;
      border-radius: 10px;
      right: 15px;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.05em;
      font-weight: bold;
    }
  }
`;

const Title = styled(StyledText)`
  text-align: center;
  font-size: 60px;
  max-width: 900px;
  margin: 80px auto 20px;
  line-height: 75px;
  font-weight: 900;
  color: #000;
  padding-left: 30px;
  padding-right: 30px;
`;

const SubTitle = styled(StyledText)`
  font-size: 19px;
  max-width: 700px;
  text-align: center;
  margin: auto;
  color: #636363;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 30px;
`;

const FreeTrailerWrapper = styled.div`
  text-align: center;
  margin: 30px auto;
  font-weight: bold;
  span {
    font-size: 15px;
    letter-spacing: 1px;
  }
`;

const DiscountImg = styled.img`
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
`;
const PriceSwitcherWrapper = styled(FlexContainer)`
  width: 340px;
  margin: auto;
  padding-top: 40px;
`;

const PlanName = styled(StyledText)`
  font-weight: bold;
  font-size: 20px;
  color: #000;
  /* text-transform: uppercase; */
`;
const PlanNameDesc = styled(StyledText)`
  font-size: 15px;
  margin: 0;
  color: #616161;
`;

const PlanPrice = styled(StyledText)`
  font-size: 15px;
  margin: 15px 0;
  color: #000;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 2.2rem;
  letter-spacing: -2px;
  display: inline-block;
  sup {
    top: -0.3em;
    font-size: 25px;
  }
`;

const Price = styled.p`
  span.unit {
    letter-spacing: 1px;
    font-size: 15px;
  }
`;

const StyledButton = styled(Button)<{ currentPlan: boolean }>`
  border-radius: 6px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  &:hover {
    outline: none;
    box-shadow: none;
    border-width: 1px !important;
  }
  &.recommended-btn {
    border: 1px solid transparent;

    ${({ currentPlan }) =>
      currentPlan &&
      css`
        color: #000;
        font-weight: bold;
        border: 1px solid #afafaf;
        background: transparent;
      `}
  }
  ${({ currentPlan }) =>
    currentPlan &&
    css`
      color: #000;
      font-weight: bold;
      border: 1px solid #afafaf;
    `}
`;

const Features = styled(FlexContainer)`
  margin-top: 20px;
  span {
    font-size: 15px;
  }
`;

const showCurrentPlan = ({
  planName,
  isCurrent,
  currentPlan,
  isNewCustomer,
}: {
  planName: SubscriptionPlan;
  isCurrent: boolean;
  currentPlan?: SubscriptionPlan;
  isNewCustomer: boolean;
}) => {
  if (isCurrent) {
    return 'Current plan';
  }

  if (
    getPlanLevel(currentPlan!, PlanCycle.MONTHLY) <
    getPlanLevel(planName, PlanCycle.MONTHLY)
  ) {
    return isNewCustomer ? 'Start a free trial' : 'Upgrade plan';
  } else {
    return 'Downgrade';
  }
};

const PlansPage = () => {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account || {};
  const { plan: currentPlan, planCycle, stripe } = selectedSite || {};

  const [isLoading, setIsLoading] = useState(false);
  const [cycle, setCycle] = useState<PlanCycle>(PlanCycle.YEARLY);
  const { customerId } = stripe ?? {};

  const isFreePlan = currentPlan === SubscriptionPlan.FREE;
  const isProPlan = currentPlan === SubscriptionPlan.PRO;
  const isPremiumPlan = currentPlan === SubscriptionPlan.PREMIUM;
  const isAdvancedPlan = currentPlan === SubscriptionPlan.ADVANCED;

  const isBelowAdvancedPlan =
    getPlanLevel(currentPlan!, planCycle!) <
    getPlanLevel(SubscriptionPlan.ADVANCED, PlanCycle.MONTHLY);

  const handleUpgradeButton = (plan: SubscriptionPlan) => {
    setIsLoading(true);

    if (!customerId) {
      createCheckoutSession({
        plan,
        cycle: cycle === PlanCycle.NO_CYCLE ? PlanCycle.MONTHLY : cycle,
      })
        .then(({ data }) => {
          window.location = data.url;
        })
        .catch((e) => {
          console.error(e.error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createBillingPortalSession()
        .then(({ data }) => {
          window.location = data.url;
        })
        .catch((e) => {
          console.error(e.error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wrapper>
      <BreadcrumbWrapper>
        <Breadcrumb separator={<AngleRightIcon />}>
          <Breadcrumb.Item as={NavLink} href="/manage-reviews">
            Manage reviews
          </Breadcrumb.Item>
          <Breadcrumb.Item as={NavLink} active>
            Pricing
          </Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbWrapper>
      <Title>Affordable compared to most testimonial apps</Title>
      <FlexContainer>
        <SubTitle>
          Start with {USAGE.free.reviews} reviews, then upgrade to our paid plan
          only if you're happy.
        </SubTitle>
      </FlexContainer>

      <FreeTrailerWrapper>
        <Tag size="lg" color="violet">
          Enjoy a 14 days free trial, cancel anytime!
        </Tag>
      </FreeTrailerWrapper>

      <PriceSwitcherWrapper gap="10px">
        <DiscountImg
          alt="Get two months free"
          src="https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/website/get-two-months.svg"
        />
        <label htmlFor="billed-monthly">Billed monthly</label>
        <Toggle
          size="lg"
          className="price-switcher"
          checked={cycle === PlanCycle.YEARLY}
          onChange={(value) => {
            if (value) {
              setCycle(PlanCycle.YEARLY);
            } else {
              setCycle(PlanCycle.MONTHLY);
            }
          }}
        />
        <label htmlFor="billed-monthly">Billed yearly</label>
      </PriceSwitcherWrapper>
      <PlansContainer gap="20px" alignItems="stretch">
        <Plan>
          <FlexContainer stack gap="5px">
            <PlanName>Basic</PlanName>
            <PlanNameDesc>For hobbies</PlanNameDesc>
            <Price>
              <PlanPrice>
                <sup>$</sup>0
              </PlanPrice>
              <span className="unit">/mo</span>
            </Price>
            <StyledButton
              size="md"
              currentPlan={isFreePlan}
              appearance={isFreePlan ? 'default' : 'ghost'}
              color="violet"
              block
              disabled={isLoading}
              onClick={() => handleUpgradeButton(SubscriptionPlan.FREE)}
            >
              {showCurrentPlan({
                planName: SubscriptionPlan.FREE,
                isCurrent: isFreePlan,
                currentPlan,
                isNewCustomer: !customerId,
              })}
            </StyledButton>
          </FlexContainer>
          <Features stack gap="15px" alignItems="flex-start">
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.free.seat} Workspace{USAGE.free.seat > 1 ? 's' : ''}
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>{USAGE.free.reviews} reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.free.integrations} third-party import
                {USAGE.free.integrations > 1 ? 's' : ''}
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>{USAGE.free.reviewsRequests} review requests /mo</span>
            </FlexContainer>
          </Features>
        </Plan>
        <Plan>
          <FlexContainer stack gap="5px">
            <PlanName>Pro</PlanName>
            <PlanNameDesc>For solo entrepreneurs</PlanNameDesc>
            <Price>
              <PlanPrice>
                <sup>$</sup>
                {cycle === PlanCycle.MONTHLY
                  ? PLAN_PRICES.PRO.monthly
                  : PLAN_PRICES.PRO.anually}
              </PlanPrice>
              <span className="unit">/mo</span>
            </Price>
            <StyledButton
              size="md"
              currentPlan={isProPlan}
              appearance={isProPlan ? 'default' : 'ghost'}
              color="violet"
              block
              disabled={isLoading}
              onClick={() => handleUpgradeButton(SubscriptionPlan.PRO)}
            >
              {showCurrentPlan({
                planName: SubscriptionPlan.PRO,
                isCurrent: isProPlan,
                currentPlan,
                isNewCustomer: !customerId,
              })}
            </StyledButton>
          </FlexContainer>
          <Features stack gap="15px" alignItems="flex-start">
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              {USAGE.professional.seat === -1
                ? 'Unlimited'
                : USAGE.professional.seat}{' '}
              Workspace
              {USAGE.professional.reviews > 1 ? 's' : ''}
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.professional.reviews === -1
                  ? 'Unlimited'
                  : USAGE.professional.reviews}{' '}
                reviews
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.advanced.integrations === -1
                  ? 'Unlimited'
                  : USAGE.advanced.integrations}{' '}
                third-party import
                {USAGE.professional.integrations > 1 ||
                USAGE.professional.integrations === -1
                  ? 's'
                  : ''}
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Full widget customization</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.professional.reviewsRequests} review requests /mo
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Auto-publish reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Support for mobile devices</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Reply to reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Product reviews anywhere</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Widget content translation</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Remove ReviewsJet branding</span>
            </FlexContainer>
          </Features>
        </Plan>
        <Plan className={isBelowAdvancedPlan ? 'recommended' : ''}>
          {isBelowAdvancedPlan && <span className="tag">Recommended</span>}
          <FlexContainer stack gap="5px">
            <PlanName>Advanced</PlanName>
            <PlanNameDesc>For small agencies</PlanNameDesc>
            <Price>
              <PlanPrice>
                <sup>$</sup>
                {cycle === PlanCycle.MONTHLY
                  ? PLAN_PRICES.ADVANCED.monthly
                  : PLAN_PRICES.ADVANCED.anually}
              </PlanPrice>
              <span className="unit">/mo</span>
            </Price>
            <StyledButton
              size="md"
              appearance={isBelowAdvancedPlan ? 'primary' : 'ghost'}
              color="violet"
              className={isBelowAdvancedPlan ? 'recommended-btn' : ''}
              block
              currentPlan={isAdvancedPlan}
              disabled={isLoading}
              onClick={() => handleUpgradeButton(SubscriptionPlan.ADVANCED)}
            >
              {showCurrentPlan({
                planName: SubscriptionPlan.ADVANCED,
                isCurrent: isAdvancedPlan,
                currentPlan,
                isNewCustomer: !customerId,
              })}
            </StyledButton>
          </FlexContainer>
          <Features stack gap="15px" alignItems="flex-start">
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              {USAGE.advanced.seat === -1
                ? 'Unlimited'
                : USAGE.advanced.seat}{' '}
              Workspace
              {USAGE.advanced.reviews > 1 || USAGE.advanced.reviews === -1
                ? 's'
                : ''}
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.advanced.reviews === -1
                  ? 'Unlimited'
                  : USAGE.advanced.reviews}{' '}
                reviews
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.advanced.integrations === -1
                  ? 'Unlimited'
                  : USAGE.advanced.integrations}{' '}
                third-party import
                {USAGE.advanced.integrations > 1 ||
                USAGE.advanced.integrations === -1
                  ? 's'
                  : ''}
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Full widget customization</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.advanced.reviewsRequests === -1
                  ? 'Unlimited'
                  : USAGE.advanced.reviewsRequests}{' '}
                review requests /mo
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Auto-publish reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Support for mobile devices</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Reply to reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Product reviews anywhere</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Widget content translation</span>
            </FlexContainer>
            {/* <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Google richsnippet</span>
            </FlexContainer> */}
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Remove ReviewsJet branding</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Google Rich Snippets</span>
            </FlexContainer>
          </Features>
        </Plan>
        <Plan>
          <FlexContainer stack gap="5px">
            <PlanName>Premium</PlanName>
            <PlanNameDesc>For large engencies</PlanNameDesc>
            <Price>
              <PlanPrice>
                <sup>$</sup>
                {cycle === PlanCycle.MONTHLY
                  ? PLAN_PRICES.PREMIUM.monthly
                  : PLAN_PRICES.PREMIUM.anually}
              </PlanPrice>
              <span className="unit">/mo</span>
            </Price>
            <StyledButton
              size="md"
              appearance="ghost"
              color="violet"
              block
              currentPlan={isPremiumPlan}
              disabled={isLoading}
              onClick={() => handleUpgradeButton(SubscriptionPlan.PREMIUM)}
            >
              {showCurrentPlan({
                planName: SubscriptionPlan.PREMIUM,
                isCurrent: isPremiumPlan,
                currentPlan,
                isNewCustomer: !customerId,
              })}
            </StyledButton>
          </FlexContainer>
          <Features stack gap="15px" alignItems="flex-start">
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              {USAGE.premium.seat === -1
                ? 'Unlimited'
                : USAGE.premium.seat}{' '}
              Workspace
              {USAGE.premium.reviews > 1 || USAGE.premium.reviews === -1
                ? 's'
                : ''}
              {USAGE.premium.reviews > 1 ? 's' : ''}
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.premium.reviews === -1
                  ? 'Unlimited'
                  : USAGE.premium.reviews}{' '}
                reviews
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.premium.integrations === -1
                  ? 'Unlimited'
                  : USAGE.premium.integrations}{' '}
                third-party import
                {USAGE.premium.integrations > 1 ||
                USAGE.premium.integrations === -1
                  ? 's'
                  : ''}
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Full widget customization</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>
                {USAGE.premium.reviewsRequests === -1
                  ? 'Unlimited'
                  : USAGE.premium.reviewsRequests}{' '}
                review requests /mo
              </span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Auto-publish reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Support for mobile devices</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Reply to reviews</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Product reviews anywhere</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Widget content translation</span>
            </FlexContainer>
            {/* <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Google richsnippet</span>
            </FlexContainer> */}
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Remove ReviewsJet branding</span>
            </FlexContainer>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <FaCheck size={16} color="#673ab7" />
              <span>Google Rich Snippets</span>
            </FlexContainer>
          </Features>
        </Plan>
        {/* <UpgradeNotice /> */}
        {/* <OneTimeNoticeCard /> */}
      </PlansContainer>

      <Divider />

      {/* Plans comparer */}
      <PlansComparerContainer>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Basic</th>
              <th>Pro</th>
              <th>Advanced</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>
                <StyledButton
                  size="sm"
                  appearance={isFreePlan ? 'default' : 'ghost'}
                  color="violet"
                  disabled={isLoading}
                  onClick={() => handleUpgradeButton(SubscriptionPlan.FREE)}
                >
                  {showCurrentPlan({
                    planName: SubscriptionPlan.FREE,
                    isCurrent: isFreePlan,
                    currentPlan,
                    isNewCustomer: !customerId,
                  })}
                </StyledButton>
              </td>
              <td>
                <StyledButton
                  size="sm"
                  appearance={isProPlan ? 'default' : 'ghost'}
                  color="violet"
                  disabled={isLoading}
                  onClick={() => handleUpgradeButton(SubscriptionPlan.FREE)}
                >
                  {showCurrentPlan({
                    planName: SubscriptionPlan.PRO,
                    isCurrent: isProPlan,
                    currentPlan,
                    isNewCustomer: !customerId,
                  })}
                </StyledButton>
              </td>
              <td>
                <StyledButton
                  size="sm"
                  appearance={isAdvancedPlan ? 'default' : 'primary'}
                  color="violet"
                  disabled={isLoading}
                  onClick={() => handleUpgradeButton(SubscriptionPlan.FREE)}
                >
                  {showCurrentPlan({
                    planName: SubscriptionPlan.ADVANCED,
                    isCurrent: isAdvancedPlan,
                    currentPlan,
                    isNewCustomer: !customerId,
                  })}
                </StyledButton>
              </td>
              <td>
                <StyledButton
                  size="sm"
                  appearance={isPremiumPlan ? 'default' : 'ghost'}
                  color="violet"
                  disabled={isLoading}
                  onClick={() => handleUpgradeButton(SubscriptionPlan.FREE)}
                >
                  {showCurrentPlan({
                    planName: SubscriptionPlan.PREMIUM,
                    isCurrent: isPremiumPlan,
                    currentPlan,
                    isNewCustomer: !customerId,
                  })}
                </StyledButton>
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="section">
                Features
              </td>
            </tr>
            <tr>
              <td className="feature">Google Rich Snippets</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Support for mobile devices</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Reply to reviews</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Auto-publish reviews</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Widget content translation</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Product reviews anywhere</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Bulk reviews import</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Full widget customization</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Review requests</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Third-party imports</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>

            <tr>
              <td colSpan={5} className="section">
                Usage limit
              </td>
            </tr>
            <tr>
              <td className="feature">Number of workspaces</td>
              <td>{USAGE.free.seat}</td>
              <td>
                {USAGE.professional.seat === -1
                  ? 'Unlimited'
                  : USAGE.professional.seat}
              </td>
              <td>
                {USAGE.advanced.seat === -1 ? 'Unlimited' : USAGE.advanced.seat}
              </td>
              <td>
                {USAGE.premium.seat === -1 ? 'Unlimited' : USAGE.premium.seat}
              </td>
            </tr>
            <tr>
              <td className="feature">Number of reviews</td>
              <td>{USAGE.free.reviews}</td>
              <td>
                {USAGE.professional.reviews === -1
                  ? 'Unlimited'
                  : USAGE.professional.reviews}
              </td>
              <td>
                {USAGE.advanced.reviews === -1
                  ? 'Unlimited'
                  : USAGE.advanced.reviews}
              </td>
              <td>
                {USAGE.premium.reviews === -1
                  ? 'Unlimited'
                  : USAGE.premium.reviews}
              </td>
            </tr>
            <tr>
              <td className="feature">Third-party imports</td>
              <td>{USAGE.free.integrations}</td>
              <td>
                {USAGE.professional.integrations === -1
                  ? 'Unlimited'
                  : USAGE.professional.integrations}
              </td>
              <td>
                {USAGE.advanced.integrations === -1
                  ? 'Unlimited'
                  : USAGE.advanced.integrations}
              </td>
              <td>
                {USAGE.premium.integrations === -1
                  ? 'Unlimited'
                  : USAGE.premium.integrations}
              </td>
            </tr>
            <tr>
              <td className="feature">Review requests</td>
              <td>{USAGE.free.reviewsRequests}</td>
              <td>
                {USAGE.professional.reviewsRequests === -1
                  ? 'Unlimited'
                  : USAGE.professional.reviewsRequests}
              </td>
              <td>
                {USAGE.advanced.reviewsRequests === -1
                  ? 'Unlimited'
                  : USAGE.advanced.reviewsRequests}
              </td>
              <td>
                {USAGE.premium.reviewsRequests === -1
                  ? 'Unlimited'
                  : USAGE.premium.reviewsRequests}
              </td>
            </tr>
            <tr>
              <td className="feature">Number of widgets</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td colSpan={5} className="section">
                Widgets
              </td>
            </tr>
            <tr>
              <td className="feature">Remove ReviewsJet branding</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Carousel widget</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Wall-of-love widget</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Modal widget</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Product widget</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="section">
                Support
              </td>
            </tr>
            <tr>
              <td className="feature">High priority</td>
              <td>
                <GoDash size={25} />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
            <tr>
              <td className="feature">Chat &amp; email</td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
              <td>
                <FaCheck color="#673ab7" />
              </td>
            </tr>
          </tbody>
        </table>
      </PlansComparerContainer>
    </Wrapper>
  );
};

export default PlansPage;
