import { Suspense, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import { Message } from 'rsuite';
import styled, { css } from 'styled-components';
import { getSite } from '../../features/userData/redux/action';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import DiscountCode from '../../shared/components/DiscountCode';
import Feedback from '../../shared/components/Feedback';
import Preloader from '../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../shared/components/ReviewsJetCrownLogo';
import Sidebar from '../../shared/components/layouts/sidebar';
import useToggleSidebar from '../../shared/hooks/useToggleSidebar';
import { Loading } from '../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../store';
import MenuDropdown from './MenuDropdown';
import SeatSelector from './SeatSelector';

const Wrapper = styled.div<{ collapsed: boolean; $hideSidebar: boolean }>`
  transition: width 0.15s;
  ${({ collapsed, $hideSidebar }) => {
    if (!collapsed && !$hideSidebar) {
      return css`
        margin-left: auto;
        width: calc(100% - 265px);
      `;
    }

    if (collapsed) {
      return css`
        margin-left: auto;
        width: ${$hideSidebar ? '100%' : 'calc(100% - 84px)'};
      `;
    }
  }}
`;

const LeftWrapper = styled.div`
  flex: 1;
  .rs-btn-icon.rs-btn-md {
    padding: 0;
  }
`;
const OutletWrapper = styled.div`
  transition: width 0.15s;
`;
const Topbar = styled.div<{
  $hideSidebar: boolean;
  $collapsedSidebar: boolean;
}>`
  padding: 15px 20px;
  height: 76px;
  border-bottom: 1px solid #e4e4e4;
  position: fixed;
  background: #fff;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ $hideSidebar }) => ($hideSidebar ? '100%' : 'calc(100% - 84px)')};
  display: none;
`;

const InnerWrapper = styled.div<{ $hideSidebar: boolean }>`
  ${({ $hideSidebar }) =>
    !$hideSidebar &&
    css`
      padding-top: 80px;
    `}
`;

const RightWrapper = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
  .profile-wrapper {
    position: absolute;
    top: 5px;
    right: 2px;
  }
`;
const UninstalledWrapper = styled.div`
  padding: 20px 30px;
`;

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { hideSidebar, collapsedSidebar, setCollapsedSidbar } =
    useToggleSidebar();

  useEffect(() => {
    dispatch(getSite({ showPreloader: true })).catch(() => {});
  }, []);

  return (
    <>
      <Sidebar
        hideSidebar={hideSidebar}
        collapsedSidebar={collapsedSidebar}
        toggleSidebar={(value) => setCollapsedSidbar(value)}
      />
      <Wrapper collapsed={collapsedSidebar} $hideSidebar={hideSidebar}>
        <Suspense fallback={<Preloader />}>
          <OutletWrapper>
            <Topbar
              $hideSidebar={hideSidebar}
              $collapsedSidebar={collapsedSidebar}
            >
              <LeftWrapper>
                {hideSidebar ? (
                  <ReviewsJetCrownLogo
                    onClick={() => navigate('/manage-reviews')}
                  />
                ) : (
                  <SeatSelector />
                )}
              </LeftWrapper>
              <DiscountCode />
              <RightWrapper style={{ flex: 1 }}>
                <MenuDropdown />
              </RightWrapper>
            </Topbar>
            <InnerWrapper $hideSidebar={hideSidebar}>
              {loading === Loading.SUCCESS && !!selectedSite?.unInstalled && (
                <UninstalledWrapper>
                  <Message type="error" showIcon>
                    <span>
                      {' '}
                      ReviewsJet has been uninstalled from{' '}
                      <b>{selectedSite.url}</b>. Please reinstall to continue
                      using this workspace.
                    </span>
                  </Message>
                </UninstalledWrapper>
              )}

              {/* <Discount /> */}

              <Outlet />
            </InnerWrapper>
          </OutletWrapper>
        </Suspense>
      </Wrapper>
      <Feedback />
    </>
  );
};

export default PrivateRoute;
