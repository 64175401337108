export async function blobUrlToDataUrl(blobUrl: string) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error('Error reading Blob as Data URL'));
    };
    reader.readAsDataURL(blob);
  });
}
