export type GooglePageLanguage = {
  language_code: string;
  language_name: string;
};

export const googlePageLanguages: GooglePageLanguage[] = [
  {
    language_code: 'af',
    language_name: 'Afrikaans',
  },
  {
    language_code: 'ak',
    language_name: 'Akan',
  },
  {
    language_code: 'sq',
    language_name: 'Albanian',
  },
  {
    language_code: 'ws',
    language_name: 'Samoa',
  },
  {
    language_code: 'am',
    language_name: 'Amharic',
  },
  {
    language_code: 'ar',
    language_name: 'Arabic',
  },
  {
    language_code: 'hy',
    language_name: 'Armenian',
  },
  {
    language_code: 'az',
    language_name: 'Azerbaijani',
  },
  {
    language_code: 'eu',
    language_name: 'Basque',
  },
  {
    language_code: 'be',
    language_name: 'Belarusian',
  },
  {
    language_code: 'bem',
    language_name: 'Bemba',
  },
  {
    language_code: 'bn',
    language_name: 'Bengali',
  },
  {
    language_code: 'bh',
    language_name: 'Bihari',
  },
  {
    language_code: 'xx-bork',
    language_name: 'Bork, bork, bork!',
  },
  {
    language_code: 'bs',
    language_name: 'Bosnian',
  },
  {
    language_code: 'br',
    language_name: 'Breton',
  },
  {
    language_code: 'bg',
    language_name: 'Bulgarian',
  },
  {
    language_code: 'bt',
    language_name: 'Bhutanese',
  },
  {
    language_code: 'km',
    language_name: 'Cambodian',
  },
  {
    language_code: 'ca',
    language_name: 'Catalan',
  },
  {
    language_code: 'chr',
    language_name: 'Cherokee',
  },
  {
    language_code: 'ny',
    language_name: 'Chichewa',
  },
  {
    language_code: 'zh-cn',
    language_name: 'Chinese (Simplified)',
  },
  {
    language_code: 'zh-tw',
    language_name: 'Chinese (Traditional)',
  },
  {
    language_code: 'co',
    language_name: 'Corsican',
  },
  {
    language_code: 'hr',
    language_name: 'Croatian',
  },
  {
    language_code: 'cs',
    language_name: 'Czech',
  },
  {
    language_code: 'da',
    language_name: 'Danish',
  },
  {
    language_code: 'nl',
    language_name: 'Dutch',
  },
  {
    language_code: 'xx-elmer',
    language_name: 'Elmer Fudd',
  },
  {
    language_code: 'en',
    language_name: 'English',
  },
  {
    language_code: 'eo',
    language_name: 'Esperanto',
  },
  {
    language_code: 'et',
    language_name: 'Estonian',
  },
  {
    language_code: 'ee',
    language_name: 'Ewe',
  },
  {
    language_code: 'fo',
    language_name: 'Faroese',
  },
  {
    language_code: 'tl',
    language_name: 'Filipino',
  },
  {
    language_code: 'fi',
    language_name: 'Finnish',
  },
  {
    language_code: 'fr',
    language_name: 'French',
  },
  {
    language_code: 'fy',
    language_name: 'Frisian',
  },
  {
    language_code: 'gaa',
    language_name: 'Ga',
  },
  {
    language_code: 'gl',
    language_name: 'Galician',
  },
  {
    language_code: 'ka',
    language_name: 'Georgian',
  },
  {
    language_code: 'de',
    language_name: 'German',
  },
  {
    language_code: 'el',
    language_name: 'Greek',
  },
  {
    language_code: 'kl',
    language_name: 'Greenlandic',
  },
  {
    language_code: 'gn',
    language_name: 'Guarani',
  },
  {
    language_code: 'gu',
    language_name: 'Gujarati',
  },
  {
    language_code: 'xx-hacker',
    language_name: 'Hacker',
  },
  {
    language_code: 'ht',
    language_name: 'Haitian Creole',
  },
  {
    language_code: 'ha',
    language_name: 'Hausa',
  },
  {
    language_code: 'haw',
    language_name: 'Hawaiian',
  },
  {
    language_code: 'iw',
    language_name: 'Hebrew',
  },
  {
    language_code: 'hi',
    language_name: 'Hindi',
  },
  {
    language_code: 'hu',
    language_name: 'Hungarian',
  },
  {
    language_code: 'is',
    language_name: 'Icelandic',
  },
  {
    language_code: 'ig',
    language_name: 'Igbo',
  },
  {
    language_code: 'id',
    language_name: 'Indonesian',
  },
  {
    language_code: 'ia',
    language_name: 'Interlingua',
  },
  {
    language_code: 'ga',
    language_name: 'Irish',
  },
  {
    language_code: 'it',
    language_name: 'Italian',
  },
  {
    language_code: 'ja',
    language_name: 'Japanese',
  },
  {
    language_code: 'jw',
    language_name: 'Javanese',
  },
  {
    language_code: 'kn',
    language_name: 'Kannada',
  },
  {
    language_code: 'kk',
    language_name: 'Kazakh',
  },
  {
    language_code: 'rw',
    language_name: 'Kinyarwanda',
  },
  {
    language_code: 'rn',
    language_name: 'Kirundi',
  },
  {
    language_code: 'xx-klingon',
    language_name: 'Klingon',
  },
  {
    language_code: 'kg',
    language_name: 'Kongo',
  },
  {
    language_code: 'ko',
    language_name: 'Korean',
  },
  {
    language_code: 'kri',
    language_name: 'Krio (Sierra Leone)',
  },
  {
    language_code: 'ku',
    language_name: 'Kurdish',
  },
  {
    language_code: 'ckb',
    language_name: 'Kurdish (Soranî)',
  },
  {
    language_code: 'ky',
    language_name: 'Kyrgyz',
  },
  {
    language_code: 'lo',
    language_name: 'Laothian',
  },
  {
    language_code: 'la',
    language_name: 'Latin',
  },
  {
    language_code: 'lv',
    language_name: 'Latvian',
  },
  {
    language_code: 'ln',
    language_name: 'Lingala',
  },
  {
    language_code: 'lt',
    language_name: 'Lithuanian',
  },
  {
    language_code: 'loz',
    language_name: 'Lozi',
  },
  {
    language_code: 'lg',
    language_name: 'Luganda',
  },
  {
    language_code: 'ach',
    language_name: 'Luo',
  },
  {
    language_code: 'mk',
    language_name: 'Macedonian',
  },
  {
    language_code: 'mg',
    language_name: 'Malagasy',
  },
  {
    language_code: 'ms',
    language_name: 'Malay',
  },
  {
    language_code: 'ml',
    language_name: 'Malayalam',
  },
  {
    language_code: 'mt',
    language_name: 'Maltese',
  },
  {
    language_code: 'mv',
    language_name: 'Maldives',
  },
  {
    language_code: 'mi',
    language_name: 'Maori',
  },
  {
    language_code: 'mr',
    language_name: 'Marathi',
  },
  {
    language_code: 'mfe',
    language_name: 'Mauritian Creole',
  },
  {
    language_code: 'mo',
    language_name: 'Moldavian',
  },
  {
    language_code: 'mn',
    language_name: 'Mongolian',
  },
  {
    language_code: 'sr-me',
    language_name: 'Montenegrin',
  },
  {
    language_code: 'my',
    language_name: 'Myanmar',
  },
  {
    language_code: 'ne',
    language_name: 'Nepali',
  },
  {
    language_code: 'pcm',
    language_name: 'Nigerian Pidgin',
  },
  {
    language_code: 'nso',
    language_name: 'Northern Sotho',
  },
  {
    language_code: 'no',
    language_name: 'Norwegian',
  },
  {
    language_code: 'nn',
    language_name: 'Norwegian (Nynorsk)',
  },
  {
    language_code: 'oc',
    language_name: 'Occitan',
  },
  {
    language_code: 'or',
    language_name: 'Oriya',
  },
  {
    language_code: 'om',
    language_name: 'Oromo',
  },
  {
    language_code: 'ps',
    language_name: 'Pashto',
  },
  {
    language_code: 'fa',
    language_name: 'Persian',
  },
  {
    language_code: 'xx-pirate',
    language_name: 'Pirate',
  },
  {
    language_code: 'pl',
    language_name: 'Polish',
  },
  {
    language_code: 'pt',
    language_name: 'Portuguese',
  },
  {
    language_code: 'pt-br',
    language_name: 'Portuguese (Brazil)',
  },
  {
    language_code: 'pt-pt',
    language_name: 'Portuguese (Portugal)',
  },
  {
    language_code: 'pa',
    language_name: 'Punjabi',
  },
  {
    language_code: 'qu',
    language_name: 'Quechua',
  },
  {
    language_code: 'ro',
    language_name: 'Romanian',
  },
  {
    language_code: 'rm',
    language_name: 'Romansh',
  },
  {
    language_code: 'nyn',
    language_name: 'Runyakitara',
  },
  {
    language_code: 'ru',
    language_name: 'Russian',
  },
  {
    language_code: 'gd',
    language_name: 'Scots Gaelic',
  },
  {
    language_code: 'sr',
    language_name: 'Serbian',
  },
  {
    language_code: 'sh',
    language_name: 'Serbo-Croatian',
  },
  {
    language_code: 'st',
    language_name: 'Sesotho',
  },
  {
    language_code: 'tn',
    language_name: 'Setswana',
  },
  {
    language_code: 'crs',
    language_name: 'Seychellois Creole',
  },
  {
    language_code: 'sn',
    language_name: 'Shona',
  },
  {
    language_code: 'sd',
    language_name: 'Sindhi',
  },
  {
    language_code: 'si',
    language_name: 'Sinhalese',
  },
  {
    language_code: 'sk',
    language_name: 'Slovak',
  },
  {
    language_code: 'sl',
    language_name: 'Slovenian',
  },
  {
    language_code: 'so',
    language_name: 'Somali',
  },
  {
    language_code: 'es',
    language_name: 'Spanish',
  },
  {
    language_code: 'es-419',
    language_name: 'Spanish (Latin American)',
  },
  {
    language_code: 'su',
    language_name: 'Sundanese',
  },
  {
    language_code: 'sw',
    language_name: 'Swahili',
  },
  {
    language_code: 'sv',
    language_name: 'Swedish',
  },
  {
    language_code: 'tg',
    language_name: 'Tajik',
  },
  {
    language_code: 'ta',
    language_name: 'Tamil',
  },
  {
    language_code: 'tt',
    language_name: 'Tatar',
  },
  {
    language_code: 'te',
    language_name: 'Telugu',
  },
  {
    language_code: 'th',
    language_name: 'Thai',
  },
  {
    language_code: 'ti',
    language_name: 'Tigrinya',
  },
  {
    language_code: 'to',
    language_name: 'Tonga',
  },
  {
    language_code: 'lua',
    language_name: 'Tshiluba',
  },
  {
    language_code: 'tum',
    language_name: 'Tumbuka',
  },
  {
    language_code: 'tr',
    language_name: 'Turkish',
  },
  {
    language_code: 'tk',
    language_name: 'Turkmen',
  },
  {
    language_code: 'tw',
    language_name: 'Twi',
  },
  {
    language_code: 'ug',
    language_name: 'Uighur',
  },
  {
    language_code: 'uk',
    language_name: 'Ukrainian',
  },
  {
    language_code: 'ur',
    language_name: 'Urdu',
  },
  {
    language_code: 'uz',
    language_name: 'Uzbek',
  },
  {
    language_code: 'vu',
    language_name: 'Vanuatu',
  },
  {
    language_code: 'vi',
    language_name: 'Vietnamese',
  },
  {
    language_code: 'cy',
    language_name: 'Welsh',
  },
  {
    language_code: 'wo',
    language_name: 'Wolof',
  },
  {
    language_code: 'xh',
    language_name: 'Xhosa',
  },
  {
    language_code: 'yi',
    language_name: 'Yiddish',
  },
  {
    language_code: 'yo',
    language_name: 'Yoruba',
  },
  {
    language_code: 'zu',
    language_name: 'Zulu',
  },
];
