import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { updateWidgetsSettings } from '../../redux/action';
import {
  resetWidgetSettingsState,
  settingSelector,
} from '../../redux/widgetSettings.slice';

const Header = styled(FlexContainer)`
  padding: 0 0 20px;
`;

const DiscardBtn = styled.div`
  padding: 5px 8px;
  border-radius: 5px;
  color: #333;
  font-size: 13px;
  cursor: pointer;
  background-color: #f5f5f5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: rgb(219 216 216);
  }
`;

const SaveBtn = styled.div`
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  background-color: #3498ff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: #166ec7;
    color: #fff;
  }
`;

const Wrapper = styled(FlexContainer)<{ $show: boolean }>`
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`;

export default function ActionButtons() {
  const dispatch = useAppDispatch();
  const {
    updateWidgetSettings: { saving },
    isWidgetSettingsUpdated,
  } = useAppSelector(settingSelector);

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  const { showSuccessToast } = useNotification();

  return (
    <Header justifyContent="flex-end">
      <Wrapper
        justifyContent="flex-end"
        gap="10px"
        $show={isWidgetSettingsUpdated}
      >
        <DiscardBtn onClick={() => dispatch(resetWidgetSettingsState())}>
          Discard
        </DiscardBtn>
        <SaveBtn onClick={updateSettings}>
          {saving === Loading.PENDING ? 'Saving...' : 'Save'}
        </SaveBtn>
      </Wrapper>
    </Header>
  );
}
