import { debounce } from 'lodash';
import { useRef } from 'react';
import { Input } from 'rsuite';
import styled from 'styled-components';
import FormRow from '../../features/widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../features/widgetSettings/redux/widgetSettings.slice';
import { useAppDispatch, useAppSelector } from '../../store';

const Label = styled.label`
  display: block;
  color: black;
  margin-bottom: 5px;
`;

export default function VideoTranslations() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { translation } = settings ?? {};

  const updateTranslation = useRef(
    debounce((value: string, payload: object) => {
      dispatch(updateSiteWidgetSettings(payload));
    }, 300)
  ).current;

  return (
    <>
      <FormRow stack alignItems="flex-start">
        <Label>Camera</Label>
        <Input
          placeholder="Camera"
          defaultValue={translation?.reviewForm?.camera}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  camera: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Microphone</Label>
        <Input
          placeholder="Microphone"
          defaultValue={translation?.reviewForm?.microphone}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  microphone: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>QUESTIONS</Label>
        <Input
          placeholder="QUESTIONS"
          defaultValue={translation?.reviewForm?.questions}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  questions: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Record again</Label>
        <Input
          placeholder="Record again"
          defaultValue={translation?.reviewForm?.recordAgain}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  recordAgain: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Record a video</Label>
        <Input
          placeholder="Record a video"
          defaultValue={translation?.reviewForm?.recordAVideo}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  recordAVideo: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Record my video</Label>
        <Input
          placeholder="Record my video"
          defaultValue={translation?.reviewForm?.recordMyVideo}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  recordMyVideo: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Send in text</Label>
        <Input
          placeholder="Send in text"
          defaultValue={translation?.reviewForm?.sendInText}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  sendInText: value,
                },
              },
            })
          }
        />
      </FormRow>

      {/* <FormRow stack alignItems="flex-start">
        <Label>Submit review</Label>
        <Input
          placeholder="Submit review"
          defaultValue={translation?.reviewForm?.submitReview}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  submitReview: value,
                },
              },
            })
          }
        />
      </FormRow> */}

      <FormRow stack alignItems="flex-start">
        <Label>Check Your Camera and Microphone</Label>
        <Input
          placeholder="Check Your Camera and Microphone"
          defaultValue={translation?.reviewForm?.checkCameraMicrophone}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  checkCameraMicrophone: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>You have up to 30 seconds to record your video....</Label>
        <Input
          placeholder="You have up to 30 seconds to record your video. Don’t worry — you can review it before submitting and re-record if needed."
          defaultValue={translation?.reviewForm?.videoDurationNotice}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  videoDurationNotice: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>Your email address will remain private and won’t be....</Label>
        <Input
          placeholder="Your email address will remain private and won’t be shared publicly"
          defaultValue={translation?.reviewForm?.privateEmailNotice}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  privateEmailNotice: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>If you’ve denied access to your camera or microphone....</Label>
        <Input
          placeholder="If you’ve denied access to your camera or microphone, you’ll need to enable them in your browser settings."
          defaultValue={translation?.reviewForm?.accessDeniedMessage}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  accessDeniedMessage: value,
                },
              },
            })
          }
        />
      </FormRow>

      <FormRow stack alignItems="flex-start">
        <Label>To allow access to your camera and microphone...</Label>
        <Input
          placeholder="To allow access to your camera and microphone, click the link for your browser below:"
          defaultValue={translation?.reviewForm?.accessDeniedTip}
          onChange={(value) =>
            updateTranslation(value, {
              ...settings,
              translation: {
                ...settings?.translation,
                reviewForm: {
                  ...settings?.translation?.reviewForm,
                  accessDeniedTip: value,
                },
              },
            })
          }
        />
      </FormRow>
    </>
  );
}
