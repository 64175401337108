import { PropsWithChildren } from 'react';
import { Modal } from 'rsuite';
import styled, { css } from 'styled-components';
import GoBackIcon from '../../svgs/go-back-icon';
import HorizontalRule from '../horizontal-rule';
import StyledText from '../styled-text';

const ModalHeaderContainer = styled(Modal.Header)<{
  resetCloseButton?: boolean;
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 5px;
  ${({ resetCloseButton }) =>
    resetCloseButton &&
    css`
      .rs-btn-close {
        top: 0;
        right: 0;
      }
    `}
`;
const ModalHeaderText = styled(Modal.Title)`
  font-size: ${({ theme }) => theme.typography.fontSizes.md};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  margin: 0;
  margin-bottom: 0;
  /* text-transform: uppercase; */
`;
const ModalSubHeaderText = styled(Modal.Title)`
  margin: 0;
  white-space: initial;
  text-align: center;
  margin: 20px 0 0;
`;
const TextWrapper = styled.div<{ centerText?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ centerText }) =>
    centerText &&
    css`
      /* position: absolute; */
      /* width: fit-content; */
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    `}
`;
const GoBackIconWrapper = styled.div`
  display: flex;
  margin-right: 15px;
  cursor: pointer;
`;
const ModalBodyWrapper = styled(Modal.Body)`
  margin-top: 0;
  padding: 0;
`;

interface Props {
  title?: string;
  subTitle?: string;
  centerText?: boolean;
  headerline?: boolean;
  closeButton?: boolean;
  gobackText?: string;
  onGoBackClick?: () => void;
  resetCloseButton?: boolean;
  height?: string;
}

export const ModalHeader = ({
  title,
  subTitle,
  centerText,
  headerline = false,
  closeButton = true,
  gobackText,
  onGoBackClick,
  resetCloseButton,
  height,
}: Props) => (
  <>
    <ModalHeaderContainer
      closeButton={closeButton}
      resetCloseButton={resetCloseButton}
      height={height}
    >
      {onGoBackClick && (
        <GoBackIconWrapper onClick={onGoBackClick}>
          <GoBackIcon />
          {gobackText && <StyledText>{gobackText}</StyledText>}
        </GoBackIconWrapper>
      )}
      <TextWrapper centerText={centerText}>
        {title && <ModalHeaderText>{title}</ModalHeaderText>}
      </TextWrapper>
    </ModalHeaderContainer>
    {headerline && <HorizontalRule />}

    {subTitle && <ModalSubHeaderText>{subTitle}</ModalSubHeaderText>}
  </>
);

export const ModalBody = ({ children }: PropsWithChildren) => (
  <ModalBodyWrapper>{children}</ModalBodyWrapper>
);
