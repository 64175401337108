import styled from 'styled-components';

interface Props {
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
}

const StyledText = styled.p<Props>`
  color: ${({ color, theme }) => color || theme.colors.black};
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-family: ${({ fontFamily }) => fontFamily || "'Roboto', sans-serif"};
  line-height: ${({ lineHeight }) => lineHeight || '1.5rem'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '0em'};
`;

export default StyledText;
