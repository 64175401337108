import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';

const http = new Http();

export const getTestimonialTemplate = createAsyncThunk<
  { template: { subject: string; message: string } },
  string,
  { rejectValue: string; state: RootState }
>('testimonial/templates', async (type, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/testimonials/templates?type=${type}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const sendSingleReviewRequest = createAsyncThunk<
  { success: boolean; review: any },
  FormData,
  { rejectValue: string; state: RootState }
>('emails/single-request', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/emails/single-request`,
      body,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
