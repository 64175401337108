import styled from 'styled-components';

/* eslint no-console: "warn" */

const StyledButton = styled.button<ButtonProps>`
  padding: ${({ size }) =>
    size === 'small' ? '5px 15px' : size === 'large' ? '9px 15px' : '7px 15px'};
  font-size: ${({ size }) =>
    size === 'small' ? '13px' : size === 'large' ? '15px' : '14px'};
  background-color: ${({ variant, disabled }) => {
    if (disabled) {
      return '#BBBDC4';
    }

    if (variant === 'primary') {
      return '#0f62e6';
    }

    // @ts-ignore
    if (['secondary', 'tertiary', 'danger', 'warning'].includes(variant)) {
      return '#fff';
    }
  }};

  color: ${({ variant, disabled }) => {
    if (disabled) {
      return '#fff';
    }

    if (variant === 'tertiary') {
      return '#000';
    }

    if (variant === 'danger') {
      return '#e91b1b';
    }

    if (variant === 'success') {
      return '#4caf50';
    }

    if (variant === 'warning') {
      return '#fa8900';
    }

    if (variant === 'secondary') {
      return '#0f62e6';
    }

    return '#fff';
  }};
  border: none;
  border-radius: ${({ rounded }) => (rounded ? '18px' : '5px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  border: ${({ variant, disabled }) => {
    if (disabled) {
      return '1px solid #dedede';
    }
    if (variant === 'tertiary') {
      return '1px solid #ccc';
    }

    if (variant === 'danger') {
      return '1px solid #e91b1b';
    }

    if (variant === 'success') {
      return '1px solid #4caf50';
    }

    if (variant === 'warning') {
      return '1px solid #fa8900';
    }

    return '1px solid #0f62e6';
  }};
`;

interface ButtonProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'danger'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  children: React.ReactNode;
  type?: 'submit' | 'button';
  disabled?: boolean;
  rounded?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'medium',
  onClick,
  children,
  disabled = false,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
