const getLastSegmentOfUrl = (url: string) => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname.endsWith('/')
    ? parsedUrl.pathname.slice(0, -1)
    : parsedUrl.pathname;

  const segments = pathname.split('/');
  return segments.pop() || '';
};

export default getLastSegmentOfUrl;
