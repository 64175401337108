import React from 'react';

const Crown = () => {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="28"
        height="18"
        rx="9"
        fill="#1C1C1F"
        fillOpacity="0.8"
      ></rect>
      <rect
        x="0.5"
        y="0.5"
        width="27"
        height="17"
        rx="8.5"
        stroke="white"
        strokeOpacity="0.1"
        fillOpacity="0"
      ></rect>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3C13.7262 3 13.4622 3.09509 13.2594 3.26668C13.1323 3.37397 13.0338 3.50757 12.972 3.65677L12.9649 3.67453L11.8059 6.68164L9.2405 5.48446C8.9222 5.33592 8.53867 5.3864 8.27679 5.61129C8.01492 5.83619 7.92917 6.18873 8.06134 6.49711L9.77562 10.4971C9.90579 10.8008 10.221 11 10.5715 11H17.4286C17.779 11 18.0942 10.8008 18.2244 10.4971L19.9387 6.49711C20.0708 6.18872 19.9851 5.83619 19.7232 5.61129C19.4613 5.3864 19.0778 5.33592 18.7595 5.48446L16.1934 6.68199L15.0396 3.69689C14.9838 3.54077 14.8884 3.39937 14.7614 3.28479C14.7556 3.27955 14.7498 3.2744 14.7438 3.26933C14.5406 3.09607 14.2753 3 14 3ZM10.8 12C10.3582 12 10 12.4477 10 13C10 13.5523 10.3582 14 10.8 14H17.2C17.6418 14 18 13.5523 18 13C18 12.4477 17.6418 12 17.2 12H10.8Z"
        fill="#FFCC00"
      ></path>
    </svg>
  );
};

export default Crown;
