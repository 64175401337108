export default function ChromeLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <defs>
        <linearGradient
          id="linear0"
          gradientUnits="userSpaceOnUse"
          x1="3.2173"
          y1="15"
          x2="44.7812"
          y2="15"
          gradientTransform="matrix(0.416667,0,0,0.416667,0,0)"
        >
          <stop
            offset="0"
            style={{
              stopColor: 'rgb(85.098039%,18.823529%,14.509804%)',
              stopOpacity: 1,
            }}
          />
          <stop
            offset="1"
            style={{
              stopColor: 'rgb(91.764706%,26.27451%,20.784314%)',
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <linearGradient
          id="linear1"
          gradientUnits="userSpaceOnUse"
          x1="20.7219"
          y1="47.6791"
          x2="41.5039"
          y2="11.6837"
          gradientTransform="matrix(0.416667,0,0,0.416667,0,0)"
        >
          <stop
            offset="0"
            style={{
              stopColor: 'rgb(98.823529%,78.823529%,20.392157%)',
              stopOpacity: 1,
            }}
          />
          <stop
            offset="1"
            style={{
              stopColor: 'rgb(98.431373%,73.72549%,1.568627%)',
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <linearGradient
          id="linear2"
          gradientUnits="userSpaceOnUse"
          x1="26.5981"
          y1="46.5015"
          x2="5.8161"
          y2="10.506"
          gradientTransform="matrix(0.416667,0,0,0.416667,0,0)"
        >
          <stop
            offset="0"
            style={{
              stopColor: 'rgb(11.764706%,55.686275%,24.313725%)',
              stopOpacity: 1,
            }}
          />
          <stop
            offset="1"
            style={{
              stopColor: 'rgb(20.392157%,65.882353%,32.54902%)',
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 15 9.996094 C 15 12.757812 12.761719 14.996094 10 14.996094 C 7.238281 14.996094 5 12.757812 5 9.996094 C 5 7.238281 7.238281 4.996094 10 4.996094 C 12.761719 4.996094 15 7.238281 15 9.996094 Z M 15 9.996094 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'url(#linear0)',
          }}
          d="M 10 5 L 18.660156 5 C 16.871094 1.90625 13.570312 0 10 0 C 6.425781 0 3.125 1.90625 1.339844 5 L 5.671875 12.5 L 5.675781 12.5 C 4.777344 10.953125 4.773438 9.046875 5.667969 7.5 C 6.5625 5.949219 8.214844 4.996094 10 5 Z M 10 5 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(10.196078%,45.098039%,90.980392%)',
            fillOpacity: 1,
          }}
          d="M 13.957031 10 C 13.957031 12.1875 12.1875 13.957031 10 13.957031 C 7.8125 13.957031 6.042969 12.1875 6.042969 10 C 6.042969 7.8125 7.8125 6.042969 10 6.042969 C 12.1875 6.042969 13.957031 7.8125 13.957031 10 Z M 13.957031 10 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'url(#linear1)',
          }}
          d="M 14.328125 12.5 L 10 20 C 13.574219 20 16.875 18.09375 18.660156 15 C 20.445312 11.90625 20.445312 8.09375 18.660156 5 L 10 5 L 10 5.003906 C 11.785156 5 13.4375 5.953125 14.332031 7.5 C 15.226562 9.046875 15.226562 10.953125 14.328125 12.5 Z M 14.328125 12.5 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'url(#linear2)',
          }}
          d="M 5.671875 12.5 L 1.339844 5.003906 C -0.445312 8.097656 -0.445312 11.90625 1.339844 15 C 3.125 18.09375 6.429688 20 10 20 L 14.332031 12.5 L 14.328125 12.5 C 13.4375 14.046875 11.785156 15.003906 10 15.003906 C 8.210938 15.003906 6.5625 14.050781 5.671875 12.5 Z M 5.671875 12.5 "
        />
      </g>
    </svg>
  );
}
