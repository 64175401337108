import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const NavLink = forwardRef((props: any, ref) => {
  const { href, as, ...rest } = props;
  return (
    <Link to={href}>
      <a ref={ref} {...rest}>
        {props.children}
      </a>
    </Link>
  );
});

export default NavLink;
