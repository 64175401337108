import styled from 'styled-components';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const IframeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
`;

export const SupportIframe = () => {
  return (
    <IframeContainer>
      <StyledIframe
        src={`${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_SUPPORT!
        )}?notitle=true&nologo=true&nocontact=true`}
        title="Supports Iframe"
      />
    </IframeContainer>
  );
};
