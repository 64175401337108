import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa6';
import { Button, Divider, Tag, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import ChromeLogo from '../../../../../shared/components/ChromeLogo';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { ReviewerPlatform } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { accountSelector } from '../../../../userData/redux/userData.slice';

const Title = styled.h4`
  color: #151719;
  font-weight: 400;
  margin: 0 0 10px;
  font-size: 18px;
`;

const AccountId = styled.h5`
  margin: 20px 0;
  font-size: 14px;
  p {
    color: #707070;
    font-weight: 400;
  }
`;

const getPlatformName = (platform: ReviewerPlatform) => {
  switch (platform) {
    case ReviewerPlatform.ALI_EXPRESS:
      return 'AliExpress';
    case ReviewerPlatform.ETSY:
      return 'Etsy';
    case ReviewerPlatform.TOUR_RADAR:
      return 'TourRadar';

    default:
      break;
  }
};

const DisplaySiteIdPage = ({ platform }: { platform: ReviewerPlatform }) => {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { _id: siteId } = selectedSite ?? {};
  const { showInfoToast } = useNotification();

  return (
    <>
      <Title>Import {getPlatformName(platform)} reviews</Title>

      <p>1. Copy your Account ID</p>
      <AccountId>
        <FlexContainer justifyContent="flex-start" gap="10px">
          <Tag size="lg">{siteId}</Tag>
          <Whisper
            placement="top"
            controlId="control-id-context-menu"
            trigger="hover"
            speaker={<Tooltip>Copy Account ID</Tooltip>}
          >
            <CopyToClipboard hidden text={siteId}>
              <Button
                size="sm"
                onClick={() => showInfoToast(`${siteId} copied successfully!`)}
              >
                <FaCopy />
              </Button>
            </CopyToClipboard>
          </Whisper>
        </FlexContainer>
      </AccountId>

      <p>2. Install our Chrome Extension</p>

      <p>3. Visit your {`${getPlatformName(platform)} page`} page</p>

      <p>
        4. Click the <b>Import Reviews</b> button
      </p>

      <Divider />

      <Button
        block
        appearance="primary"
        onClick={() =>
          window.open(
            'https://chromewebstore.google.com/detail/reviewsjet/cdoapcelcmonibckhnojjalhildkhbbf',
            '_blank'
          )
        }
      >
        <FlexContainer gap="10px">
          Click to install <ChromeLogo />
        </FlexContainer>
      </Button>
    </>
  );
};

export default DisplaySiteIdPage;
