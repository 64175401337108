import { useState } from 'react';
import ReviewTaskSuccess from '../ReviewTaskSuccess';
import AddProductPage from './AddProductPage';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectCapterraStep {
  ADD_PAGE,
  SUCCESS,
}

const ConnectCapterra = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectCapterraStep.ADD_PAGE);

  return (
    <>
      {step === ConnectCapterraStep.ADD_PAGE && (
        <AddProductPage
          onConnected={() => {
            onConnected();
            setStep(ConnectCapterraStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectCapterraStep.SUCCESS && (
        <ReviewTaskSuccess
          onConfirmed={() => {
            onComplete();
            setStep(ConnectCapterraStep.ADD_PAGE);
          }}
        />
      )}
    </>
  );
};

export default ConnectCapterra;
