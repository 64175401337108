import styled from 'styled-components';
import SearchBusiness from './SearchBusiness';
import { GoogleConnectProvider } from '../../../contexts/GoogleLocationContext';
import { useState } from 'react';
import ListBusinesses from './ListBusinesses';
import ConfirmBusiness from './ConfirmBusiness';
import Success from './Success';

type Props = {
  onComplete: () => void;
  onConnected: () => void;
};

export enum ConnectGoogleStep {
  SEARCH,
  LIST,
  CONFIRM,
  SUCCESS,
}

const ConnectGoogle = ({ onComplete, onConnected }: Props) => {
  const [step, setStep] = useState(ConnectGoogleStep.SEARCH);

  return (
    <GoogleConnectProvider>
      {step === ConnectGoogleStep.SEARCH && (
        <SearchBusiness onSuccess={() => setStep(ConnectGoogleStep.LIST)} />
      )}
      {step === ConnectGoogleStep.LIST && (
        <ListBusinesses
          onGoBack={() => setStep(ConnectGoogleStep.SEARCH)}
          onLocationSelected={() => {
            setStep(ConnectGoogleStep.CONFIRM);
          }}
        />
      )}
      {step === ConnectGoogleStep.CONFIRM && (
        <ConfirmBusiness
          onGoback={() => setStep(ConnectGoogleStep.LIST)}
          onConfirmed={() => {
            onConnected();
            setStep(ConnectGoogleStep.SUCCESS);
          }}
        />
      )}
      {step === ConnectGoogleStep.SUCCESS && (
        <Success
          onConfirmed={() => {
            onComplete();
            setStep(ConnectGoogleStep.SEARCH);
          }}
        />
      )}
    </GoogleConnectProvider>
  );
};

export default ConnectGoogle;
